export function dictToURL(dict) {
    var str = [];
    for(var p in dict){
       str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dict[p]));
    }
    return str.join("&");
  }

export function objectToQueryString(obj, parentKey = '') {
   const keyValuePairs = [];
 
   for (const key in obj) {
     if (obj?.hasOwnProperty(key)) {
       const value = obj[key];
       const encodedKey = encodeURIComponent(key);
 
       let modifiedKey;
       if (parentKey) {
         modifiedKey = `${parentKey}[${encodedKey}]`;
       } else {
         modifiedKey = encodedKey;
       }
 
       if (typeof value === 'object' && value !== null) {
         keyValuePairs.push(objectToQueryString(value, modifiedKey));
       } else {
         keyValuePairs.push(`${modifiedKey}`);
       }
     }
   }
 
   return keyValuePairs.join('&');
 }