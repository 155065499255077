import React, { useState, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, Rectangle, CartesianGrid, Tooltip, BarChart, Bar, ResponsiveContainer, Label } from 'recharts';

import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";
import { COLORS_2 } from "../../../assets/styles/global";

import { FiveRowsValueThresholdTooltip, TickFormatterAxisBigNumber } from "../Tooltips";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { NoBorderIconButton, CardProperties, ThemeVariables } from "lynxai-ui-kit";
import { AiOutlineExpand } from "react-icons/ai";

const MultipleLinesChartWithThresholdCard = ({ title, linesKeys, cardType, data, modal }) => {
    const { theme } = useContext(ThemeContext);
    const [isModalOpen, setisModalOpen] = useState(false);

    return (
        <>
            {data?.length > 0 &&
                <>
                    <CardProperties theme={theme} className={`${modal && 'background-transparent'} ${cardType}`}>
                        {!modal &&
                            <FlexBlockDiv>
                                <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                                <NoBorderIconButton theme={theme} onClick={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                            </FlexBlockDiv>}
                        <ResponsiveContainer width="100%" height="100%" className={`font-style-montserrat-family-regular extra-extra-small-text color-white`}>
                            <LineChart data={data} margin={{ left: 25, top: 15, right: 7 }}>
                                <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.medium_color_50}` }} vertical={false} />
                                <XAxis dataKey="year" dx={-2} tick={{ width: 130, fill: `${ThemeVariables[theme]?.extreme_contrast}` }} tickLine={false} />
                                <YAxis tickFormatter={TickFormatterAxisBigNumber} tick={{ fill: `${ThemeVariables[theme]?.extreme_contrast}` }} tickLine={false} />
                                {linesKeys?.map((item, index) => (
                                    <Line key={index} strokeWidth={2} type="monotone" name={item.label} dataKey={item.value} stroke={COLORS_2[index]} activeDot={{ r: 6 }} dot={{ stroke: `${ThemeVariables[theme]?.extreme_contrast}`, strokeWidth: 0, r: 4, strokeDasharray: '' }} />
                                ))}
                                {/* <Line strokeWidth={2} type="monotone" name="Threshold" dataKey="threshold" strokeDasharray="7 7" stroke="var(--dark-green)" dot={false} /> */}
                                <Tooltip wrapperStyle={{ outline: 'none' }} content={<FiveRowsValueThresholdTooltip />} shared={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardProperties>
                    <ModalWindow theme={theme} title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                        <MultipleLinesChartWithThresholdCard title={title} linesKeys={linesKeys} multipleLines={true} data={data} cardType="modal-card" modal={true}></MultipleLinesChartWithThresholdCard>
                    </ModalWindow>
                </>
            }
        </>
    );
}

export default MultipleLinesChartWithThresholdCard;