import React, { useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import "./ManageSimulations.scss"

const SimulationProfile = () => {
    const { simulation } = useParams()

    const [fundsList, setFundsList] = useState([{ value: 1, fund_name: "Fund A" }, { value: 2, fund_name: "Fund B" }, { value: 3, fund_name: "Fund C" }]);
    const [environmentVarList, setEnvironmentVarList] = useState(['Coal Price', 'CO2 emissions', 'Compliance Market Carbon Price', 'Compliance Market Limit emissions', 'Country CO2 emissions', 'Energy Price (kW/h)', 'Gas price', 'Oil price', 'Hydropower Average Price', 'Solar Energy Average Price', 'Voluntary Market Carbon Price', 'Wind energy Average Price']);
    const [fundsMenuOpen, setFundsMenuOpen] = useState(true);
    const [environmentVariablesMenuOpen, setEnvironmentVariablesMenuOpen] = useState(true);

    return (
        <>
            <title>Lynxai | Simulator</title>
            AAAAAA
        </>
    );
}

export default SimulationProfile;
