import React, { useContext } from "react";
import { convertToBigNumbers } from "../../utils/Maths/MathsFunctions";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";
import { ThemeVariables } from "lynxai-ui-kit";

export const ValueTooltip = ({ active, payload, label, percent }) => {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                <p className="left-col">Value</p>
                <p className="right-col">{`${convertToBigNumbers(payload[0]?.value)}`}</p>
            </div>
        );
    }
    return null;
};

export const AmountTooltip = ({ active, payload, label, percent }) => {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                <p className="left-col">Value</p>
                <p className="right-col">{`${convertToBigNumbers(payload[0]?.payload.value)}`}</p>
            </div>
        );
    }
    return null;
};

export const LabelTooltip = ({ active, payload, label, percent }) => {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                <p className="left-col">{label}</p>
                <p className="right-col">{`${payload[0]?.value}`}</p>
            </div>
        );
    }
    return null;
};

export const TwoRowsValueThresholdTooltip = ({ active, payload, label, percent }) => {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip-rows-div">
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p className="left-col">{label}</p>
                    <p className="right-col">{`${payload[0]?.value}`}</p>
                </div>
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p className="left-col">Threshold</p>
                    <p className="right-col">{`${payload[1]?.value}`}</p>
                </div>
            </div>
        );
    }
    return null;
};

export const FiveRowsValueThresholdTooltip = ({ text, active, payload, label, percent }) => {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip-rows-div">
                {payload[0] &&
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p style={{ backgroundColor: payload[0]?.stroke }} className="left-col">{payload[0]?.name}</p>
                    <p className="right-col">{`${payload[0]?.value}`}</p>
                </div>}
                {payload[1] &&
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p style={{ backgroundColor: payload[1]?.stroke }} className="left-col">{payload[1]?.name}</p>
                    <p className="right-col">{`${payload[1]?.value}`}</p>
                </div>}
                {payload[2] &&
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p style={{ backgroundColor: payload[2]?.stroke }} className="left-col">{payload[2]?.name}</p>
                    <p className="right-col">{`${payload[2]?.value}`}</p>
                </div>}
                {payload[3] &&
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p style={{ backgroundColor: payload[3]?.stroke }} className="left-col">{payload[3]?.name}</p>
                    <p className="right-col">{`${payload[3]?.value}`}</p>
                </div>}
                {/* <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p style={{ backgroundColor: payload[4]?.stroke }} className="left-col">{payload[4]?.name}</p>
                    <p className="right-col">{`${payload[4]?.value}`}</p>
                </div> */}
            </div>
        );
    }
    return null;
};

export const TwoRowsValuePercentageTooltip = ({ active, payload, label, percent }) => {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip-rows-div">
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p className="left-col">Value</p>
                    <p className="right-col">{`${payload[0]?.payload?.payload?.value}`}</p>
                </div>
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p className="left-col">Percentage</p>
                    <p className="right-col">{`${(payload[0]?.payload?.payload?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</p>
                </div>
            </div>
        );
    }
    return null;
};

export const TwoRowsCurrencyPercentageTooltip = ({ active, payload, label, percent }) => {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip-rows-div">
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p className="left-col">Value</p>
                    <p className="right-col">{`${payload[0]?.payload?.payload?.value.toLocaleString()}€`}</p>
                </div>
                <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                    <p className="left-col">Percentage</p>
                    <p className="right-col">{`${(payload[0]?.payload?.payload?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</p>
                </div>
            </div>
        );
    }
    return null;
};

export const CurrencyTooltip = ({ active, payload, label, percent }) => {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                <p className="left-col">Value</p>
                <p className="right-col">{`${payload[0]?.value?.toLocaleString()}€`}</p>
            </div>
        );
    }
    return null;
};

export const PercentageTooltip = ({ active, payload }) => {
    if (active && payload && payload?.length) {
        const calc_percentage = (payload[0]?.value * 100)?.toFixed(1).replace(/[.,]0/, "")
        return (
            <div className="custom-tooltip font-style-montserrat-family-semibold extra-extra-small-text">
                <p className="left-col">Value</p>
                <p className="right-col">{`${calc_percentage}%`}</p>
            </div>
        );
    }
    return null;
};

export const TickFormatterYAxisBigText = (value, index) => {
    const limit = 20;
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}...`;
};

export const TickFormatterAxisBigNumber = (value, index) => {
    return `${convertToBigNumbers(value)}`;
};

export const TooBigXAxis = (props) => {
    const { theme, setTheme } = useContext(ThemeContext);
    const { x, y, payload, data } = props;
    const maxLength = 80 / data.length; // Set the maximum label length you desire

    let label = payload.value;
    if (label.length > maxLength) {
        label = label.slice(0, maxLength - 3) + '...'; // Append ellipsis if label is too long
    }

    return (
        <g transform={`translate(${x},${y})`} className="font-style-montserrat-family-regular extra-extra-small-text">
            <text
                x={0}
                y={0}
                dy={10}
                textAnchor="middle"
                style={{ fill: `${ThemeVariables[theme]?.extreme_contrast}` }}>
                {label}
            </text>
        </g>
    );
};