import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/styles/global.scss"
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";

import * as AWS from 'aws-sdk'

AWS?.config?.update({
  region: 'eu-west-2',
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);