import React, { useState, useEffect, useRef, useContext } from "react";

import { BsChevronDown } from "react-icons/bs";

import "./MainDescriptionCard.scss"

import { NoBorderIconButton, CardProperties } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const MainDescriptionCard = ({ title, description, cardType }) => {
  const { theme } = useContext(ThemeContext);
  const [showMore, setShowMore] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current && textRef.current.scrollHeight < 135) {
          setShowMore(true);
        }
      }, [description]);

    return (
        <CardProperties theme={theme} className={`main-description-card ${cardType}`}>
            <p className="margin-bottom-2 small-text font-style-montserrat-family-semibold color-white">{title}</p>
            <p ref={textRef} className={`${showMore && "show-more"} description extra-small-text font-style-montserrat-family-regular`}>
                {description}
            </p>
            {!showMore && 
            <NoBorderIconButton theme={theme} className="margin-center" icon={<BsChevronDown className="icon small-text color-white" />} onClick={() => setShowMore(!showMore)} />
            }
        </CardProperties>
    );
}

export default MainDescriptionCard;