import { createContext } from 'react';

export const GeneralContext = createContext({});

export const ManageFundContext = createContext({});

export const ManageSimulationContext = createContext({});

export const AssetProfileContext = createContext({});

export const FundProfileContext = createContext({});

export const PortfolioContext = createContext({});

export const OverviewContext = createContext({});

export const SimulatorContext = createContext({});