import React, { useState, useEffect, useRef, useContext } from "react";

import "../Regulations.scss"

import LocalLoader from "../../../components/LocalLoader/LocalLoader";
import MainDescriptionCard from "../../../components/Cards/MainDescriptionCard/MainDescriptionCard";
import { CardProperties, MainTitleTransparentCard } from "lynxai-ui-kit";
import FilterCompanyCard from "../../../components/Cards/FilterCompanyCard/FilterCompanyCard";
import OneValueLabelBottomCard from "../../../components/Cards/OneValueLabelBottomCard/OneValueLabelBottomCard";
import TextCard from "../../../components/Cards/TextCard/TextCard";
import DoughnutChartCard from "../../../components/DataVisualization/DoughnutChartCard/DoughnutChartCard";
import PieChartPercentageCard from "../../../components/DataVisualization/PieChartPercentageCard/PieChartPercentageCard";
import HorizontalBarChartPercentageCard from "../../../components/DataVisualization/HorizontalBarChartPercentageCard/HorizontalBarChartPercentageCard";

import { getEUTaxonomyNonFinancial } from "../../../utils/requests/getRequests";
import { COLORS_2 } from "../../../assets/styles/global";
import { GeneralContext } from "../../../utils/functions_variables/variables";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const NonFinancialCompanyProfile = () => {
  const { theme } = useContext(ThemeContext)
  const { setWarningMessage } = useContext(GeneralContext)
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const EUTaxonomyNonFinancial = async () => {
      setIsLoading(true)
      await getEUTaxonomyNonFinancial().then(data => {
        setData(data);
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    EUTaxonomyNonFinancial()
  }, [])

  if (isLoading) {
    return <LocalLoader></LocalLoader>;
  }

  return (
    <div className="regulations-content">
      <CardProperties theme={theme} className="full-width"><p className="font-style-montserrat-family-semibold small-text color-white">EU Taxonomy</p></CardProperties>
      <FilterCompanyCard cardType="big-big-chart-card-size" data_1={data?.total_eligible_turnover_percentage} data_2={data?.total_eligible_capex_percentage} data_3={data?.total_eligible_opex_percentage} companies={data?.companies}></FilterCompanyCard>
      <MainDescriptionCard title="Anual Report Comments" description={data?.anual_report_comments} cardType="big-big-chart-card-size"></MainDescriptionCard>
      <MainTitleTransparentCard theme={theme} text="Turnover Metric Analysis"></MainTitleTransparentCard>
      <OneValueLabelBottomCard text="Total Eligible Turnover %" value={data?.total_eligible_turnover_percentage.percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <OneValueLabelBottomCard text="Total Non-Eligible Turnover %" value={data?.total_non_eligible_turnover_percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <TextCard title="Top sector for total turnover_taxonomy_eligible_percentage is:" data={data?.top_sector_total_turnover_taxonomy_eligible_percentage.funds} cardType="medium-chart-card-size card-height-1" valueOrPercentage="percentage" ></TextCard>
      <DoughnutChartCard title="Total Eligibility Turnover €" data={data?.total_eligible_turnover_amount} cardType="medium-chart-card-size card-height-3"></DoughnutChartCard>
      <PieChartPercentageCard title="Eligible Turnover % by Sector" data={data?.eligible_turnover_percentage_by_sector} cardType="medium-chart-card-size card-height-3" colors={COLORS_2}></PieChartPercentageCard>
      <HorizontalBarChartPercentageCard title="Eligible Turnover % by Economic Activity" data={data?.eligible_turnover_percentage_by_economic_activity} dataKey="eligible" yAxisLabel="name" showLegend={true} categoryName="Taxonomy - Eligible" cardType="medium-chart-card-size card-height-3"></HorizontalBarChartPercentageCard>
      <TextCard title="Top 2 economic_activity for total turnover_taxonomy_eligible_percentage are:" data={data?.top_economic_activity_total_turnover_taxonomy_eligible_percentage.funds} cardType="medium-chart-card-size card-height-3" valueOrPercentage="percentage" ></TextCard>
      <MainTitleTransparentCard theme={theme} text="CAPEX Metric Analysis"></MainTitleTransparentCard>
      <OneValueLabelBottomCard text="Total Eligible CAPEX %" value={data?.total_eligible_capex_percentage.percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <OneValueLabelBottomCard text="Total Non-Eligible CAPEX %" value={data?.total_non_eligible_capex_percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <TextCard title="Top sector for total capex_taxonomy_eligible_percentage is:" data={data?.top_sector_total_capex_taxonomy_eligible_percentage.funds} cardType="medium-chart-card-size card-height-1" valueOrPercentage="percentage" ></TextCard>
      <DoughnutChartCard title="Total Eligibility CAPEX €" data={data?.total_eligible_capex_amount} cardType="medium-chart-card-size card-height-3"></DoughnutChartCard>
      <PieChartPercentageCard title="Eligible CAPEX % by Sector" data={data?.eligible_capex_percentage_by_sector} cardType="medium-chart-card-size card-height-3" colors={COLORS_2}></PieChartPercentageCard>
      <HorizontalBarChartPercentageCard title="Eligible CAPEX % by Economic Activity" data={data?.eligible_capex_percentage_by_economic_activity} dataKey="eligible" yAxisLabel="name" showLegend={true} categoryName="Taxonomy - Eligible" cardType="medium-chart-card-size card-height-3"></HorizontalBarChartPercentageCard>
      <TextCard title="Top 2 economic_activity for total capex_taxonomy_eligible_percentage are:" data={data?.top_economic_activity_total_capex_taxonomy_eligible_percentage.funds} cardType="medium-chart-card-size card-height-3" valueOrPercentage="percentage" ></TextCard>
      <MainTitleTransparentCard theme={theme} text="OPEX Metric Analysis"></MainTitleTransparentCard>
      <OneValueLabelBottomCard text="Total Eligible OPEX %" value={data?.total_eligible_opex_percentage.percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <OneValueLabelBottomCard text="Total Non-Eligible OPEX %" value={data?.total_non_eligible_opex_percentage} cardType="small-small-chart-card-size card-height-1"></OneValueLabelBottomCard>
      <TextCard title="Top sector for total opex_taxonomy_eligible_percentage is:" data={data?.top_sector_total_opex_taxonomy_eligible_percentage.funds} cardType="medium-chart-card-size card-height-1" valueOrPercentage="percentage" ></TextCard>
      <DoughnutChartCard title="Total Eligibility opex €" data={data?.total_eligible_opex_amount} cardType="medium-chart-card-size card-height-3"></DoughnutChartCard>
      <PieChartPercentageCard title="Eligible OPEX % by Sector" data={data?.eligible_opex_percentage_by_sector} cardType="medium-chart-card-size card-height-3" colors={COLORS_2}></PieChartPercentageCard>
      <HorizontalBarChartPercentageCard title="Eligible OPEX % by Economic Activity" data={data?.eligible_opex_percentage_by_economic_activity} dataKey="eligible" yAxisLabel="name" showLegend={true} categoryName="Taxonomy - Eligible" cardType="medium-chart-card-size card-height-3"></HorizontalBarChartPercentageCard>
      <TextCard title="Top 2 economic_activity for total opex_taxonomy_eligible_percentage are:" data={data?.top_economic_activity_total_opex_taxonomy_eligible_percentage.funds} cardType="medium-chart-card-size card-height-3" valueOrPercentage="percentage" ></TextCard>
    </div>
  );
}

export default NonFinancialCompanyProfile;