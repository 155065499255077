import React, { useState, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";

import "../ManageFund.scss"
import { ManageFundContext } from "../../../../utils/functions_variables/variables";

import FormField from "../FormField";

import { BorderTextButton, CardProperties, NormalInput, MainTitleTransparentCard } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../assets/styles/darkLightMode/themeContext";

const FundDetails = () => {
  const { theme } = useContext(ThemeContext)
  const { purpose, setTab, fundsNames, fundName, setFundName, setStepOneDone } = useContext(ManageFundContext)

  const [name, setName] = useState(fundName)
  const [nameFilled, setNameFilled] = useState(fundName !== '' && fundName.length > 2)
  const [sameName, setSameName] = useState(false)

  function handleNameChange(e) {
    setName(e)
    if (!fundsNames?.includes(e)) {
      setSameName(false)
      setFundName(e)
      if (e.length > 2) { setNameFilled(true) }
    } else {
      setNameFilled(false)
      setSameName(true)
    }
  }

  function handleSave() {
    setStepOneDone(true)
    setTab(2)
  }

  return (
    <>
      <MainTitleTransparentCard theme={theme} text="Details" />
      <div className="fund-details-div">
        <CardProperties theme={theme}>
          <FormField fieldName="Fund name" field={
            purpose === "details"
              ?
              <p className="font-style-montserrat-family-regular extra-small-text color-white">{name}</p>
              :
              <NormalInput theme={theme} className={nameFilled ? "border-green" : "border-red"} placeholder="Fund name" maxLength={45} value={name} onChange={(e) => handleNameChange(e?.target?.value)} autoFocus={true} required={true} type="text" />
          } />
          {sameName && <FormField fieldName="" field={<p className="name-already-exists font-style-montserrat-family-regular extra-small-text color-orange">This fund name already exists</p>} />}
        </CardProperties>
        {purpose !== "details" &&
          <BorderTextButton theme={theme} text="Save" className={`${nameFilled ? "button-hover" : "button-disabled"} margin-top-2`} onClick={handleSave} disabled={!nameFilled} />
        }
      </div>
    </>
  );
}

export default FundDetails;