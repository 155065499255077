
import React, { useState } from "react";

import "../../MultiStep.scss"

import InvesteeCompaniesTable from "../InvesteeCompaniesTable/InvesteeCompaniesTable";
import RealEstateAssetsTable from "../RealEstateAssetsTable/RealEstateAssetsTable";
import SovereignsSupranationalsTable from "../SovereignsSupranationalsTable/SovereignsSupranationalsTable";

const MainIndicators = ({ selectedFund, dataDynamic, dataStatic }) => {

    const indicator = "mainIndicators" 

    return (
        <div className="tables-main-div">
            <InvesteeCompaniesTable selectedFund={selectedFund} indicator={indicator} dataDynamic={dataDynamic?.investeeCompanies} dataStatic={dataStatic?.investeeCompanies} isAdditionalClimate={true} isAdditionalSocial={true}></InvesteeCompaniesTable>
            <SovereignsSupranationalsTable selectedFund={selectedFund} indicator={indicator} dataDynamic={dataDynamic?.sovereignsAndSupranationals} dataStatic={dataStatic?.sovereignsAndSupranationals}></SovereignsSupranationalsTable>
            <RealEstateAssetsTable selectedFund={selectedFund} indicator={indicator} dataDynamic={dataDynamic?.realEstateAssets} dataStatic={dataStatic?.realEstateAssets}></RealEstateAssetsTable>
        </div>
    );
}

export default MainIndicators;