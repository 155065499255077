import React, { useState } from "react";

import "./ManageSimulation.scss"

import ManageSimulationSteps from "./ManageSimulationSteps/ManageSimulationSteps";
import SimulationType from "./tabs/SimulationType";
import SimulationAssets from "./tabs/SimulationAssets";
import SimulationDetails from "./tabs/SimulationDetails";
import SimulationScenarios from "./tabs/SimulationScenarios";
import Simulate from "./tabs/Simulate";

import { ManageSimulationContext } from "../../../utils/functions_variables/variables";

const ManageSimulation = () => {
    const [tab, setTab] = useState(1)

    const [stepOneDone, setStepOneDone] = useState(false)

    const [stepTwoDone, setStepTwoDone] = useState(false)

    const CreateSimulationContent = () => {
        switch (tab) {
            case 1:
                return <SimulationType />
            case 2:
                return <SimulationAssets />
            case 3:
                return <SimulationScenarios />
            case 4:
                return <SimulationDetails />
            case 5:
                return <Simulate />
            default:
                return <SimulationType />
        }
    }

    return (
        <ManageSimulationContext.Provider value={{ tab, setTab, stepOneDone, setStepOneDone, stepTwoDone, setStepTwoDone }}>
            <div className="create-simulation-div">
                <ManageSimulationSteps />
                <div className="create-simulation-inside-div">
                    <CreateSimulationContent />
                </div>
            </div>
        </ManageSimulationContext.Provider>
    );
}

export default ManageSimulation;
