import React, { useState, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import "./MetricQuestionBox.scss"
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";
import { HiPlus } from "react-icons/hi";

import ModalWindow from "../ModalWindow/ModalWindow"
import { BorderTextIconFullWidthFullHeightButton } from "lynxai-ui-kit";

const MetricQuestionGraph = ({ text, children, sizeClass }) => {
    const { theme } = useContext(ThemeContext);
    const [isModalOpen, setisModalOpen] = useState(false);

    return (
        <>
            <BorderTextIconFullWidthFullHeightButton theme={theme} text={text} icon={<HiPlus className="icon color-white" />} onClick={() => setisModalOpen(!isModalOpen)} />
            <ModalWindow theme={theme} title={text} sizeClass={sizeClass ? "two-charts-visualization" : "charts-visualization"} open={isModalOpen} setOpen={setisModalOpen}>
                <div className="metric-question-graph-modal">
                    {children}
                </div>
            </ModalWindow>
        </>
    );
}

export default MetricQuestionGraph;