import React, { useContext, useState } from "react";

import { AiOutlineInfoCircle } from "react-icons/ai";
import "./InfoBox.scss"

const InfoBox = ({ showBox, setShowBox, boxPosition, setBoxPosition, text }) => {

    function handleMouseOver(event) {
        setShowBox(true);
    }

    function handleMouseMove(event) {
        setBoxPosition({ x: event?.clientX, y: event?.clientY });
    }

    function handleMouseOut(event) {
        setShowBox(false);
    }

    return (
        <div onMouseOver={handleMouseOver} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut} className="info-icon-div">
            <AiOutlineInfoCircle className="info-icon small-text" />
            {showBox && (
                <div x={boxPosition?.x} y={boxPosition?.y} className="info-box font-style-montserrat-family-regular extra-extra-small-text color-white">{text}</div>
            )}
        </div>
    );
}

export default InfoBox;