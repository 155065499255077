import React, { useContext } from "react";

import "../AssetProfile.scss"

import { AssetProfileContext } from "../../../utils/functions_variables/variables";
import { convertToBigNumbers } from "../../../utils/Maths/MathsFunctions";

import MultipleLinesChartWithThresholdCard from "../../DataVisualization/MultipleLinesChartWithThresholdCard/MultipleLinesChartWithThresholdCard";
import LineChartGraphCard from "../../DataVisualization/LineChartGraphCard/LineChartGraphCard";
import { OneOrTwoValueCard, MainTitleTransparentCard } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const AssetProfileEnvironmental = () => {
  const { theme } = useContext(ThemeContext)
  const { assetEnvironmentalData } = useContext(AssetProfileContext)
  const filteredEmissionsIntensityPerEurInvested = removeNullValues(assetEnvironmentalData?.emissionsIntensityPerEurInvested)
  const filteredEmissionsIntensityPerRevenue = removeNullValues(assetEnvironmentalData?.emissionsIntensityPerRevenue)

  function get_number_columns() {
    let row_items_number_class = 2
    if (assetEnvironmentalData?.rankingInPortfolio) {
      row_items_number_class += 1;
    }
    switch (row_items_number_class) {
      case 2:
        return "two"
      case 3:
        return "three"
      default:
        return "two"
    }
  }

  function removeNullValues(data) {
    return data?.filter(function (item) {
      return item?.value !== null;
    });
  }

  return (
    <div className="asset-profile-content">
      <MainTitleTransparentCard theme={theme} text="Metrics" />
      {assetEnvironmentalData?.rankingInPortfolio && <OneOrTwoValueCard theme={theme} text="Ranking in portfolio" data={assetEnvironmentalData?.rankingInPortfolio} type="oneValue" className={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      {<OneOrTwoValueCard theme={theme} text="Is it currently above or below the threshold?" data={0.01233} type="greenRedPercentage" className={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      {<OneOrTwoValueCard theme={theme} text="What were the lowest and highest emission values?" data={{ highest_value: convertToBigNumbers(assetEnvironmentalData?.highestValue), lowest_value: convertToBigNumbers(assetEnvironmentalData?.lowestValue) }} type="twoValuesWithArrows" className={`${get_number_columns()}-items small-small-chart-card-size card-height-1`} />}
      <MultipleLinesChartWithThresholdCard title="Total emissions" linesKeys={[{ label: "Scope 1", value: "scope1" }, { label: "Scope 2", value: "scope2" }, { label: "Scope 3", value: "scope3" }, { label: "Total", value: "total" }]} cardType="medium-chart-card-size card-height-2 card-height-3" multipleLines={true} data={assetEnvironmentalData?.emissionData} />
      {filteredEmissionsIntensityPerEurInvested?.length > 0
        &&
        (filteredEmissionsIntensityPerEurInvested?.length === 1
          ?
          <OneOrTwoValueCard theme={theme} text="CO2 emissions intensity (per EUR invested)" data={filteredEmissionsIntensityPerEurInvested[0].value} type="oneValue" className="two-items small-small-chart-card-size card-height-3" />
          :
          <LineChartGraphCard title="CO2 emissions intensity (per EUR invested)" cardType="medium-chart-card-size card-height-2 card-height-3" multipleLines={true} data={assetEnvironmentalData?.emissionsIntensityPerEurInvested} />
        )}
      {filteredEmissionsIntensityPerRevenue?.length > 0
        &&
        (filteredEmissionsIntensityPerRevenue?.length === 1
          ?
            <OneOrTwoValueCard theme={theme} text="CO2 emissions intensity (per revenue)" data={filteredEmissionsIntensityPerRevenue[0].value} type="oneValue" className="two-items small-small-chart-card-size card-height-3" />
          :
          <LineChartGraphCard title="CO2 emissions intensity (per revenue)" cardType="medium-chart-card-size card-height-2 card-height-3" multipleLines={true} data={assetEnvironmentalData?.filteredEmissionsIntensityPerRevenue} />
        )}
    </div>
  );
}

export default AssetProfileEnvironmental;