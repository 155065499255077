import styled from "styled-components";

export const Ul = styled.ul`
    width: 400px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0;
    z-index: 5;
    padding: var(--size-six);
    box-sizing: border-box;
    transition: transform 0.4s ease-in-out;
    -webkit-transition: transform 0.4s ease-in-out;
    -o-transition: transform 0.4s ease-in-out;
    -webkit-transition: transform 0.4s ease-in-out;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-105%)'};
}
`

export const RegulationsMenuButton = styled.ul`
    padding: var(--size-three) 0.75rem;
    position: absolute;
    align-items: center;
    left: 0;
    display: flex;
    height: fit-content;
    margin-top: 10px;
    border-radius: var(--border-radius) 0 0 5px;
    z-index: 4;
    border-top: 1px solid var(--main-blue);
    border-left: 1px solid var(--main-blue);
    border-bottom: 1px solid var(--main-blue);
    cursor: pointer;
    transition: transform 0.7s ease-in-out;
    -webkit-transition: transform 0.7s ease-in-out;
    -o-transition: transform 0.7s ease-in-out;
    -webkit-transition: transform 0.7s ease-in-out;
    transform: ${({ open }) => open ? 'rotate(180deg) translateX(20vw)' : 'rotate(180deg) translateX(0)'};
    writing-mode: vertical-lr;
`

export function darkenBackground(leftMenuOpen) {
  const overlay = document.getElementById('overlay')
  if (leftMenuOpen) {
    overlay.style.display = 'none'
  } else {
    overlay.style.display = 'block'
  }
}