import React from "react";

import "./ModalWindow.scss"
import { AiFillExclamationCircle } from "react-icons/ai";

const AlertBoxPAIStepRow = ({ children }) => {

    return (
        <div className="alert-box-pai-step-row-div">
            <AiFillExclamationCircle className="color-orange medium-text"></AiFillExclamationCircle>
            <p className="font-style-montserrat-family-regular extra-small-text color-white">{children};</p>
        </div>
    );
}

export default AlertBoxPAIStepRow;