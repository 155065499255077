import React, { useContext, useState, useEffect } from "react";

import OverviewTopBar from "../../components/OverviewTopBar/OverviewTopBar";
import OverviewFundContent from "../../components/OverviewFundContent/OverviewFundContent";
import LocalLoader from "../../components/LocalLoader/LocalLoader";

import { GeneralContext, OverviewContext } from "../../utils/functions_variables/variables";
import { getFunds, getOverviewCharacteristics, getOverviewEnvironmental } from "../../utils/requests/getRequests";

import metrics from "./optional_metrics.json"

export default function Overview() {
  const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
  const [isLoading, setIsLoading] = useState(true)
  const [tab, setTab] = useState(1)
  const [fundsList, setFundsList] = useState(null);
  const [overviewCharacteristicsData, setOverviewCharacteristicsData] = useState(null);
  const [overviewEnvironmentalData, setOverviewEnvironmentalData] = useState(null);
  const [fundSelectedMetrics, setFundSelectedMetrics] = useState([]);
  const [availableMetrics, setAvailableMetrics] = useState(metrics);

  const getFundsList = async () => {
    setIsLoading(true)
    await getFunds(databaseAddress).then(data => {
      const simplifiedData = data?.map(obj => { return { value: obj?.id, label: obj?.fund_name } }).sort((a, b) => a?.label?.localeCompare(b?.label))
      setFundsList(simplifiedData)
    }).catch(error => {
      setIsLoading(false)
      setWarningMessage(["error", error.message])
    })
  }

  const getOverviewCharacteristicsData = async () => {
    await getOverviewCharacteristics(databaseAddress).then(data => {
      setOverviewCharacteristicsData(data)
    }).catch(error => {
      setIsLoading(false)
      setWarningMessage(["error", error.message])
    })
  }

  const getOverviewEnvironmentalData = async () => {
    await getOverviewEnvironmental(databaseAddress).then(data => {
      let organizedData = organizeOverviewMetricsData(data)
      setOverviewEnvironmentalData(organizedData)
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
      setWarningMessage(["error", error.message])
    })
  }

  useEffect(() => {
    getFundsList().then(getOverviewCharacteristicsData().then(getOverviewEnvironmentalData()))
  }, [])

  function organizeOverviewMetricsData(data) {
    let organizedData = data
    organizedData["bestAndWorstAssetsPerEurInvested"] = data?.bestAssetsPerEurInvested?.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.worstAssetsPerEurInvested?.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
    delete organizedData?.bestAssetsPerEurInvested;
    delete organizedData?.worstAssetsPerEurInvested;
    organizedData["bestAndWorstAssetsPerRevenue"] = data?.bestAssetsPerRevenue?.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.worstAssetsPerRevenue?.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
    delete organizedData?.bestAssetsPerRevenue;
    delete organizedData?.worstAssetsPerRevenue;
    organizedData["bestAndWorstIndustriesPerEurInvested"] = data?.bestIndustriesPerEurInvested?.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.worstIndustriesPerEurInvested?.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
    delete organizedData?.bestIndustriesPerEurInvested;
    delete organizedData?.worstIndustriesPerEurInvested;
    organizedData["bestAndWorstIndustriesPerRevenue"] = data?.bestIndustriesPerRevenue?.map(item => ({ ...item, type: 'best', best: item.value, worst: null })).concat(data?.worstIndustriesPerRevenue?.map(item => ({ ...item, type: 'worst', best: null, worst: item.value })))
    delete organizedData?.bestIndustriesPerRevenue;
    delete organizedData?.worstIndustriesPerRevenue;

    return organizedData
  }

  return (
    <>
      <title>Lynxai | Overview</title>
      {isLoading ?
        <LocalLoader></LocalLoader>
        :
        <OverviewContext.Provider value={{ tab, setTab, fundsList, setFundsList, overviewCharacteristicsData, overviewEnvironmentalData, availableMetrics, setAvailableMetrics, metrics, fundSelectedMetrics }}>
          <div className="content-main-div">
            <OverviewTopBar />
            <OverviewFundContent />
          </div>
        </OverviewContext.Provider>
      }
    </>
  );
}