import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import FundDetails from "./tabs/FundDetails";
import FundAssets from "./tabs/FundAssets";
import FundRegulations from "./tabs/FundRegulations";
import ManageFundSteps from "../ManageFund/ManageFundSteps/ManageFundSteps";
import { GeneralContext, ManageFundContext, PortfolioContext } from "../../../utils/functions_variables/variables";
import { getAssets } from "../../../utils/requests/getRequests";
import { postCreateFund, postEditFund } from "../../../utils/requests/postRequests";
import { getFund } from "../../../utils/requests/getRequests";
import MainLoader from "../../MainLoader/MainLoader";
import FundProfile from "./FundProfile/FundProfile";

import "./ManageFund.scss"

const ManageFund = ({ purposeSet }) => {
    const navigate = useNavigate();
    const { fund } = useParams()

    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const { ManagePortfolioData, getFundsList } = useContext(PortfolioContext)

    const [loadingText, setLoadingText] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    let purpose = purposeSet

    const [tab, setTab] = useState(1)
    const [allStepsDone, setAllStepsDone] = useState(false)
    const [fundsNames, setFundsNames] = useState(ManagePortfolioData?.map(dict => dict["fund_name"]))

    const [fundID, setFundID] = useState("");
    const [fundName, setFundName] = useState("");
    const [stepOneDone, setStepOneDone] = useState(false)

    const [regulations, setRegulations] = useState({ 'pai': false, 'eu_taxonomy': false });
    const [stepTwoDone, setStepTwoDone] = useState(false)

    const [assetsList, setAssetsList] = useState([])
    const [selectedAsset, setSelectedAsset] = useState(assetsList[0])
    const [fundAssets, setFundAssets] = useState({})
    const [dates, setDates] = useState([new Date().toLocaleDateString('en-GB')])
    const [selectedDate, setSelectedDate] = useState(dates[0])
    const [createdAssets, setCreatedAssets] = useState({})
    const [stepThreeDone, setStepThreeDone] = useState(false)

    useEffect(() => {
        const getAssetsList = async () => {
            setIsLoading(true)
            await getAssets(databaseAddress).then(data => {
                const simplifiedData = data?.filter(item => item?.name !== null)?.map(obj => { return { value: obj?.id, label: obj?.name, ticker: obj?.ticker, isin: obj?.isin, asset_type: obj?.asset_type, created: false }; })?.sort((a, b) => a?.label?.localeCompare(b?.label))
                setAssetsList(simplifiedData)
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }

        const fundDetails = async (fundName) => {
            await getFund(databaseAddress, fundName).then(data => {
                loadEditFundFields(data)
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }

        getAssetsList()
        if ((purpose === "edit" || purpose === "profile")) {
            setTab(1)
            fundDetails(fund)
        } else {
            setIsLoading(false)
        }
    }, [purpose])

    function loadEditFundFields(data) {
        setFundID(data?.id)
        setFundName(fund)
        setFundAssets(generateFundAssets(data?.fundHistory))
    }

    function generateFundAssets(fundHistory) {
        let fundHistoryArr = {}
        for (let i = 0; i < fundHistory?.length; i++) {
            let assetsArr = []
            let date_formatted = new Date(fundHistory[i]?.date).toLocaleDateString('en-GB');
            for (let j = 0; j < fundHistory[i]?.assets?.length; j++) {
                assetsArr.push({ value: fundHistory[i]?.assets[j]?.id, keySecondary: Math.floor(Math.random() * 1000000000), label: fundHistory[i]?.assets[j]?.name, asset_type: fundHistory[i]?.assets[j]?.asset_type, investment_type: fundHistory[i]?.assets[j]?.investment_type, currency: fundHistory[i]?.assets[j]?.currency, amount: fundHistory[i]?.assets[j]?.nominal_amount, share: fundHistory[i]?.assets[j]?.share_amount })
            }
            fundHistoryArr[date_formatted] = assetsArr
        }
        let dates_sorted = Object.keys(fundHistoryArr)?.sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);
            return dateA - dateB;
        });
        setDates(dates_sorted)
        setSelectedDate(dates_sorted[dates_sorted?.length - 1])
        return fundHistoryArr
    }

    function mergeDicts(dict1, dict2) {
        const mergedDict = { ...dict1 };

        Object.keys(dict2)?.forEach(date => {
            if (mergedDict[date]) {
                mergedDict[date] = mergedDict[date]?.concat(dict2[date]);
            } else {
                mergedDict[date] = dict2[date];
            }
        });

        return mergedDict
    }

    async function handleFundCreation(e) {
        setLoadingText("Creating fund...")
        setIsLoading(true)
        const convertedAddedAssets = {};
        Object.keys(fundAssets)?.forEach(date => {
            convertedAddedAssets[date] = fundAssets[date]?.map(asset => {
                return {
                    name: asset?.label,
                    currency: asset?.currency,
                    nominal_amount: typeof asset?.amount === 'string' ? Number(parseFloat(asset?.amount.replace(/\s/g, ''))) : asset?.amount,
                    share_amount: typeof asset?.share === 'string' ? Number(parseFloat(asset?.share.replace(/\s/g, ''))) : asset?.share,
                    investment_type: asset?.investment_type,
                };
            });
        });
        const convertedCreatedAssets = {};
        Object.keys(createdAssets)?.forEach(date => {
            convertedCreatedAssets[date] = createdAssets[date]?.map(asset => {
                return {
                    ticker: asset?.ticker,
                    name: asset?.label,
                    currency: asset?.currency,
                    nominal_amount: typeof asset?.amount === 'string' ? Number(parseFloat(asset?.amount.replace(/\s/g, ''))) : asset?.amount,
                    share_amount: typeof asset?.share === 'string' ? Number(parseFloat(asset?.share.replace(/\s/g, ''))) : asset?.share,
                    country: asset?.country,
                    investment_type: asset?.investment_type,
                    asset_type: asset?.asset_type,
                    public_asset: asset?.public_asset,
                    isin: asset?.isin
                };
            });
        });

        const mergedDicts = mergeDicts(convertedAddedAssets, convertedCreatedAssets)

        const body = JSON.stringify({
            clientName: databaseAddress,
            fundName: fundName,
            fundHistory: mergedDicts
        })
        await postCreateFund(body).then(data => {
            getFundsList()
            setIsLoading(false)
            setWarningMessage(["success", "Fund created successfully."])
            navigate('/portfolio')
        }).catch(error => {
            setIsLoading(false)
            setLoadingText(null)
            setWarningMessage(["error", error.message])
        })
    }

    async function handleFundEdition(e) {
        setIsLoading(true)
        const convertedAddedAssets = {};
        Object.keys(fundAssets)?.forEach(date => {
            convertedAddedAssets[date] = fundAssets[date]?.map(asset => {
                return {
                    name: asset?.label,
                    currency: asset?.currency,
                    nominal_amount: typeof asset?.amount === 'string' ? Number(parseFloat(asset?.amount.replace(/\s/g, ''))) : asset?.amount,
                    share_amount: typeof asset?.share === 'string' ? Number(parseFloat(asset?.share.replace(/\s/g, ''))) : asset?.share,
                    investment_type: asset?.investment_type,
                };
            });
        });
        const convertedCreatedAssets = {};
        Object.keys(createdAssets)?.forEach(date => {
            convertedCreatedAssets[date] = createdAssets[date]?.map(asset => {
                return {
                    ticker: asset?.ticker,
                    name: asset?.label,
                    currency: asset?.currency,
                    nominal_amount: typeof asset?.amount === 'string' ? Number(parseFloat(asset?.amount.replace(/\s/g, ''))) : asset?.amount,
                    share_amount: typeof asset?.share === 'string' ? Number(parseFloat(asset?.share.replace(/\s/g, ''))) : asset?.share,
                    country: asset?.country,
                    asset_type: asset?.asset_type,
                    public_asset: asset?.public_asset,
                    isin: asset?.isin,
                    investment_type: asset?.investment_type,
                };
            });
        });

        const mergedDicts = mergeDicts(convertedAddedAssets, convertedCreatedAssets)

        await postEditFund(databaseAddress, fundName, mergedDicts).then(data => {
            getFundsList()
            setIsLoading(false)
            navigate('/portfolio')
            setWarningMessage(["success", "Fund edited successfully."])
        }).catch(error => {
            setIsLoading(false)
            setLoadingText(null)
            setWarningMessage(["error", error.message])
        })
    }

    const CreateFundContent = () => {
        switch (tab) {
            case 1:
                return <FundDetails />
            case 2:
                return <FundRegulations />
            case 3:
                return <FundAssets />
            default:
                return <FundDetails />
        }
    }

    if (isLoading) {
        return <MainLoader text={loadingText}></MainLoader>;
    }

    return (
        <ManageFundContext.Provider value={{ purpose, tab, setTab, allStepsDone, setAllStepsDone, fundsNames, setFundsNames, fundID, setFundID, fundName, setFundName, stepOneDone, setStepOneDone, regulations, setRegulations, stepTwoDone, setStepTwoDone, assetsList, setAssetsList, selectedAsset, setSelectedAsset, fundAssets, setFundAssets, dates, setDates, selectedDate, setSelectedDate, createdAssets, setCreatedAssets, stepThreeDone, setStepThreeDone }}>
            {purpose === "profile" ?
                <FundProfile fund={fund} fundsList={ManagePortfolioData?.map(obj => { return { value: obj?.id, label: obj?.fund_name } }).sort((a, b) => a?.label?.localeCompare(b?.label))} />
                :
                <div className="create-fund-div">
                    <ManageFundSteps handleFundCreation={handleFundCreation} handleFundEdition={handleFundEdition} />
                    <div className="create-fund-inside-div">
                        <CreateFundContent />
                    </div>
                </div>
            }
        </ManageFundContext.Provider>
    );
}

export default ManageFund;