
import React, { useContext, useState, useRef, useEffect } from "react";

import { getPAIInputFieldsData } from "../../../../utils/requests/getRequests";
import { putPAIInput } from "../../../../utils/requests/putRequests";

import "../MultiStep.scss"

import { IoChevronDown } from "react-icons/io5";
import { GeneralContext } from "../../../../utils/functions_variables/variables";
import LocalLoader from "../../../LocalLoader/LocalLoader";
import { BorderTextButton, BorderIconTextFullWidthButton, Textarea } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../assets/styles/darkLightMode/themeContext";

const StepFour = ({ selectedFund, step, stepsInfo, setStepsInfo }) => {
    const { theme } = useContext(ThemeContext)
    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const contentRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true)
    const [articlesOpen, setArticlesOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const textareaRef = React.useRef(null);
    const [placeholderText, setPlaceholderText] = useState("");
    const [valueText, setValueText] = useState("");
    const [buttonEnable, setButtonEnable] = useState(false)

    useEffect(() => {
        if (contentRef?.current) {
            setHeight(contentRef.current.scrollHeight);
        }
    }, [articlesOpen]);

    const toggleShow = () => {
        setArticlesOpen(!articlesOpen);
    }

    const handleChangeText = (evt) => {
        const val = evt?.target?.value;
        setValueText(val);
    };

    function checkFields() {
        if (valueText !== '') {
            setButtonEnable(true)
        } else { setButtonEnable(false) }
    }

    useEffect(() => {
        checkFields()
    }, [valueText]);

    function cleanFields() {
        setValueText("")
    }

    const getInputFieldsData = async () => {
        setIsLoading(true)
        await getPAIInputFieldsData(databaseAddress, selectedFund?.label, "step4", 4).then(data => {
            if (Object.keys(data)?.length === 0) {
                cleanFields()
            } else {
                if (data?.step4) { setValueText(data?.step4); }
                let stepFilled = false
                if (data?.step4 !== '') {
                    stepFilled = true
                }
                setStepsInfo(prevState => ({ ...prevState, stepFour: { filled: stepFilled } }))
            }
            setIsLoading(false)
        }).catch(error => {
            cleanFields()
            setIsLoading(false)
            setWarningMessage(["error", error.message])
        })
    }

    useEffect(() => {
        getInputFieldsData()
    }, [selectedFund])

    const handleSaveStep = async (e) => {
        e.preventDefault();
        if (buttonEnable) {
            setIsLoading(true)
            const bodyContent = JSON.stringify({ client: databaseAddress, fundName: selectedFund?.label, insertValue: { step4: valueText } })
            await putPAIInput(bodyContent, 4).then(data => {
                getInputFieldsData()
                setStepsInfo(prevState => ({ ...prevState, stepFour: { filled: true } }))
                setIsLoading(false)
                setWarningMessage(["success", "Content saved successfully."])
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    }

    if (isLoading) {
        return <LocalLoader></LocalLoader>;
    }

    return (
        <div style={{ display: step === 4 ? 'flex' : 'none' }} className="steps-main-div color-white">
            <div className="articles">
                <BorderIconTextFullWidthButton theme={theme} text="Articles" icon={<IoChevronDown className={`icon ${articlesOpen && "close-180-deg"}`} />} onClick={toggleShow} />
                <div ref={contentRef} className="articles-div" style={{ height: articlesOpen ? `${height}px` : '0', marginBottom: articlesOpen ? `15px` : '0' }}>
                    <p className="title font-style-montserrat-family-semibold extra-small-text">Article 8 - Engagement policies section</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">1. </label>In the section ‘Engagement policies’ in Table 1 of Annex I, financial market participants shall provide all of the following information:<br />
                        <label className="font-style-montserrat-family-semibold">(a)</label> where applicable, brief summaries of the engagement policies referred to in Article 3g of Directive 2007/36/EC of the European Parliament and of the Council;<br />
                        <label className="font-style-montserrat-family-semibold">(b)</label> brief summaries of any other engagement policies to reduce principal adverse impacts.</p>
                    <p className="text font-style-montserrat-family-regular extra-small-text "><label className="font-style-montserrat-family-semibold">2. </label>The brief summaries referred to in paragraph 1 shall describe all of the following:<br />
                        <label className="font-style-montserrat-family-semibold">(a)</label> theindicatorsforadverseimpactsconsideredintheengagementpoliciesreferredtoinparagraph1;<br />
                        <label className="font-style-montserrat-family-semibold">(b)</label> how those engagement policies will be adapted where there is no reduction of the principal adverse impacts over more than one period reported on.</p>
                </div>
            </div>
            <div className="step-three-four-five margin-top-2 margin-bottom-3">
            <Textarea theme={theme} ref={textareaRef} placeholder={placeholderText === "" ? "Engagement Policies" : placeholderText} maxLength={1200} type="text" rows={1} value={valueText} onChange={e => handleChangeText(e?.target?.value)} required={true} />
                <BorderTextButton theme={theme} text="Save" className={`${buttonEnable ? "button-hover" : "button-disabled"} margin-top-2`} onClick={handleSaveStep} type="submit" disabled={!buttonEnable} />
            </div>
        </div>
    );
}

export default StepFour;