import emailjs from '@emailjs/browser';

import { postSupportMessage } from './postRequests';

const openSupportTicket = async (form) => {

    // SEND EMAIL
    emailjs.sendForm('platform_support_ticket', 'platform_support_ticket', form?.current, 'JEJuGBtp056M6p2Xu')

    const formData = new FormData(form?.current);
    const formFields = Object.fromEntries(formData?.entries())

    // STORE IT IN DB
    var params = {
        "userName": formFields?.email,
        "content": formFields?.message
    }

    const response = await postSupportMessage(JSON.stringify(params)).then(data => {
        return ["success", "Ticket submitted successfully. We will reply within 48 hours."]
    }).catch(error => {
        throw new Error("Submission Failed. Please try again later or, if the problem persists, contact our support team.");
    })
    return response
}

export default openSupportTicket;