import React from "react";
import "./LocalLoader.scss";

const main_loader = require('../../assets/images/main_loader.gif')

function LocalLoader({ size, text }) {
  return (
   <div className={`${size} local-loader-container`}>
    <img style={{ margin: text !== null ? '35px' : null }} src={main_loader} alt="img"/>
      {text && <p className="font-style-montserrat-family-regular small-text color-white">{text}</p>}
   </div>
  );
}

export default LocalLoader;