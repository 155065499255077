import { useEffect } from "react";

const useAutosizeTextArea = (textAreaRef, value) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.minHeight = "30px";
      let scrollHeight = "";
      if (value === "") {
        textAreaRef.style.height = "100%";
      } else {
        setTimeout(() => {
          scrollHeight = textAreaRef.scrollHeight;
        }, 20)
        setTimeout(() => {
          textAreaRef.style.height = scrollHeight + "px";
        }, 30)
      }
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
