import regulations_endpoints from "../endpoints/regulations_endpoints.json"

export async function putPAIInput(bodyContent, step_number) {
    const url = regulations_endpoints[process.env.REACT_APP_ENVIRONMENT]?.sfdr?.put_pai
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "X-Api-Key": process.env.REACT_APP_API_KEY,
        'Accept': 'application/json',
      },
      body: bodyContent
    })
  
    if (response?.ok) {
      return response.json()
    } else {
      throw new Error("An error occurred when trying to save the input from step " + step_number + " data. Please try again later or, if the problem persists, contact our support team.");
    }
  }