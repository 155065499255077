
import React, { useState, useContext } from "react";
import AnimateHeight from 'react-animate-height';

import "../../MultiStep.scss"

import { BiRightArrow } from "react-icons/bi";

import TableOrganizer from "../Table/TableOrganizer";
import { BorderIconTextFullWidthButton } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../../assets/styles/darkLightMode/themeContext";

const SovereignsSupranationalsTable = ({ selectedFund, dataDynamic, dataStatic, indicator }) => {
  const { theme } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false)
    const [height, setHeight] = useState(0);
    const table = "sovereignsAndSupranationals";

  return (
    <div className="pai-table-div">
            <BorderIconTextFullWidthButton theme={theme} text="INDICATORS APPLICABLE TO INVESTMENTS IN SOVEREIGNS AND SUPRANATIONALS" className="margin-bottom-1" icon={<BiRightArrow className={`extra-extra-small-text icon ${isOpen ? "close-90-deg" : ""}`} />} onClick={function () { setIsOpen(!isOpen); setHeight(height === 0 ? 'auto' : 0) }} />
            <AnimateHeight id="example-panel" duration={500} height={height}>
            <table>
                <thead>
                    <tr>
                        <th colSpan="2">Adverse Sustainability Indicator</th>
                        <th className="col-medium">Metric</th>
                        <th className="col-small">Impact [year n]</th>
                        <th className="col-small">Impact [year n-1]</th>
                        <th className="col-big-big">Explanation</th>
                        <th className="col-big-big">Actions taken, and actions planned and targets set for the next reference period</th>
                    </tr>
                </thead>
                <TableOrganizer selectedFund={selectedFund} indicator={indicator} table={table} dataDynamic={dataDynamic} dataStatic={dataStatic} investmentType="sovereignsAndSupranationals"/>
            </table>
            </AnimateHeight>
            </div>
  );
}

export default SovereignsSupranationalsTable;