import React, { useState, useContext } from "react";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Rectangle } from 'recharts';
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";
import { AiOutlineExpand } from "react-icons/ai";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { NoBorderIconButton, CardProperties, ThemeVariables } from "lynxai-ui-kit";

const BarChartPercentageThreeBarsCard = (props) => {
    const { title, data, cardType, modal } = props;
    const { theme, setTheme } = useContext(ThemeContext);

    const [isModalOpen, setisModalOpen] = useState(false);

    const ticks = [0, 0.25, 0.5, 0.75, 1];
    const labelFormatter = (x) => {
        return ((x / 1) * 100).toFixed(1).replace(/[.,]0/, "") + '%'
    };

    return (
        <>
            <CardProperties theme={theme} className={`${modal && 'background-transparent'} ${cardType}`}>
                {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton theme={theme} onClick={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                <ResponsiveContainer width="100%" height="100%" className="extra-extra-small-text font-style-montserrat-family-regular">
                    <BarChart width="100%" height="100%" data={data} >
                        <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.medium_color_50}` }} vertical={false} />
                        <XAxis dataKey="name" tick={{ width: 130, fill: `${ThemeVariables[theme]?.extreme_contrast}` }} interval={0} tickLine={false} />
                        <YAxis ticks={ticks} tickCount={25} tickFormatter={(tick) => { return `${(tick * 100)}%` }} hide />
                        <Bar shape={<Rectangle radius={[3, 3, 0, 0]} />} animationDuration={800} isAnimationActive={true} dataKey="turnover" name="Portfolio Aligned Turnover % - Non_Financials" fill="#36E7FF" minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.light_contrast}`, formatter: labelFormatter, position: "top" }}>
                        </Bar>
                        <Bar shape={<Rectangle radius={[3, 3, 0, 0]} />} animationDuration={800} isAnimationActive={true} dataKey="capex" name="Portfolio Aligned CAPEX % - Non_Financials" fill="#0071dc" minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.light_contrast}`, formatter: labelFormatter, position: "top" }}>
                        </Bar>
                        <Bar shape={<Rectangle radius={[3, 3, 0, 0]} />} animationDuration={800} isAnimationActive={true} dataKey="opex" name="Portfolio Aligned OPEX % - Non_Financials" fill="#35D272" minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.light_contrast}`, formatter: labelFormatter, position: "top" }}>
                        </Bar>
                        <Legend wrapperStyle={{ maxWidth: "40%", whiteSpace: "break-spaces", paddingLeft: 10 }} iconType="square" formatter={(value) => <span className="legend-items-piechart font-style-montserrat-family-regular color-white">{value}</span>} layout="vertical" verticalAlign="middle" align="right" />
                    </BarChart>
                </ResponsiveContainer>
            </CardProperties>
            <ModalWindow theme={theme} title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                <BarChartPercentageThreeBarsCard title={title} data={data} cardType="modal-card" modal={true}></BarChartPercentageThreeBarsCard>
            </ModalWindow>
        </>
    );
}

export default BarChartPercentageThreeBarsCard;