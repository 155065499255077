import React, { useState, useContext } from "react";

import "../ManageFund.scss"
import { ManageFundContext } from "../../../../utils/functions_variables/variables";

import CheckboxField from "../CheckboxField";
import FormField from "../FormField";

import { BorderTextButton, CardProperties, CheckboxInput, MainTitleTransparentCard } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../assets/styles/darkLightMode/themeContext";

const FundRegulations = () => {
  const { theme } = useContext(ThemeContext)
  const { purpose, setTab, regulations, setRegulations, setStepTwoDone } = useContext(ManageFundContext)


  function handlePAIChange(e) {
    setRegulations({ ...regulations, 'pai': !regulations["pai"] })
  }

  function handleEUTaxonomyChange(e) {
    setRegulations({ ...regulations, 'eu_taxonomy': !regulations["eu_taxonomy"] })
  }

  function handleSave() {
    setStepTwoDone(true)
    setTab(3)
  }

  return (
    <>
      <MainTitleTransparentCard theme={theme} text="Regulations" />
      <div className="fund-details-div">
        {purpose === "details" ?
          <CardProperties theme={theme}>
            <FormField fieldName="EU Taxonomy" field={<p className="font-style-montserrat-family-regular extra-small-text color-white">{regulations["eu_taxonomy"] ? "Yes" : "No"}</p>} />
            <FormField fieldName="PAI" field={<p className="font-style-montserrat-family-regular extra-small-text color-white">{regulations["pai"] ? "Yes" : "No"}</p>} />
          </CardProperties>
          :
          <>
            <CardProperties theme={theme}>
              <CheckboxField text="PAI" infoBoolean={true} infoText="A Principal Adverse Impact (PAI) is any impact of investment decisions or advice that results in a negative effect on sustainability factors, such as environmental, social and employee concerns, respect for human rights, anti-corruption, and anti-bribery matters.">
                <CheckboxInput checked={regulations["pai"]} onChange={handlePAIChange} />
              </CheckboxField>
              <CheckboxField className="margin-top-3" text="EU Taxonomy" infoBoolean={true} infoText="The EU taxonomy is a classification system, establishing a list of environmentally sustainable economic activities.">
                <CheckboxInput checked={regulations["eu_taxonomy"]} onChange={handleEUTaxonomyChange} />
              </CheckboxField>
            </CardProperties>
            <BorderTextButton theme={theme} text="Save" className={`button-hover margin-top-2`} onClick={handleSave} />
            <p className="margin-top-3 extra-small-text font-style-montserrat-family-regular color-white">Select the regulations you would like this fund to report to.</p>
            <p className="margin-top-2 extra-small-text font-style-montserrat-family-regular color-orange">If you do not wish to report on any of these regulations, click "Save" and proceed to the next step.</p>
            <p className="margin-top-1 extra-small-text font-style-montserrat-family-regular color-orange">This can be changed after the creation of the fund.</p>
          </>
        }
      </div>
    </>
  );
}

export default FundRegulations;