import React, { useState, useContext } from "react";

import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Rectangle } from 'recharts';
import { AiOutlineExpand } from "react-icons/ai";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { NoBorderIconButton, CardProperties, ThemeVariables } from "lynxai-ui-kit";

const HorizontalBarChartPercentageCard = (props) => {
    const { children, title, data, cardType, yAxisLabel, dataKey, categoryName, showLegend, modal } = props;
    const { theme, setTheme } = useContext(ThemeContext);

    const [isModalOpen, setisModalOpen] = useState(false);

    const ticks = [0, 0.25, 0.5, 0.75, 1];
    const labelFormatter = (x) => {
        if (x < 0.001) {
            return "<0.001%"
        } else {
            return ((x / 1) * 100).toFixed(1).replace(/[.,]0/, "") + '%'
        }
    };

    return (
        <>
            {data?.length > 0 &&
                <>
                    <CardProperties theme={theme} className={`${modal && 'background-transparent'} ${cardType}`}>
                    {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton theme={theme} onClick={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                        <ResponsiveContainer id="percentage-bar-chart" width="100%" height="100%" className="font-style-montserrat-family-regular extra-extra-small-text color-white">
                            <BarChart width="100%" height="100%" data={data} layout="vertical" margin={showLegend ? { top: 15 } : { top: 15, right: 30 }}>
                                <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.medium_color_50}` }} horizontal={false} />
                                <XAxis hide type="number" axisLine={false} ticks={ticks} tickCount={0.25} tickFormatter={(tick) => { return `${(tick * 100)}%` }} />
                                <YAxis tick={{ fill: `${ThemeVariables[theme]?.extreme_contrast}` }} tickLine={false} dataKey={yAxisLabel} type="category" width={130} tickMargin={10} />
                                <Bar shape={<Rectangle radius={[0, 3, 3, 0]} />} animationDuration={800} dataKey={dataKey} name={categoryName} minPointSize={2} barSize={45} fill="#0071dc" label={{ fill: `${ThemeVariables[theme]?.light_contrast}`, formatter: labelFormatter, position: "right" }} />
                                {showLegend &&
                                    <Legend wrapperStyle={{ maxWidth: "20%", whiteSpace: "break-spaces", paddingLeft: 10 }} iconType="square" formatter={(value) => <span className="legend-items-piechart font-style-montserrat-family-regular color-white">{value}</span>} layout="vertical" verticalAlign="top" align="right" />}
                                {children}
                            </BarChart>
                        </ResponsiveContainer>
                    </CardProperties>
                    <ModalWindow theme={theme} title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                        <HorizontalBarChartPercentageCard title={title} showLegend={showLegend} data={data} dataKey={dataKey} yAxisLabel={yAxisLabel} categoryName={categoryName} cardType="modal-card" modal={true}></HorizontalBarChartPercentageCard>
                    </ModalWindow>
                </>
            }
        </>
    );
}

export default HorizontalBarChartPercentageCard;