import React, { useState, useEffect, useRef, useContext } from "react";

import "../Regulations.scss"

import LocalLoader from "../../../components/LocalLoader/LocalLoader";
import MainDescriptionCard from "../../../components/Cards/MainDescriptionCard/MainDescriptionCard";
import FilterCompanyCard from "../../../components/Cards/FilterCompanyCard/FilterCompanyCard";
import FullWidthBarChartCard from "../../../components/DataVisualization/FullWidthBarChartCard/FullWidthBarChartCard";
import BigOneValueCard from "../../../components/Cards/BigOneValueCard/BigOneValueCard";
import MultipleBarChartPercentageCard from "../../../components/DataVisualization/MultipleBarChartPercentageCard/MultipleBarChartPercentageCard";

import { getEUTaxonomyInsurance } from "../../../utils/requests/getRequests";
import { COLORS_1, COLORS_2 } from "../../../assets/styles/global";
import { GeneralContext } from "../../../utils/functions_variables/variables";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";
import { CardProperties } from "lynxai-ui-kit";

const InsuranceCompanyProfile = () => {
  const { theme } = useContext(ThemeContext);
  const { setWarningMessage } = useContext(GeneralContext)
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef?.current) return;
    dataFetchedRef.current = true;

    const EUTaxonomyInsurance = async () => {
      setIsLoading(true)
      await getEUTaxonomyInsurance().then(data => {
        setData(data);
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    EUTaxonomyInsurance()
  }, [])

  if (isLoading) {
    return <LocalLoader></LocalLoader>;
  }

  return (
    <div className="regulations-content">
      <CardProperties theme={theme} className="full-width"><p className="font-style-montserrat-family-semibold small-text color-white">EU Taxonomy</p></CardProperties>
      <FilterCompanyCard cardType="big-big-chart-card-size" data_1={data?.green_asset_ratio} data_2={data?.economic_exposure_taxonomy} data_3={data?.banking_book_aligned_percentage} companies={data?.companies}></FilterCompanyCard>
      <MainDescriptionCard title="Anual Report Comments" description={data?.anual_report_comments} cardType="big-big-chart-card-size"></MainDescriptionCard>
      <FullWidthBarChartCard title="Non-Life Premium % Eligibility" data={data?.non_life_premium_percentage_eligibility} cardType="big-chart-card-size card-height-3" colors={COLORS_1}></FullWidthBarChartCard>
      <BigOneValueCard title="Taxonomy Net Premium Written" data={data?.taxonomy_net_premium_written} cardType="small-chart-card-size card-height-3"></BigOneValueCard>
      <MultipleBarChartPercentageCard title="Taxonomy Exposures % of Financial Institutions" data={data?.taxonomy_exposures_percentage_financial_institutions} cardType="big-big-chart-card-size" colors={COLORS_2}></MultipleBarChartPercentageCard>
      </div>
  );
}

export default InsuranceCompanyProfile;