import React, { useContext, useState } from "react";

import { AiOutlineInfoCircle } from "react-icons/ai";
import "./InfoBoxTable.scss"

const InfoBoxTable = ({ text }) => {
    const [showBox, setShowBox] = useState(false);
    const [boxPosition, setBoxPosition] = useState({ x: 0, y: 30 });

    function handleMouseOver(event) {
        setShowBox(true)
    }

    function handleMouseOut(event) {
        setShowBox(false)
    }

    return (
        <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className="info-table-icon-div">
            <AiOutlineInfoCircle className="info-icon-table small-text" />
            {showBox && (
                <div x={boxPosition?.x} y={boxPosition?.y} className="info-box-table font-style-montserrat-family-regular extra-extra-small-text color-white">{text}</div>
            )}
        </div>
    );
}

export default InfoBoxTable;