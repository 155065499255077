import React, { useState, useContext } from "react";
import { Ul } from "./LeftSlideMenuStyles"
import { darkenBackground } from "../../components/LeftSlideMenu/LeftSlideMenuStyles"

import { RxCross2 } from "react-icons/rx";

import LeftSlideMenuItem from "./LeftSlideMenuItem";
import LeftSlideMenuData from "./LeftSlideMenuData.json"

import "./LeftSlideMenu.scss"
import { NoBorderIconButton } from "lynxai-ui-kit";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

const LeftSlideMenu = (props) => {
    const { theme } = useContext(ThemeContext);
    const { leftMenuOpen, setleftMenuOpen } = props;
    const [openedMenuIndex, setOpenedMenuIndex] = useState(null);

    const closeMenu = () => {
        setleftMenuOpen(!leftMenuOpen);
        darkenBackground(leftMenuOpen);
        setTimeout(() => {
            setOpenedMenuIndex(null)
        }, 500)
    }

    return (
        <>
            <div id="overlay" onClick={() => closeMenu()}></div>
            <Ul open={leftMenuOpen} className="Ul font-style-montserrat-family-semibold color-white">
                <div className="left-slide-menu-title small-text color-white margin-bottom-4">
                    Regulations
                    <NoBorderIconButton theme={theme} onClick={closeMenu} icon={<RxCross2 className="icon small-text color-white" />} />
                </div>
                {LeftSlideMenuData?.map((item, index) => <LeftSlideMenuItem key={index} item={item} closeMenu={closeMenu} isOpened={openedMenuIndex === index} onClickSubnav={() => { openedMenuIndex === index ? setOpenedMenuIndex(null) : setOpenedMenuIndex(index) }}></LeftSlideMenuItem>)}
            </Ul>
        </>
    );
}

export default LeftSlideMenu;