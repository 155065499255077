
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

function descendingComparator(a, b, orderBy) {
  let value_a = null;
  let value_b = null;
  if (orderBy?.numeric) {
    value_a = parseFloat(a[orderBy?.id])
    value_b = parseFloat(b[orderBy?.id])
  } else {
    value_a = a[orderBy?.id]?.toLowerCase()
    value_b = b[orderBy?.id]?.toLowerCase()
  }
  if (value_b < value_a) {
    return -1;
  }
  if (value_b > value_a) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } =
    props;
  const createSortHandler =
    (property) => (event) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell className="table-head"
            key={headCell?.id}
            align='left'
            sortDirection={orderBy.id === headCell?.id ? order : false}>
            {headCell?.label !== '' &&
              <TableSortLabel className="header-cell font-style-montserrat-family-semibold extra-small-text color-white"
                active={orderBy.id === headCell?.id}
                direction={orderBy.id === headCell?.id ? order : 'asc'}
                onClick={createSortHandler(headCell)}
                classes={{ active: 'active', icon: ((orderBy.id === headCell?.id) ? "sort-icon-active" : "") }}>
                <p>{headCell?.label}</p>
                {orderBy.id === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}