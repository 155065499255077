import React, { useState, useEffect, useContext } from "react";

import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";
import { Tooltip } from 'react-tooltip'
import { convertToBigNumbers } from "../../../utils/Maths/MathsFunctions";
import { AiOutlineExpand } from "react-icons/ai";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { scaleLinear } from "d3-scale";
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import { FlexBlockDiv } from "../../../assets/styles/global";
import world_countries_geo from "../../../utils/jsons/world_countries_geo.json"

import "./ChoroplethMapWorldCard.scss"
import 'react-tooltip/dist/react-tooltip.css'

import { NoBorderIconButton, CardProperties } from "lynxai-ui-kit";

const ChoroplethMapWorldCard = (props) => {
    const { title, data, fullHeight, cardType, modal } = props;
    const { theme, setTheme } = useContext(ThemeContext);
    const [content, setContent] = useState("");
    const [isModalOpen, setisModalOpen] = useState(false);

    let colorScale = null
    if (data) {
        colorScale = scaleLinear()
            .domain([0, 1])
            .range([
                "#d3ebd4",
                "#006702"
            ]);
    }

    return (
        <>
            {
                data?.length > 0 &&
                <>
                    <CardProperties theme={theme} className={`${modal && 'background-transparent'} ${cardType}`}>
                    {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton theme={theme} onClick={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                        <div className={`composable-map-world-div ${fullHeight ? "composable-map-world-full-height" : "composable-map-world-small-height"}`}>
                            <Tooltip noArrow position="absolute" top={20} className="extra-extra-small-text font-style-montserrat-family-regular color-white" id="map-tooltip" content={content}></Tooltip>
                            <ComposableMap className="composable-map-full-height composable-map"
                                projectionConfig={{
                                    center: [0, 5],
                                    scale: 240
                                }}>
                                <Geographies data-tooltip-id="map-tooltip" geography={world_countries_geo}>
                                    {({ geographies }) =>
                                        geographies?.map((geo) => {
                                            const cur = data?.find((s) => s?.label === geo?.properties?.name);
                                            const calc_percentage = (cur?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "") + "% (" + convertToBigNumbers(cur?.value) + ")"
                                            return (
                                                <Geography onMouseEnter={() => { cur ? setContent(`${geo?.properties?.name}: ${calc_percentage}`) : setContent(`${geo?.properties?.name}: 0%`) }} onMouseLeave={() => { setContent(""); }}
                                                    key={geo?.rsmKey}
                                                    geography={geo}
                                                    stroke="var(--gray-6)" style={{ hover: { fill: "var(--main-blue)", outline: "none" }, pressed: { fill: "var(--main-blue)", outline: "none" } }}
                                                    fill={cur ? colorScale(cur?.percentage) : "white"} />);
                                        })}
                                </Geographies>
                            </ComposableMap>
                        </div>
                    </CardProperties>
                    <ModalWindow theme={theme} title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                        <ChoroplethMapWorldCard title={title} data={data} fullHeight={true} cardType="modal-card" modal={true}></ChoroplethMapWorldCard>
                    </ModalWindow>
                </>
            }
        </>
    );
}

export default ChoroplethMapWorldCard;