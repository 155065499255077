import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink } from "react-router-dom";

import "./AssetsHistoryManagement.scss"
import { HiPlus } from "react-icons/hi";
import { CiCalendarDate, CiFileOn, CiCircleInfo } from "react-icons/ci";

import ModalWindow from "../../../ModalWindow/ModalWindow"
import AssetsHistoryManagementDateButton from "./AssetsHistoryManagementDateButton";
import AddDateForm from "./AddDateForm";
import { ManageFundContext } from "../../../../utils/functions_variables/variables";

import { BorderIconTextButton, NoBorderTextUnderlineButton, CardProperties } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../assets/styles/darkLightMode/themeContext";

const AssetsHistoryManagement = () => {
  const { theme } = useContext(ThemeContext)
  const { purpose, dates, setDates, setSelectedDate } = useContext(ManageFundContext)

    const [displayAddDate, setDisplayAddDate] = useState(false)
    const [displayExplanation, setDisplayExplanation] = useState(false)
    const [hasActiveOverflow, setHasActiveOverflow] = useState(false);
    const scrollableDivRef = useRef(null);

    useEffect(() => {
        function handleScroll() {
            if (scrollableDivRef?.current) {
                const scrollableDiv = scrollableDivRef.current;
                const hasOverflow = scrollableDiv.scrollWidth > scrollableDiv.clientWidth
                setHasActiveOverflow(hasOverflow);
            } else {
                setTimeout(handleScroll, 100);
            }
        };

        handleScroll()
    }, [dates])

    function handleAddDate() {
        setDisplayAddDate(!displayAddDate)
    }

    function addDate(date) {
        const newDates = [...dates, date]
        newDates?.sort((a, b) => {
            const [dayA, monthA, yearA] = a.split('/');
            const [dayB, monthB, yearB] = b.split('/');

            const dateA = new Date(`${monthA}/${dayA}/${yearA}`);
            const dateB = new Date(`${monthB}/${dayB}/${yearB}`);

            return dateA - dateB;
        });
        setDates(newDates)
        setSelectedDate(date)
    }

    return (
        <>
            <div className="assets-history-main-div">
                <CardProperties theme={theme} className="upper-div">
                    <p className="font-style-montserrat-family-semibold extra-small-text color-white">
                        Snapshot date
                    </p>
                    {purpose !== "details" &&
                        <>
                        <NoBorderTextUnderlineButton theme={theme} text="What is this?" className="margin-right-auto" onClick={() => setDisplayExplanation(true)} />
                            <BorderIconTextButton theme={theme} text="Add date" icon={<HiPlus className="icon" />} onClick={handleAddDate} />
                        </>
                    }
                </CardProperties>
                <div ref={scrollableDivRef} className={`assets-history-dates-div ${hasActiveOverflow ? 'active-overflow' : ''}`}>
                    {dates?.map((item, index) => {
                        return <AssetsHistoryManagementDateButton key={index} date={item} />
                    }
                    )}
                </div>
            </div>
            <ModalWindow theme={theme} title="Snapshot date" sizeClass="create-asset-create-fund" open={displayAddDate} setOpen={setDisplayAddDate}>
                <AddDateForm purpose={purpose} open={displayAddDate} setOpen={setDisplayAddDate} dates={dates} addDate={addDate} />
            </ModalWindow>
            <ModalWindow theme={theme} sizeClass="create-asset-create-fund" open={displayExplanation} setOpen={setDisplayExplanation}>
                <div className="explanation-div">
                    <p className="font-style-montserrat-family-semibold extra-small-text color-white">
                        This page facilitates the addition and management of diverse assets on a timeline. Here's an overview of how it operates:
                    </p>
                    <p className="font-style-montserrat-family-regular extra-small-text color-white">
                        <CiCalendarDate className="icon medium-text color-blue" />
                        Dates: Users have the capability to create and select multiple dates, from the present or the past.
                    </p>
                    <p className="font-style-montserrat-family-regular extra-small-text color-white">
                        <CiFileOn className="icon medium-text color-blue" />
                        Assets: When an asset is selected or created, it is necessary to provide essential details about the asset. By doing so, we ensure comprehensive and accurate information is available for each asset.
                    </p>
                    <p className="font-style-montserrat-family-regular extra-small-text color-white">
                        <CiCircleInfo className="icon medium-text color-blue" />
                        Asset information: Once you selected or created an asset, it is required to fill a few fields about that asset so that we can have the most complete information about them.
                    </p>
                    <p className="font-style-montserrat-family-regular extra-small-text color-white">
                        It is not mandatory to add historical data about the funds.
                    </p>
                    <p className="font-style-montserrat-family-regular extra-small-text color-white">
                        If you have any additional inquiries or require further assistance, we kindly recommend visiting our <NavLink to="/configurations/faq" state={{ from: 2 }}>FAQ</NavLink> page first. It contains answers to frequently asked questions and may provide a quick solution to your query. If you cannot find the information you need or have any other concerns, please do not hesitate to contact our <NavLink to="/configurations/support" state={{ from: 3 }}>support</NavLink> team.
                    </p>
                </div>
            </ModalWindow>
        </>
    );
}

export default AssetsHistoryManagement;