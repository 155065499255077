import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

import { Tooltip } from 'react-tooltip'
import { convertToBigNumbers } from "../../../utils/Maths/MathsFunctions";
import { AiOutlineExpand } from "react-icons/ai";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { scaleLinear } from "d3-scale";
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import { FlexBlockDiv } from "../../../assets/styles/global";
import world_countries_geo from "../../../utils/jsons/world_countries_geo.json"

import "./ChoroplethMapEuropeCard.scss"
import 'react-tooltip/dist/react-tooltip.css'

import { NoBorderIconButton, CardProperties } from "lynxai-ui-kit";

const ChoroplethMapEuropeCard = (props) => {
    const { title, cardType, data, modal } = props;
    const { theme, setTheme } = useContext(ThemeContext);
    const [content, setContent] = useState("");
    const [isModalOpen, setisModalOpen] = useState(false);

    const colorScale = scaleLinear()
        .domain([0, 1])
        .range([
            "#d3ebd4",
            "#006702"
        ]);

    return (
        <>
            <CardProperties theme={theme} className={`${modal && 'background-transparent'} ${cardType}`}>
                {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton theme={theme} onClick={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                <div id="composable-map-europe-div">
                    <Tooltip noArrow position="absolute" top={20} className="extra-extra-small-text font-style-montserrat-family-regular color-white" id="map-tooltip" content={content}></Tooltip>
                    <ComposableMap id="composable-map"
                        projection="geoAzimuthalEqualArea"
                        projectionConfig={{
                            rotate: [-20, -55, 10],
                            center: [5, 0],
                            scale: 850
                        }}>
                        <Geographies data-tooltip-id="map-tooltip" geography={world_countries_geo}>
                            {({ geographies }) =>
                                geographies?.map((geo) => {
                                    const cur = data?.find((s) => s?.country === geo?.properties?.name);
                                    const calc_percentage = cur?.amount ? (cur?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "") + "% (" + convertToBigNumbers(cur?.amount) + ")" : "0"
                                    return (
                                        <Geography onMouseEnter={() => { setContent(`${geo?.properties?.name}: ${calc_percentage}`); }} onMouseLeave={() => { setContent(""); }}
                                            key={geo?.rsmKey}
                                            geography={geo}
                                            stroke="var(--gray-6)" style={{ hover: { fill: "var(--main-blue)", outline: "none" }, pressed: { fill: "var(--main-blue)", outline: "none" } }}
                                            fill={cur ? colorScale(cur?.percentage) : "white"}
                                        />);
                                })}
                        </Geographies>
                    </ComposableMap>
                </div>
            </CardProperties>
            <ModalWindow theme={theme} title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                <ChoroplethMapEuropeCard title={title} data={data} cardType="modal-card" modal={true}></ChoroplethMapEuropeCard>
            </ModalWindow>
        </>
    );
}

export default ChoroplethMapEuropeCard;