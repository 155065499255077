
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { AiOutlineUnorderedList, AiFillExclamationCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { TbFileDollar, TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { IoCheckmarkCircle } from "react-icons/io5";

import { ManageFundContext } from "../../../../utils/functions_variables/variables";
import { BackgroundStraightAnglesFullWidthIconButton, BackgroundStraightAnglesFullWidthIconTextButton, ExpandableLeftMenuTabButton, ExpandableLeftMenuTopButton, NoBorderIconButton, NoBorderTextButton } from "lynxai-ui-kit";
import { HiPlus } from "react-icons/hi";
import { TfiPencil } from "react-icons/tfi";
import { RxCross2 } from "react-icons/rx";
import { ThemeContext } from "../../../../assets/styles/darkLightMode/themeContext";

export default function ManageFundSteps({ handleFundCreation, handleFundEdition }) {
  const { theme } = useContext(ThemeContext);
  const { purpose, tab, setTab, allStepsDone, setAllStepsDone, fundID, fundName, stepOneDone, stepTwoDone, stepThreeDone } = useContext(ManageFundContext)
  const [menuOpen, setMenuOpen] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    function checkAllSteps() {
      if (stepOneDone && stepTwoDone && stepThreeDone) {
        setAllStepsDone(true)
      } else {
        setAllStepsDone(false)
      }
    }

    checkAllSteps()
  }, [stepOneDone, stepTwoDone, stepThreeDone]);

  function handleNewFundSubmition() {
    handleFundCreation()
  }

  function handleEditFundSubmission() {
    handleFundEdition()
  }

  return (
    <div className="create-fund-menu-div">
      <ExpandableLeftMenuTopButton theme={theme} text={purpose === "edit" ? "Edit Fund" : "Create Fund"} className="margin-bottom-1" icon={menuOpen ? <TbLayoutSidebarLeftCollapse className="icon small-text color-white" /> : <TbLayoutSidebarLeftExpand className="margin-center icon small-text color-white" />} open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
      <ExpandableLeftMenuTabButton theme={theme} text="Details" className={tab === 1 ? "active" : "inactive"} statusIcon={stepOneDone ? <IoCheckmarkCircle className="status-icon color-green small-text"></IoCheckmarkCircle> : <AiFillExclamationCircle className="status-icon color-orange small-text"></AiFillExclamationCircle>} icon={<AiOutlineInfoCircle className="icon small-text color-white" />} open={menuOpen} onClick={() => setTab(1)} />
      <ExpandableLeftMenuTabButton theme={theme} text="Regulations" className={tab === 2 ? "active" : "inactive"} statusIcon={stepTwoDone ? <IoCheckmarkCircle className="status-icon color-green small-text"></IoCheckmarkCircle> : <AiFillExclamationCircle className="status-icon color-orange small-text"></AiFillExclamationCircle>} icon={<AiOutlineUnorderedList className="icon small-text color-white" />} open={menuOpen} onClick={() => setTab(2)} />
      <ExpandableLeftMenuTabButton theme={theme} text="Assets" className={tab === 3 ? "active" : "inactive"} statusIcon={stepThreeDone ? <IoCheckmarkCircle className="status-icon color-green small-text"></IoCheckmarkCircle> : <AiFillExclamationCircle className="status-icon color-orange small-text"></AiFillExclamationCircle>} icon={<TbFileDollar className="icon small-text color-white" />} open={menuOpen} onClick={() => setTab(3)} />
      {menuOpen ?
        <BackgroundStraightAnglesFullWidthIconTextButton theme={theme} className={`${!allStepsDone ? "button-disabled" : "button-hover"} margin-top-2`} text={purpose === "edit" ? "Edit" : "Create"} icon={purpose === "edit" ? <TfiPencil className="icon small-text color-white" /> : <HiPlus className="icon small-text color-white" />} onClick={purpose === "edit" ? handleEditFundSubmission : handleNewFundSubmition} disabled={!allStepsDone} />
        :
        <BackgroundStraightAnglesFullWidthIconButton theme={theme} className={`${!allStepsDone ? "button-disabled" : "button-hover"} margin-top-2`} icon={purpose === "edit" ? <TfiPencil className="small-text" /> : <HiPlus className="medium-text" />} onClick={purpose === "edit" ? handleEditFundSubmission : handleNewFundSubmition} disabled={!allStepsDone} />
      }
      <NavLink className="nav-link-comp" to="/portfolio">
        {menuOpen ?
          <NoBorderTextButton theme={theme} className="margin-top-1" text="Cancel" />
          :
          <NoBorderIconButton theme={theme} className="margin-top-1" icon={<RxCross2 className="icon small-text color-white" />} />
        }
      </NavLink>
    </div>
  );
}
