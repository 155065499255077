import React, { useContext } from "react";
import { ManageFundContext } from "../../../utils/functions_variables/variables";

import "./ManageFund.scss"

import FormField from "./FormField";
import { InputOptionSingleValue, NoBorderTextUnderlineButton, NormalDropdown, SearchBarControlReactSelect } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const AddAsset = ({ handleNotFound }) => {
    const { theme } = useContext(ThemeContext);
    const { assetsList, fundAssets, setFundAssets, selectedAsset, setSelectedAsset, selectedDate, createdAssets, setCreatedAssets } = useContext(ManageFundContext)

    const filterOptions = (option, inputValue) => {
        const { label, isin, ticker, asset_type } = option?.data;
        const searchValue = inputValue?.toLowerCase();
        return (
            (isin !== null && isin?.toLowerCase().includes(searchValue)) ||
            (ticker !== null && ticker?.toLowerCase().includes(searchValue)) ||
            (asset_type !== null && asset_type?.toLowerCase().includes(searchValue)) ||
            label?.toLowerCase().includes(searchValue)
        );
    };

    function handleAddAsset(selectedOption) {
        setSelectedAsset(selectedOption);
        let newArray = {}
        if (selectedOption?.created) {
            newArray = {
                ...createdAssets,
                [selectedDate]: [
                    {
                        value: selectedOption?.value,
                        keySecondary: Math.floor(Math.random() * 1000000000),
                        label: selectedOption?.label,
                        currency: "",
                        amount: "",
                        share: "",
                        investment_type: "",
                        isin: selectedOption?.isin,
                        ticker: selectedOption?.ticker,
                        public_asset: selectedOption?.public,
                        country: selectedOption?.countries,
                        asset_type: selectedOption?.asset_type
                    },
                    ...(createdAssets[selectedDate] || [])
                ]
            };
            setCreatedAssets(newArray);
        } else {
            newArray = {
                ...fundAssets,
                [selectedDate]: [
                    {
                        value: selectedOption?.value,
                        keySecondary: Math.floor(Math.random() * 1000000000),
                        label: selectedOption?.label,
                        investment_type: "",
                        currency: "",
                        amount: "",
                        share: "",
                        asset_type: selectedOption?.asset_type
                    },
                    ...(fundAssets[selectedDate] || [])
                ]
            };
            setFundAssets(newArray);
        }
    }

    const getOptionLabel = (option) => {
        let option_label = option?.label
        option_label = option_label + ((option?.ticker && option?.ticker !== "") || (option?.isin && option?.isin) || (option?.asset_type && option?.asset_type !== "") ? " (" : "")
        option_label = option_label + ((option?.ticker && option?.ticker !== "") ? option?.ticker + ((option?.isin && option?.isin) || (option?.asset_type && option?.asset_type !== "") ? " / " : "") : "")
        option_label = option_label + ((option?.isin && option?.isin !== "") ? option?.isin + ((option?.asset_type && option?.asset_type !== "") ? " / " : "") : "")
        option_label = option_label + ((option?.asset_type && option?.asset_type !== "") ? option?.asset_type : "")
        option_label = option_label + ((option?.ticker && option?.ticker !== "") || (option?.isin && option?.isin) || (option?.asset_type && option?.asset_type !== "") ? ")" : "")
        return option_label
    };
    const getOptionValue = (option) => option?.value;

    return (
        <div className="add-asset-div">
            <FormField fieldName="Search asset" field={
                <NormalDropdown theme={theme} noOptionsMessage={() => <p className="extra-small-text font-style-montserrat-family-regular">No assets found. Check if it is correct and, if yes, create a new asset.</p>} value={selectedAsset} options={assetsList} placeholder="Search an asset..." onChange={handleAddAsset}
                    filterOption={filterOptions} getOptionLabel={getOptionLabel} getOptionValue={getOptionValue} autoFocus={true} components={{ Option: InputOptionSingleValue, Control: SearchBarControlReactSelect }} />
            } />
            <div className="not-found-actions-div">
                <label className="not-found-label extra-small-text font-style-montserrat-family-regular color-white">Not found in our database?</label>
                <NoBorderTextUnderlineButton theme={theme} text="Give us some info about the asset" onClick={handleNotFound} />
            </div>
        </div>
    );
}

export default AddAsset;