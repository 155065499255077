import * as AWS from 'aws-sdk'

AWS.config.update({
    region: 'eu-west-2',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID
})

export async function getFileFromBucket(bucket_name, template_name, file_name, file_type) {
    const s3 = new AWS.S3();

    const params = {
        Bucket: bucket_name,
        Key: template_name,
    };

    const data = await s3.getObject(params).promise()
    let blob = new Blob([data?.Body], {
        type: file_type,
    });
    downloadBlob(blob, file_name);
}

function downloadBlob(blob, template) {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = template;
    document.body.appendChild(link);
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );
    document.body.removeChild(link);
}