import React, { useContext } from 'react';

import "./AssetProfile.scss"
import { AssetProfileContext } from '../../utils/functions_variables/variables';

import { AiOutlineReload } from "react-icons/ai";
import { ImArrowLeft2 } from "react-icons/im";

import { NoBorderIconButton } from "lynxai-ui-kit";
import { FlexBlockDiv } from '../../assets/styles/global';
import { ThemeContext } from '../../assets/styles/darkLightMode/themeContext';

const AssetProfileTopBar = () => {
  const { theme } = useContext(ThemeContext);
  const { tab, assetName, assetTicker, goBack, selectTabNumber, handleReloadPage } = useContext(AssetProfileContext)

    return (
        <>
            <FlexBlockDiv>
                <NoBorderIconButton theme={theme} onClick={goBack} icon={<ImArrowLeft2 className="icon medium-text color-lightgray" />} />
                <div className="title-div">
                    <p className="title font-style-montserrat-family-semibold color-white small-text">{assetName}</p>
                    <p className="ticker font-style-montserrat-family-regular color-dark-gray extra-small-text">{assetTicker}</p>
                </div>
                <NoBorderIconButton theme={theme} onClick={handleReloadPage} icon={<AiOutlineReload className="icon medium-text color-white" />} />
            </FlexBlockDiv>
            <div className="multi-tab-main-div">
                <ul className="form-stepper">
                    <li className={`form-stepper-${tab === 1 ? "active" : "unfinished"} form-stepper-list`} onClick={() => selectTabNumber(1)} tab="1">
                        <div className="label font-style-montserrat-family-regular small-text">CO2 emissions</div>
                    </li>
                    <li className={`form-stepper-${tab === 2 ? "active" : "unfinished"} form-stepper-list`} tab="2" onClick={() => selectTabNumber(2)}>
                        <div className="label font-style-montserrat-family-regular small-text">Characteristics</div>
                    </li>
                    <li className="form-stepper-unfinished form-stepper-list"></li>
                </ul>
            </div>
        </>
    );
}

export default AssetProfileTopBar;