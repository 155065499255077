import React, { useEffect, useState, useContext } from "react";
import "./FilterFieldCard.scss";
import { CardProperties, NormalDropdown, NormalControlReactSelect, CheckboxOptionReactSelect, IsMultiOptionButton } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

function FilterFieldCard({ cardType, title, listContent, data }) {
    const { theme } = useContext(ThemeContext);
    const [selectedOptions, setSelectedOptions] = useState([]);

    function handleSelectedOptions(value) {
        setSelectedOptions(value)
    }

    function removeSelectedOptions(option) {
        let new_options = selectedOptions
        setSelectedOptions(new_options?.filter(element => element.label !== option))
    }

    return (
        <CardProperties theme={theme} className={`filter-field-card-main-div ${cardType}`}>
            <p className="extra-small-text font-style-montserrat-family-semibold color-white">{title}</p>
            <NormalDropdown theme={theme} className="full-width" closeMenuOnSelect={false} hideSelectedOptions={false} controlShouldRenderValue={false} isMulti={true} placeholder="Select filter" noOptionsMessage={() => <p className="extra-small-text font-style-montserrat-family-regular">No results match your searching criteria.</p>}
                options={listContent} onChange={handleSelectedOptions} value={selectedOptions} components={{ Option: CheckboxOptionReactSelect, Control: NormalControlReactSelect }} />
            {selectedOptions.length > 0 && <div className="is-multi-selected-options-div">
                {
                    selectedOptions?.map((item) => (
                        <IsMultiOptionButton key={item.value} text={item.label} onClick={() => removeSelectedOptions(item.label)} />
                    ))}
            </div>}
            <div className="filter-field-card-stat">
                <p className="font-style-montserrat-family-semibold large-text color-white">{`${(data?.percentage * 100).toFixed(1).replace(/[.,]0/, "")}%`}</p>
                <p className="font-style-montserrat-family-regular small-text color-white">{data?.name}</p>
            </div>
        </CardProperties>
    );
}

export default FilterFieldCard;