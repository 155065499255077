import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import validator from 'validator'
import { Auth } from "aws-amplify";

import "../../views/Authentication/Authentication.scss"

import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";
import MainLoader from "../MainLoader/MainLoader";
import { GeneralContext } from "../../utils/functions_variables/variables";
import { NormalInput, BorderTextFullWidthButton, NoBorderTextUnderlineButton, CardProperties, PasswordInput } from "lynxai-ui-kit";
import { handleKeyDownEvent } from "../../utils/functions_variables/functions";

export default function LogIn({ setNewPasswordRequiredUser, setNewPasswordRequired, setResetPassword, previousLocation }) {
    const { theme } = useContext(ThemeContext);
    const lynxai_full_logo = (theme === 'light' ? require('../../assets/images/lynxai_full_logo_black_blue.png') : require('../../assets/images/lynxai_full_logo_white_blue.png'));
    const { setWarningMessage, setUserName, setUserEmail, setDatabaseAddress, setUserRoles } = useContext(GeneralContext)
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false)
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [buttonEnable, setButtonEnable] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const from = previousLocation?.pathname || "/";

    useEffect(() => {
        function checkFields() {
            if (validator.isEmail(loginEmail) && loginPassword !== '') {
                setButtonEnable(true)
            } else { setButtonEnable(false) }
        }

        checkFields()
    }, [loginEmail, loginPassword]);

    useEffect(() => {
        const checkLocationIndex = () => {
            if (state?.info) {
                setWarningMessage(["success", state?.info])
            }
        }
        checkLocationIndex()
    }, [state]);

    const navigate = useNavigate();

    function handleResetPassword(event) {
        setResetPassword(true)
    }

    const togglePasswordVisibility = (event) => {
        event.preventDefault();
        setShowPassword(!showPassword);
    };

    async function handleLogin(e) {
        e.preventDefault();
        if (buttonEnable) {
            setIsLoading(true)
            await Auth?.signIn(loginEmail, loginPassword).then((user) => {
                if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setNewPasswordRequiredUser(user)
                    setNewPasswordRequired(true)
                    setIsLoading(false)
                    setWarningMessage(["info", "A new password is required."])
                } else {
                    Auth?.currentAuthenticatedUser().then(user => {
                        if (user?.signInUserSession?.accessToken?.payload["cognito:groups"] !== undefined) {
                            setUserName(user?.attributes?.name)
                            setUserEmail(user?.attributes?.email)
                            setDatabaseAddress(user?.attributes?.address)
                            setUserRoles(user?.signInUserSession?.accessToken.payload["cognito:groups"])
                            setIsLoading(false)
                            navigate(from);
                        } else {
                            throw new Error("Your subscription is not active. Please contact our support.");
                        }
                    }).catch((error) => {
                        setIsLoading(false)
                        setWarningMessage(["error", error.message])
                    })
                }
            }).catch((error) => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    }

    if (isLoading) {
        return <MainLoader></MainLoader>;
    }

    return (
        <CardProperties theme={theme} className="authentication-main-div">
            <img src={lynxai_full_logo} alt="img" />
            <div className="divider margin-top-3 margin-bottom-1"></div>
            <h1 className="margin-top-3 margin-bottom-2 font-style-montserrat-family-semibold small-text color-lightgray">Sign in to your account</h1>
            <form onSubmit={handleLogin}>
                <NormalInput theme={theme} className="margin-bottom-1" placeholder="Email" value={loginEmail} onChange={(e) => setLoginEmail(e?.target?.value)} autoFocus={true} required={true} type="email" onKeyDown={(e) => handleKeyDownEvent(handleLogin, e)} />
                <PasswordInput theme={theme} placeholder="Password" required={true} autoComplete="on" value={loginPassword} showPassword={showPassword} onChange={(e) => setLoginPassword(e?.target?.value)} onKeyDown={(e) => handleKeyDownEvent(handleLogin, e)} toggleShowPassword={togglePasswordVisibility} />
                <NoBorderTextUnderlineButton theme={theme} className="margin-left-auto margin-top-1" text="Forgot password?" type="button" onClick={handleResetPassword} />
                <BorderTextFullWidthButton theme={theme} text="Sign in" onClick={handleLogin} className={`${buttonEnable ? "button-hover" : "button-disabled"} margin-top-2`} type="submit" disabled={!buttonEnable} />
            </form>
        </CardProperties>
    );
}