import funds_endpoints from "../endpoints/portfolio_funds_endpoints.json"
import support_endpoints from "../endpoints/support_endpoints.json"

//FUND
export async function postCreateFund(bodyContent) {
  const url = funds_endpoints[process.env.REACT_APP_ENVIRONMENT]?.create_fund
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    },
    body: bodyContent
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to create this fund. Please try again later or, if the problem persists, contact our support team.");
  }
}

export async function postEditFund(databaseAddress, fundName, content) {
  const url = funds_endpoints[process.env.REACT_APP_ENVIRONMENT]?.edit_fund
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      clientName: databaseAddress,
      fundName: fundName,
      // fundType: ,
      // country: ,
      fundHistory: content
    })
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to edit this fund. Please try again later or, if the problem persists, contact our support team.");
  }
}

//SUPPORT
export async function postSupportMessage(bodyContent) {
  const url = support_endpoints[process.env.REACT_APP_ENVIRONMENT]?.support_email
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    },
    body: bodyContent
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to submit a support ticket. Please try again later or, if the problem persists, contact our support team.");
  }
}