import React, { useContext } from "react";

import "./OneValueLabelBottomCard.scss"

import { CardProperties } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const OneValueLabelBottomCard = ({ text, value, cardType }) => {
    const { theme } = useContext(ThemeContext);

    return (
        <CardProperties theme={theme} className={`${cardType} extra-small-text font-style-montserrat-family-regular color-white`}>
            <p className="one-value-card-value large-text font-style-montserrat-family-semibold">{` ${(value * 100)?.toFixed(1).replace(/[.,]0/, "")}% `}</p>
            {text}
        </CardProperties>
    );
}

export default OneValueLabelBottomCard;