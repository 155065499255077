import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";
import { GeneralContext } from '../../utils/functions_variables/variables';

import MainLoader from '../MainLoader/MainLoader';
import { Auth } from "aws-amplify";

import "./Navbar.scss"
import { handleThemeChange, HandleNavigationSearchBar, HandleInputChangeSearchBar } from '../../utils/functions_variables/functions';
import { getFunds, getAssets } from '../../utils/requests/getRequests';

import { FiUser } from "react-icons/fi";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlineLightMode } from "react-icons/md";
import { BsQuestionOctagon, BsChatLeftDots, BsChevronDown, BsMoon } from "react-icons/bs";
import { VscSignOut } from "react-icons/vsc";
import { ThemeVariables, ExpandableLeftMenuTabButton, SearchBarControlReactSelect, NavbarDropdownSearchBar, GroupHeadingReactSelect, ReactSelectNavbarFundOrAssetOption } from "lynxai-ui-kit";

const Navbar = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const { setWarningMessage, userName, setUserName, userEmail, setUserEmail, databaseAddress, setDatabaseAddress, setUserRoles } = useContext(GeneralContext);
  const [fundsList, setFundsList] = useState(null)
  const [assetsList, setAssetsList] = useState(null)
  const [fundsAssetsList, setFundsAssetsList] = useState(null);
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const lynxai_full_logo = (theme === 'light' ? require('../../assets/images/lynxai_full_logo_black_blue.png') : require('../../assets/images/lynxai_full_logo_white_blue.png'));
  const navigate = useNavigate()

  useEffect(() => {
    const getFundsList = async () => {
      setIsLoading(true)
      await getFunds(databaseAddress).then(data => {
        const simplifiedData = data?.map(obj => { return { value: obj?.id, label: obj?.fund_name, nr_assets: obj?.nr_assets, type: "fund" } }).sort((a, b) => a?.label?.localeCompare(b?.label))
        setFundsList(simplifiedData)
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    const getAssetsList = async () => {
      setIsLoading(true)
      await getAssets(databaseAddress).then(data => {
        const simplifiedData = data?.map(obj => { return { value: obj?.id, label: obj?.name, ticker: obj?.ticker, type: "asset", asset_type: obj?.asset_type } }).sort((a, b) => a?.label?.localeCompare(b?.label))
        setAssetsList(simplifiedData)
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    getFundsList()
    getAssetsList()
  }, [])

  useEffect(() => {
    setFundsAssetsList([{
      label: 'Funds',
      options: fundsList,
    },
    {
      label: 'Assets',
      options: assetsList,
    }])
  }, [fundsList, assetsList]);

  async function handleLogout() {
    setIsLoading(true)
    await Auth?.signOut().then(() => {
      setUserName('')
      setUserEmail('')
      setDatabaseAddress('')
      setUserRoles('')
      setIsLoading(false)
      navigate('/')
    }).catch((error) => {
      setWarningMessage(["info", error.message])
      setIsLoading(false)
    });
  }
  const HandleNavigation = (event) => {
    HandleNavigationSearchBar(navigate, event, setInputText)
  }

  const HandleInput = (input, action) => {
    HandleInputChangeSearchBar(input, action, setInputText)
  }

  if (isLoading) {
    return <MainLoader></MainLoader>;
  }

  return (
    <div className="header-div">
      <div className="icon-div">
        <NavLink to="/overview">
          <img className="name-img" src={lynxai_full_logo} alt="img" />
        </NavLink>
      </div>
      <div className="navbar">
        <NavLink className="nav-link-comp" to="/overview" style={({ isActive }) => ({ color: isActive ? 'var(--main-blue)' : `${ThemeVariables[theme]?.extreme_contrast}` })}>
          <div className="navbar-link small-text font-style-montserrat-family-semibold">Overview</div>
        </NavLink>
        <NavLink className="nav-link-comp" to="/portfolio" style={({ isActive }) => ({ color: isActive ? 'var(--main-blue)' : `${ThemeVariables[theme]?.extreme_contrast}` })}>
          <div className="navbar-link small-text font-style-montserrat-family-semibold">Portfolio</div>
        </NavLink>
        <NavLink className="nav-link-comp" to="/simulator" style={({ isActive }) => ({ color: isActive ? 'var(--main-blue)' : `${ThemeVariables[theme]?.extreme_contrast}` })}>
          <div className="navbar-link small-text font-style-montserrat-family-semibold">Simulator</div>
        </NavLink>
        <NavLink className="nav-link-comp" to="/regulations" style={({ isActive }) => ({ color: isActive ? 'var(--main-blue)' : `${ThemeVariables[theme]?.extreme_contrast}` })}>
          <div className="navbar-link small-text font-style-montserrat-family-semibold">Regulations</div>
        </NavLink>
      </div>
      <div className="navbar-search-user-div">
        <NavbarDropdownSearchBar theme={theme} className="navbar-dropdown-search-bar" options={fundsAssetsList} placeholder="Search..." inputValue={inputText} onChange={HandleNavigation}
          onInputChange={HandleInput} components={{ GroupHeading: (props) => <GroupHeadingReactSelect theme={theme} children={props.children} />, Control: SearchBarControlReactSelect, Option: ReactSelectNavbarFundOrAssetOption }} />
        <div className="navbar-user-div">
          <button className="user-button-extended-dropdown color-white">
            <div className="user-details-div">
              <p className="user-name font-style-montserrat-family-semibold extra-small-text">{userName}</p>
              <p className="user-name font-style-montserrat-family-regular extra-extra-small-text">{userEmail}</p>
            </div>
            <BsChevronDown className="icon small-text"></BsChevronDown>
          </button>
          <button className="user-button-collapsed-dropdown color-white">
            <FaRegUserCircle className="small-text" />
            <BsChevronDown className="icon small-text"></BsChevronDown>
          </button>
          <div className="navbar-user-options-dropdown">
            <ExpandableLeftMenuTabButton theme={theme} text="Profile" icon={<FiUser className="extra-small-text icon" />} open={true} className="inactive" onClick={() => navigate('/configurations/profile', { state: { from: 1 } })} />
            <ExpandableLeftMenuTabButton theme={theme} text={theme === 'dark' ? "Light mode" : "Dark mode"} icon={theme === 'dark' ? <MdOutlineLightMode className="icon extra-small-text color-white" /> : <BsMoon className="icon extra-small-text color-white" />} open={true} className="inactive" onClick={() => handleThemeChange(theme, setTheme)} />
            <div className="divider margin-top-1 margin-bottom-1" />
            <ExpandableLeftMenuTabButton theme={theme} text="FAQ" icon={<BsQuestionOctagon className="extra-small-text icon" />} open={true} className="inactive" onClick={() => navigate('/configurations/faq', { state: { from: 2 } })} />
            <ExpandableLeftMenuTabButton theme={theme} text="Support" icon={<BsChatLeftDots className="extra-small-text icon" />} open={true} className="inactive" onClick={() => navigate('/configurations/support', { state: { from: 3 } })} />
            <div className="divider margin-top-1 margin-bottom-1" />
            <ExpandableLeftMenuTabButton theme={theme} text="Terms & Conditions" icon={<BsChatLeftDots className="extra-small-text icon" />} open={true} className="inactive" onClick={() => navigate('/configurations/terms', { state: { from: 4 } })} />
            <ExpandableLeftMenuTabButton theme={theme} text="Sign out" icon={<VscSignOut className="extra-small-text icon" />} open={true} className="inactive" onClick={handleLogout} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;