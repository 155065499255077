
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { handleKeyDownEvent } from "../../utils/functions_variables/functions"
import ModalWindow from "../ModalWindow/ModalWindow";
import { closeModal } from "../ModalWindow/ModalUtils";
import { convertToBigNumbers, roundStringToTwoDecimalPlacesOrMinorLabel } from "../../utils/Maths/MathsFunctions";
import LocalLoader from "../LocalLoader/LocalLoader"
import { GeneralContext, PortfolioContext } from "../../utils/functions_variables/variables";
import { deleteFund } from "../../utils/requests/deleteRequests";

import "./ManagePortfolio.scss"

import { getComparator, stableSort, EnhancedTableHead } from "../../utils/functions_variables/table_functions/TableCompFunctions";
import { BiTrash } from "react-icons/bi";
import { TfiPencil } from "react-icons/tfi";
import { HiPlus } from "react-icons/hi";
import { AiOutlineCheckCircle, AiOutlineReload } from "react-icons/ai";
import { BsHourglass } from "react-icons/bs";

import { BorderIconTextButton, BorderTextButton, NoBorderTextButton, NoBorderIconButton, NoBorderTextUnderlineButton, CardProperties, NormalInput, NormalSearchBar } from "lynxai-ui-kit";
import { FlexBlockDiv } from "../../assets/styles/global";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

const translate_state = {
  'ready': 'Ready',
  'calculating': 'Calculating',
}
const state_icon = {
  'ready': <AiOutlineCheckCircle className="large-text"></AiOutlineCheckCircle>,
  'calculating': <BsHourglass className="large-text"></BsHourglass>,
}

export default function ManagePortfolio({ handleFundSearchChange }) {
  const { theme } = useContext(ThemeContext)
  const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
  const { ManagePortfolioData, filteredFunds, getFundsList, isLoading, setIsLoading, fundSearch } = useContext(PortfolioContext)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState({ id: 'fund_name', numeric: false, label: "Name" });
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [confirmedInput, setConfirmedInput] = useState(false);
  const [dataToDelete, setDataToDelete] = useState("");
  const headCells = [
    {
      id: 'status',
      numeric: false,
      label: 'Status',
    },
    {
      id: 'fund_name',
      numeric: false,
      label: 'Name',
    },
    {
      id: 'nr_assets',
      numeric: true,
      label: 'Number assets',
    },
    {
      id: 'amount',
      numeric: true,
      label: 'Amount (EUR)',
    },
    {
      id: 'portfolio_weight_percentage',
      numeric: true,
      label: 'Weight',
    },
    {
      id: '',
      numeric: false,
      label: '',
    },
  ];

  const handleRequestSort = (event, property) => {  // DO NOT REMOVE THE EVENT ARGUMENT. STOPS WORKING.
    const isAsc = orderBy.id === property.id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (name) => selected?.indexOf(name) !== -1;
  const navigate = useNavigate();

  function handleDeleteFundAction(fund) {
    setDataToDelete(fund)
    setisModalOpen(true)
  }

  async function deleteFundAction(fund) {
    setIsLoading(true)
    await deleteFund(databaseAddress, fund?.fund_name).then(data => {
      getFundsList()
      setIsLoading(false)
      setWarningMessage(["success", "Fund deleted successfully."])
    }).catch(error => {
      setIsLoading(false)
      setWarningMessage(["error", error.message])
    })
  }

  function handleEditFund(fundName) {
    navigate(`edit-fund/${fundName}`)
  }

  function handleFundDetailsPage(fundName) {
    navigate(`fund-profile/${fundName}`)
  }

  function handleReloadPage() {
    getFundsList()
  }

  const handleConfirmAction = () => {
    setTimeout(() => {
      setisModalOpen(false)
    }, 100)
    deleteFundAction(dataToDelete)
  }

  const handleInputChange = (e) => {
    if (e === dataToDelete?.fund_name) {
      setConfirmedInput(true)
    } else {
      setConfirmedInput(false)
    }
  }

  return (
    <>
      <div className="manage-funds-div">
        <>
          <CardProperties theme={theme}>
            <FlexBlockDiv className="right-alignment">
              <p className="font-style-montserrat-family-semibold small-text color-white">Manage Funds</p>
              <NoBorderIconButton theme={theme} onClick={handleReloadPage} icon={<AiOutlineReload className="icon medium-text color-white" />} />
              <NormalSearchBar theme={theme} placeholder="Search fund..." onChange={handleFundSearchChange} value={fundSearch} />
              <BorderIconTextButton theme={theme} text="New fund" icon={<HiPlus className="icon small-text"></HiPlus>} onClick={() => navigate('create-fund')} />
            </FlexBlockDiv>
          </CardProperties>
          <div className="table-div extra-small-text">
            <Paper sx={{ width: '100%', background: 'transparent', boxShadow: "none !important" }}>
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader>
                  <EnhancedTableHead headCells={headCells}
                    numSelected={selected?.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={filteredFunds?.length} />
                  <TableBody>
                    {isLoading ?
                      <TableRow>
                        <TableCell className="table-row-cell" colSpan={7} >
                          <div className="local-loader-div">
                            <LocalLoader size="textarea-big-input"></LocalLoader>
                          </div>
                        </TableCell>
                      </TableRow>
                      :
                      <>
                        {ManagePortfolioData?.length === 0 ?
                          <TableRow>
                            <TableCell className="table-row-cell" colSpan={7} >
                              <div className="no-funds-found-div font-style-montserrat-family-regular small-text color-white">
                                <p>There are no created funds.</p>
                                <NoBorderTextUnderlineButton theme={theme} className="margin-left-1 small-text" text="Create fund" onClick={() => navigate('create-fund')} />
                              </div>
                            </TableCell>
                          </TableRow>
                          :
                          filteredFunds?.length === 0 ?
                            <TableRow>
                              <TableCell className="table-row-cell" colSpan={7} >
                                <div className="no-funds-found-div font-style-montserrat-family-regular small-text color-white">
                                  <p>There are no funds matching the search.</p>
                                </div>
                              </TableCell>
                            </TableRow>
                            :
                            <>{stableSort(filteredFunds, getComparator(order, orderBy))
                              ?.map((row, index) => {
                                const isItemSelected = isSelected(row?.name);
                                return (
                                  <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={index}
                                    selected={isItemSelected}>
                                    <TableCell className="table-row-cell" align="left"><div className={`status-div ${row?.status} ${row?.status}-div`}><div className={`${row?.status} icon-div`}>{state_icon[row?.status]}</div><p className={`font-style-montserrat-family-regular extra-small-text ${row?.status}`}>{translate_state[row?.status]}</p></div></TableCell>
                                    <TableCell className="table-row-cell limit-width font-style-montserrat-family-regular extra-small-text color-white" align="left">
                                      <NoBorderTextUnderlineButton theme={theme} text={row?.fund_name} onClick={() => handleFundDetailsPage(row?.fund_name)} />
                                    </TableCell>
                                    <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{row?.nr_assets}</p></TableCell>
                                    <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{row?.amount ? "€ " + convertToBigNumbers(row?.amount) : "-"}</p></TableCell>
                                    <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{roundStringToTwoDecimalPlacesOrMinorLabel(row?.portfolio_weight_percentage)}%</p></TableCell>
                                    <TableCell className="table-row-cell">
                                      <FlexBlockDiv className="right-alignment">
                                        <NoBorderIconButton theme={theme} icon={<BiTrash className="icon-red small-text color-white" />} onClick={() => handleDeleteFundAction(row)} />
                                        <NoBorderIconButton theme={theme} icon={<TfiPencil className="icon-orange small-text color-white" />} onClick={() => handleEditFund(row?.fund_name)} />
                                      </FlexBlockDiv>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                        }
                      </>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </>
      </div>
      <ModalWindow theme={theme} title={`Do you want to delete the fund '${dataToDelete.fund_name}'?`} classType="confirmation-input-action" open={isModalOpen} setOpen={setisModalOpen}>
        <div className="margin-top-3 input-confirmation-field-div font-style-montserrat-family-regular extra-small-text color-white">
          <p>Write the fund name to confirm.</p>
          <NormalInput theme={theme} className="margin-top-1 margin-bottom-1" onChange={(e) => handleInputChange(e?.target?.value)} autoFocus={true} required={true} type="text" onKeyDown={(e) => handleKeyDownEvent(handleConfirmAction, e)} />
        </div>
        <FlexBlockDiv className="right-alignment margin-top-2">
          <NoBorderTextButton theme={theme} text="Cancel" onClick={() => closeModal(isModalOpen, setisModalOpen)} />
          <BorderTextButton theme={theme} className={!confirmedInput && "button-disabled"} text="Delete" onClick={handleConfirmAction} disabled={!confirmedInput} />
        </FlexBlockDiv>
      </ModalWindow>
    </>
  );
}