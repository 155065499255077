import React, { useState } from "react";

import "./ManageFund.scss"

import InfoBox from "../../InfoBox/InfoBox";

const CheckboxField = ({ text, className, children, infoBoolean, infoText }) => {
    const [showBox, setShowBox] = useState(false);
    const [boxPosition, setBoxPosition] = useState({ x: 0, y: 0 });

    return (
        <div className={`${className} checkbox-field-div`}>
            {children}
            <p className="font-style-montserrat-family-regular extra-small-text color-white">{text}</p>
            {infoBoolean && <InfoBox showBox={showBox} setShowBox={setShowBox} boxPosition={boxPosition} setBoxPosition={setBoxPosition} text={infoText} />}
        </div>
    );
}

export default CheckboxField;