import React, { useEffect, useState, useContext, useRef } from "react";
import { Route, Routes, Navigate } from 'react-router-dom';

import { getFunds } from "../../utils/requests/getRequests";
import { GeneralContext, SimulatorContext } from "../../utils/functions_variables/variables";

import ManageSimulations from "../../components/Simulator/ManageSimulations";
import ManageSimulation from "../../components/Simulator/ManageSimulation/ManageSimulation";
import SimulationProfile from "../../components/Simulator/SimulationProfile";

export default function Simulator() {
    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
  const [isLoading, setIsLoading] = useState(false)
  const [simulationsData, setSimulationsData] = useState(null)
    const [simulatorSearch, setSimulatorSearch] = useState("");
    const [filteredSimulations, setFilteredSimulations] = useState(null);
    
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        const getFundsList = async () => {
            setIsLoading(true)
            await getFunds(databaseAddress).then(data => {
                setSimulationsData([
                    {
                        "id": 74,
                        "simulation_name": "Caixa Geral de Depositos fund",
                        "fund_type": null,
                        "eu_sfdr_article": null,
                        "country": null,
                        "status": "ready",
                        "amount": 8618520,
                        "portfolio_weight_percentage": 0.591534,
                        "date": "2022-07-06T00:00:00.000Z",
                        "client_id": 1,
                        "nr_assets": 14
                    },
                    {
                        "id": 77,
                        "simulation_name": "Merge fund 1 & 2",
                        "fund_type": null,
                        "eu_sfdr_article": null,
                        "country": null,
                        "status": "ready",
                        "amount": 1671460,
                        "portfolio_weight_percentage": 0.114721,
                        "date": "2021-10-20T00:00:00.000Z",
                        "client_id": 1,
                        "nr_assets": 10
                    },
                    {
                        "id": 82,
                        "simulation_name": "FundViz simulation I",
                        "fund_type": null,
                        "eu_sfdr_article": null,
                        "country": null,
                        "status": "ready",
                        "amount": 4279790,
                        "portfolio_weight_percentage": 0.293745,
                        "date": "2021-01-01T00:00:00.000Z",
                        "client_id": 1,
                        "nr_assets": 45
                    }
                ])
                setFilteredSimulations([
                    {
                        "id": 74,
                        "simulation_name": "Caixa Geral de Depositos fund",
                        "fund_type": null,
                        "eu_sfdr_article": null,
                        "country": null,
                        "status": "ready",
                        "amount": 8618520,
                        "portfolio_weight_percentage": 0.591534,
                        "date": "2022-07-06T00:00:00.000Z",
                        "client_id": 1,
                        "nr_assets": 14
                    },
                    {
                        "id": 77,
                        "simulation_name": "Merge fund 1 & 2",
                        "fund_type": null,
                        "eu_sfdr_article": null,
                        "country": null,
                        "status": "ready",
                        "amount": 1671460,
                        "portfolio_weight_percentage": 0.114721,
                        "date": "2021-10-20T00:00:00.000Z",
                        "client_id": 1,
                        "nr_assets": 10
                    },
                    {
                        "id": 82,
                        "simulation_name": "FundViz simulation I",
                        "fund_type": null,
                        "eu_sfdr_article": null,
                        "country": null,
                        "status": "ready",
                        "amount": 4279790,
                        "portfolio_weight_percentage": 0.293745,
                        "date": "2021-01-01T00:00:00.000Z",
                        "client_id": 1,
                        "nr_assets": 45
                    }
                ])
                // setFundsList(data)
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }

        if (dataFetchedRef?.current) return;
    dataFetchedRef.current = true;
        getFundsList()
    }, [])

    useEffect(() => {
        if (simulationsData) {
            const filteredData = simulationsData?.filter(({ simulation_name }) => simulation_name?.toLowerCase().includes(simulatorSearch?.toLowerCase()));
            setFilteredSimulations(filteredData)
        }
    }, [simulatorSearch]);

    const handleSimulationSearchChange = event => {
        setSimulatorSearch(event?.target?.value);
    };

    return (
        <div className="content-main-div">
            <title>Lynxai | Simulator</title>
            <SimulatorContext.Provider value={{ isLoading, setIsLoading, simulationsData, filteredSimulations, simulatorSearch }}>
                <Routes>
                    <Route path="/*" element={<Navigate replace to="/simulator" />} />
                    <Route exact path='/' element={<ManageSimulations handleSimulationSearchChange={handleSimulationSearchChange} />} />
                    <Route exact path='/create-simulation' element={<ManageSimulation />} />
                    <Route exact path='/simulation-profile/:simulation' element={<SimulationProfile />} />
                </Routes>
            </SimulatorContext.Provider>
        </div>
    );
}