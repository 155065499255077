import { dictToURL } from './helpFunctions';
import funds_endpoints from "../endpoints/portfolio_funds_endpoints.json"

export async function deleteFund(databaseAddress, fundName) {
    const query_params = { clientName: databaseAddress, fundName: fundName };
    const url = funds_endpoints[process.env.REACT_APP_ENVIRONMENT]?.delete_fund + "?" + dictToURL(query_params)

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.REACT_APP_API_KEY,
      'Accept': 'application/json',
    },
  })

  if (response?.ok) {
    return response.json()
  } else {
    throw new Error("An error occurred when trying to delete the fund. Please try again later or, if the problem persists, contact our support team.");
  }
}