import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getComparator, stableSort, EnhancedTableHead } from "../../../../../utils/functions_variables/table_functions/TableCompFunctions";
import { convertToCurrency, roundStringToTwoDecimalPlaces } from "../../../../../utils/Maths/MathsFunctions"
import assets_and_investments_types from "../../assets_and_investments_types.json"

import "../FundProfile.scss"

import { CardProperties, NoBorderTextUnderlineButton, NormalSearchBar } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../../assets/styles/darkLightMode/themeContext";
import { FlexBlockDiv } from "../../../../../assets/styles/global";

const FundProfileAssets = ({ fundAssets }) => {
  const { theme } = useContext(ThemeContext);
  const asset_types = assets_and_investments_types.map(item => ({ "label": item.label, "value": item.value }))
  const [filteredFundAssets, setFilteredFundAssets] = useState(fundAssets);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState({ id: 'label', numeric: false, label: "Asset" });
  const [selected, setSelected] = useState([]);
  const [assetSearch, setAssetSearch] = useState("");

  useEffect(() => {
    if (fundAssets) {
      const filteredFunds = fundAssets?.filter(({ label }) => label?.toLowerCase().includes(assetSearch?.toLowerCase()));
      setFilteredFundAssets(filteredFunds)
    }
  }, [assetSearch]);

  const handleAssetSearchChange = event => {
    setAssetSearch(event?.target?.value);
  };

  const navigate = useNavigate();

  const headCells = [
    {
      id: 'label',
      numeric: false,
      label: 'Asset',
    },
    {
      id: 'ticker', // check if this match with the field coming from back-end
      numeric: false,
      label: 'Ticker',
    },
    {
      id: 'stock', // check if this match with the field coming from back-end
      numeric: true,
      label: 'Stock value',
    },
    {
      id: 'type',
      numeric: false,
      label: 'Asset type',
    },
    {
      id: 'share',
      numeric: true,
      label: 'Shares',
    },
    {
      id: 'amount',
      numeric: true,
      label: 'Amount',
    },
  ];
  const isSelected = (name) => selected?.indexOf(name) !== -1;

  const handleRequestSort = (event, property) => {  // DO NOT REMOVE THE EVENT ARGUMENT. STOPS WORKING.
    const isAsc = orderBy.id === property.id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleAssetProfilePage(id) {
    navigate(`../asset-profile/${id}`)
  }

  return (
    <div className="fund-profile-content">
      <CardProperties theme={theme} className="full-width">
        <FlexBlockDiv className="right-alignment">
          <p className="font-style-montserrat-family-semibold small-text color-white">List of assets</p>
          <NormalSearchBar theme={theme} placeholder="Search asset..." onChange={handleAssetSearchChange} value={assetSearch} />
        </FlexBlockDiv>
      </CardProperties>
      <div className="table-div extra-small-text">
        <Paper sx={{ width: '100%', background: 'transparent', boxShadow: "none !important" }}>
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <EnhancedTableHead headCells={headCells}
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={filteredFundAssets?.length} />
              <TableBody className="table-body">
                {filteredFundAssets?.length === 0 ?
                  <TableRow>
                    <TableCell className="table-row-cell" colSpan={7} >
                      <div className="no-assets-found-div font-style-montserrat-family-regular small-text color-white">
                        <p>There are no assets matching the search.</p>
                      </div>
                    </TableCell>
                  </TableRow>
                  :
                  stableSort(filteredFundAssets, getComparator(order, orderBy))
                    ?.map((row, index) => {
                      const isItemSelected = isSelected(row?.name);
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}>
                          <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left">
                            <NoBorderTextUnderlineButton theme={theme} text={row?.label} onClick={() => handleAssetProfilePage(row?.value)} />
                          </TableCell>
                          <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{row?.ticker === '' ? '-' : row?.ticker}</p></TableCell>
                          <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">-</p></TableCell>
                          <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{row?.asset_type ? asset_types?.find((item) => item.value === row?.asset_type)?.label : "-"}</p></TableCell>
                          <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{row?.share}</p></TableCell>
                          <TableCell className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white" align="left"><p className="font-style-montserrat-family-regular extra-small-text color-white">{row?.amount ? "€ " + convertToCurrency(roundStringToTwoDecimalPlaces(row?.amount)) : "-"}</p></TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}

export default FundProfileAssets;