import React, { useRef, useContext, useState } from "react";
import AnimateHeight from 'react-animate-height';

import "./OptionalMetricsSelectionTable.scss"

import { BiRightArrow } from "react-icons/bi";

import OptionalMetricsSelectionCheckboxTable from "./OptionalMetricsSelectionCheckboxTable/OptionalMetricsSelectionCheckboxTable";
import { BorderIconTextFullWidthButton } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../../../assets/styles/darkLightMode/themeContext";

const OptionalMetricsSelectionTable = ({ title, indicator, additional, table, data, checkedItems, setCheckedItems }) => {
  const { theme } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false)
    const [height, setHeight] = useState(0);

    return (
        <div className="optional-metrics-selection-div">
            <BorderIconTextFullWidthButton theme={theme} text={title} className="margin-bottom-1" icon={<BiRightArrow className={`extra-extra-small-text icon ${isOpen ? "close-90-deg" : ""}`} />} onClick={function () { setIsOpen(!isOpen); setHeight(height === 0 ? 'auto' : 0) }} />
            <AnimateHeight id="example-panel" duration={500} height={height}>
                <table>
                    <thead className="font-style-montserrat-family-semibold extra-small-text color-white">
                        <tr>
                            <th className="col-big" colSpan="3">Adverse Sustainability Indicator</th>
                            <th className="col-medium">Metric</th>
                        </tr>
                    </thead>
                    <OptionalMetricsSelectionCheckboxTable checkedItems={checkedItems} setCheckedItems={setCheckedItems} indicator={indicator} table={table} additional={additional} data={data} />
                </table>
            </AnimateHeight>
        </div>
    );
}

export default OptionalMetricsSelectionTable;