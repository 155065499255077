

import React, { useContext } from "react";
import { useLocation } from 'react-router-dom';
import { GeneralContext } from "../../utils/functions_variables/variables";

import Authentication from "../Authentication/Authentication";
import Unauthorized from "./Unauthorized";

const PrivateRoute = ({ allowedRoles, children }) => {
    const { userRoles, isAuthenticated } = useContext(GeneralContext)
    const location = useLocation();

    return (isAuthenticated ? (userRoles && allowedRoles.find((role) => userRoles.includes(role)) ? children : <Unauthorized />
    )
        : <Authentication state={{ from: location }} previousLocation={location} />
    )
};

export default PrivateRoute;