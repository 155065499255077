import React, { useState, useContext } from "react";

import "../ManageSimulation.scss"

import { MainTitleTransparentCard } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../assets/styles/darkLightMode/themeContext";

const SimulationDetails = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      <MainTitleTransparentCard theme={theme} text="Details" />
    </>
  );
}

export default SimulationDetails;