
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'

import Profile from "./Profile";
import FAQ from "./FAQ/FAQ";
import TermsAndConditions from "./TermsAndConditions";
import Support from "./Support";
import ConfigurationsMenu from "../../components/ConfigurationsMenu/ConfigurationsMenu";

export default function Configurations() {
  let location = useLocation();
  const [tab, setTab] = useState(location?.state ? location?.state?.from || 1 : 1)

  useEffect(() => {
    const checkLocationIndex = () => {
      if (location?.state) {
        setTab(location?.state?.from)
      } else {
        setTab(1)
      }
    }
    checkLocationIndex()
  }, [location?.state]);

  const ConfigurationsContent = () => {
    switch (tab) {
      case 1:
        return <Profile />
      case 2:
        return <FAQ />
      case 3:
        return <Support />
      case 4:
        return <TermsAndConditions />
      default:
        return <Profile />
    }
  }

  return (
    <div className="content-main-div">
      <title>Lynxai | Configurations</title>
      <div className="configurations-page-div">
        <ConfigurationsMenu tab={tab} setTab={setTab}></ConfigurationsMenu>
        <div className="full-width">
          <ConfigurationsContent />
        </div>
      </div>
    </div>
  );
}
