import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { Auth } from "aws-amplify";
import PasswordChecklist from "react-password-checklist"

import { RxCross2 } from "react-icons/rx";
import { HiCheck } from "react-icons/hi";
import { ImArrowLeft2 } from "react-icons/im";

import "../../views/Authentication/Authentication.scss"

import { GeneralContext } from "../../utils/functions_variables/variables";
import MainLoader from "../MainLoader/MainLoader";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";
import { BorderTextFullWidthButton, NoBorderIconButton, CardProperties, NormalInput, PasswordInput } from "lynxai-ui-kit";
import { handleKeyDownEvent } from "../../utils/functions_variables/functions";

const icons = { ValidIcon: <HiCheck className="icon-check"></HiCheck>, InvalidIcon: <RxCross2 className="icon-cross"></RxCross2> }

export default function ResetPassword({ setResetPassword }) {
    const { setWarningMessage } = useContext(GeneralContext)
    const { theme } = useContext(ThemeContext)
    const lynxai_full_logo = (theme === 'light' ? require('../../assets/images/lynxai_full_logo_black_blue.png') : require('../../assets/images/lynxai_full_logo_white_blue.png'));
    const [isLoading, setIsLoading] = useState(false)
    const [passswordIsValid, setPasswordIsValid] = useState("");
    const [email, setEmail] = useState("");
    const [buttonSendCodeEnable, setButtonSendCodeEnable] = useState(false)
    const [buttonConfirmNewPasswordsEnable, setButtonConfirmNewPasswordsEnable] = useState(false)
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [step, setStep] = useState(1);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        function checkFieldEmail() {
            if (validator.isEmail(email)) {
                setButtonSendCodeEnable(true)
            } else { setButtonSendCodeEnable(false) }
        }
        checkFieldEmail()
    }, [email]);

    useEffect(() => {
        function checkNewPasswordsCode() {
            if (code?.length === 6 && passswordIsValid) {
                setButtonConfirmNewPasswordsEnable(true)
            } else { setButtonConfirmNewPasswordsEnable(false) }
        }
        checkNewPasswordsCode()
    }, [code, passswordIsValid]);

    const handleGoBack = async (e) => {
        setResetPassword(false)
    }

    const toggleNewPasswordVisibility = (event) => {
        event.preventDefault();
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmNewPasswordVisibility = (event) => {
        event.preventDefault();
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    const handleSendCode = async (e) => {
        e.preventDefault();
        if (buttonSendCodeEnable) {
            setIsLoading(true)
            await Auth?.forgotPassword(email).then(data => {
                setStep(2);
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            });
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (buttonConfirmNewPasswordsEnable) {
            setIsLoading(true)
            await Auth?.forgotPasswordSubmit(email, code, newPassword).then((user) => {
                setIsLoading(false)
                navigate('/login', { state: { info: "Password changed successfully." } })
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    };

    if (isLoading) {
        return <MainLoader></MainLoader>;
    }

    return (
        <CardProperties theme={theme} className="authentication-main-div">
            <NoBorderIconButton theme={theme} icon={<ImArrowLeft2 className="icon medium-text color-white" />} className="back-button" onClick={handleGoBack} />
            <img src={lynxai_full_logo} alt="img" />
            <div className="divider margin-top-3 margin-bottom-1"></div>
            {step === 1 && (
                <>
                    <h1 className="margin-top-3 margin-bottom-2 font-style-montserrat-family-semibold small-text color-lightgray">Reset password</h1>
                    <form onSubmit={handleSendCode}>
                        <NormalInput theme={theme} className="margin-bottom-1" placeholder="Email" value={email} onChange={(e) => setEmail(e?.target?.value)} autoFocus={true} required={true} type="email" onKeyDown={(e) => handleKeyDownEvent(handleSendCode, e)} />
                        <BorderTextFullWidthButton theme={theme} text="Reset password" onClick={handleSendCode} className={`${buttonSendCodeEnable ? "button-hover" : "button-disabled"} margin-top-1`} type="submit" disabled={!buttonSendCodeEnable} />
                    </form>
                </>
            )}
            {step === 2 && (
                <>
                    <h1 className="margin-top-3 margin-bottom-1 font-style-montserrat-family-semibold small-text color-lightgray">Enter new password</h1>
                    <h2 className="margin-top-1 margin-bottom-1 font-style-montserrat-family-regular extra-small-text color-green">A confirmation code was sent to your email.</h2>
                    <form onSubmit={handleResetPassword}>
                        <NormalInput theme={theme} className="margin-top-1" placeholder="Code" value={code} onChange={(e) => setCode(e?.target?.value)} autoFocus={true} required={true} type="number" onKeyDown={(e) => handleKeyDownEvent(handleResetPassword, e)} />
                        <div className="divider margin-top-1 margin-bottom-1"></div>
                        <PasswordInput theme={theme} className="margin-bottom-1" placeholder="New password" required={true} autoComplete="new-password" value={newPassword} showPassword={showNewPassword} onChange={(e) => setNewPassword(e?.target?.value)} onKeyDown={(e) => handleKeyDownEvent(handleResetPassword, e)} toggleShowPassword={toggleNewPasswordVisibility} />
                        <PasswordInput theme={theme} className="margin-bottom-1" placeholder="Confirm new password" required={true} autoComplete="new-password" value={confirmNewPassword} showPassword={showConfirmNewPassword} onChange={(e) => setConfirmNewPassword(e?.target?.value)} onKeyDown={(e) => handleKeyDownEvent(handleResetPassword, e)} toggleShowPassword={toggleConfirmNewPasswordVisibility} />
                        <PasswordChecklist className="extra-small-text font-style-montserrat-family-regular color-white"
                            rules={["capital", "lowercase", "minLength", "number", "specialChar", "match"]}
                            minLength={8}
                            fontSize={10}
                            value={newPassword}
                            valueAgain={confirmNewPassword}
                            iconComponents={icons} onChange={(isValid) => setPasswordIsValid(isValid)} />
                        <BorderTextFullWidthButton theme={theme} text="Change password" onClick={handleResetPassword} className={`${buttonConfirmNewPasswordsEnable ? "button-hover" : "button-disabled"} margin-top-2`} type="submit" disabled={!buttonConfirmNewPasswordsEnable} />
                    </form>
                </>
            )}
        </CardProperties>
    );
}