import React, { useContext } from "react";

import "./Configurations.scss"

import { MainTitleTransparentCard } from "lynxai-ui-kit";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

const TermsAndConditions = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <div className="configurations-main-div">
      <MainTitleTransparentCard theme={theme} text="Terms & Conditions" />
    </div>
  );
}

export default TermsAndConditions;