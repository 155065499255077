import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import "./AssetProfile.scss"

import { GeneralContext, AssetProfileContext } from '../../utils/functions_variables/variables';
import MainLoader from "../MainLoader/MainLoader"
import { getAssetCharacteristics, getAssetEnvironmental } from '../../utils/requests/getRequests';

import AssetProfileTopBar from './AssetProfileTopBar';
import AssetProfileCharacteristics from './tabs/AssetProfileCharacteristics';
import AssetProfileEnvironmental from './tabs/AssetProfileEnvironmental';

const AssetProfile = () => {
    const { id } = useParams()
    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const [isLoading, setIsLoading] = useState(false)
    const [tab, setTab] = useState(1)
    const [assetCharacteristicsData, setAssetCharacteristicsData] = useState(null);
    const [assetEnvironmentalData, setAssetEnvironmentalData] = useState(null);
    const [assetName, setAssetName] = useState(null)
    const [assetTicker, setAssetTicker] = useState(null)

    const navigate = useNavigate();

    useEffect(() => {
        assetCharacteristics()
        assetEnvironmental()
    }, [id]);

    const assetCharacteristics = async () => {
        setIsLoading(true)
        await getAssetCharacteristics(databaseAddress, id).then(data => {
            setAssetName(data?.name)
            setAssetTicker(data?.ticker)
            setAssetCharacteristicsData(data)
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
            setWarningMessage(["error", error.message])
        })
    }

    const assetEnvironmental = async () => {
        await getAssetEnvironmental(databaseAddress, id).then(data => {
            setAssetEnvironmentalData(data)
            setIsLoading(false)
        }).catch(error => {
            setWarningMessage(["error", error.message])
            setIsLoading(false)
        })
    }

    const goBack = () => {
        navigate(-1);
    }

    const selectTabNumber = (tab) => {
        setTab(tab)
    }

    function handleReloadPage() {
        assetCharacteristics()
    }

    const AssetProfileContent = () => {
        switch (tab) {
            case 1:
                return <AssetProfileEnvironmental />
            case 2:
                return <AssetProfileCharacteristics />
            default:
                return <AssetProfileEnvironmental />
        }
    }

    if (isLoading) {
        return <MainLoader></MainLoader>;
    }

    return (
        <AssetProfileContext.Provider value={{ id, databaseAddress, setWarningMessage, isLoading, setIsLoading, tab, setTab, assetName, setAssetName, assetTicker, setAssetTicker, assetCharacteristicsData, setAssetCharacteristicsData, assetEnvironmentalData, setAssetEnvironmentalData, goBack, selectTabNumber, handleReloadPage }}>
                <div className="asset-profile-main-div">
                    <AssetProfileTopBar />
                    <AssetProfileContent />
                </div>
        </AssetProfileContext.Provider>
    );
}

export default AssetProfile;