import React from "react";

import translatedJson from "../../../../../views/Regulations/SFDR/PAIjsonTranslation.json"
import { convertToBigNumbers, roundStringToTwoDecimalPlaces, roundPercentage } from "../../../../../utils/Maths/MathsFunctions";

import InfoBoxTable from "../../../../InfoBoxTable/InfoBoxTable"
import TableInput from "./TableInput";

const TableOrganizer = ({ selectedFund, dataDynamic, dataStatic, indicator, table, additional }) => {

    let colName_prefix = ""
    if (additional) { colName_prefix = indicator + "_" + table + "_" + additional } else { colName_prefix = indicator + "_" + table }

    const tbodies = Object.entries(dataStatic)?.map(([mainKey, mainValue]) => {
        const sizeMainIndicator = Object.values(mainValue)?.reduce((acc, arr) => acc + Object.keys(arr)?.length, 0);
        const fixedDataValues = Object.entries(mainValue)
        let i = 0;
        const subtopicRows = fixedDataValues?.map((indicator, index) => {
            const sizeInsideMetrics = Object.values(indicator[1])?.length;
            let m = 0;
            const rows = Object.entries(indicator[1])?.map(([keyOne, valueOne]) => {
                const subtopicName = (m === 0 && i === 0) ? <td width="7%" rowSpan={sizeMainIndicator}>{translatedJson[mainKey]}</td> : null
                const metricName = m === 0 ? <td width="13%" rowSpan={sizeInsideMetrics}>{translatedJson[indicator[0]]}</td> : null
                m = m + 1
                return (<tr key={m + i}>
                    {subtopicName}
                    {metricName}
                    <td>{translatedJson[keyOne]}</td>
                    <td><div className="value-div">{valueOne?.impactYearN !== null && valueOne?.quantityN !== null && <InfoBoxTable text={`${valueOne?.quantityN} assets were considered`} />}{valueOne?.impactYearN === null || valueOne?.quantityN === null ? "[NO DATA]" : valueOne?.impactYearN?.endsWith('%') ? roundPercentage(valueOne?.impactYearN) : roundStringToTwoDecimalPlaces(valueOne?.impactYearN) > 999 ? convertToBigNumbers(roundStringToTwoDecimalPlaces(valueOne?.impactYearN))?.replace(",", ".") : roundStringToTwoDecimalPlaces(valueOne?.impactYearN)}</div></td>
                    <td><div className="value-div">{valueOne?.impactYearNMinus1 !== null && valueOne?.quantityNM1 !== null && <InfoBoxTable text={`${valueOne?.quantityNM1} assets were considered`} />}{valueOne?.impactYearNMinus1 === null || valueOne?.quantityNM1 === null ? "[NO DATA]" : valueOne?.impactYearNMinus1?.endsWith('%') ? roundPercentage(valueOne?.impactYearNMinus1) : roundStringToTwoDecimalPlaces(valueOne?.impactYearNMinus1) > 999 ? convertToBigNumbers(roundStringToTwoDecimalPlaces(valueOne?.impactYearNMinus1)).replace(",", ".") : roundStringToTwoDecimalPlaces(valueOne?.impactYearNMinus1)}</div></td>
                    <td className="td-input"><TableInput selectedFund={selectedFund} colName={colName_prefix + "_" + mainKey + "_" + indicator[0] + "_" + keyOne + "_explanation"} name="explanation" text={dataDynamic && dataDynamic[mainKey] && dataDynamic[mainKey][indicator[0]] && dataDynamic[mainKey][indicator[0]][keyOne] && dataDynamic[mainKey][indicator[0]][keyOne]?.explanation ? dataDynamic[mainKey][indicator[0]][keyOne]?.explanation : ""} /></td>
                    <td className="td-input"><TableInput selectedFund={selectedFund} colName={colName_prefix + "_" + mainKey + "_" + indicator[0] + "_" + keyOne + "_actions"} name="actions_planned_taken" text={dataDynamic && dataDynamic[mainKey] && dataDynamic[mainKey][indicator[0]] && dataDynamic[mainKey][indicator[0]][keyOne] && dataDynamic[mainKey][indicator[0]][keyOne]?.actions ? dataDynamic[mainKey][indicator[0]][keyOne]?.actions : ""} /></td>
                </tr>)
            })
            i = i + 1
            return rows
        })
        return <tbody key={mainKey}>
            {subtopicRows}
        </tbody>
    })
    return tbodies
}

export default TableOrganizer;