import React from "react";

import "./PrivateRoute.scss"

export default function Unauthorized() {

    return (
        <div className="unauthorized-div">
            <p className="font-style-montserrat-family-semibold extra-small-text color-white">YOU DON'T HAVE ACCESS TO THIS PAGE.</p>
            <p className="font-style-montserrat-family-regular extra-small-text color-white">A subscription upgrade is required. If you should not be seeing this message, please contact support.</p>
        </div>
    );
}
