
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import { GeneralContext } from "../../utils/functions_variables/variables";
import { handleThemeChange } from "../../utils/functions_variables/functions";
import LogIn from "../../components/AuthenticationPages/LogIn";
import ChangePasswordRequired from "../../components/AuthenticationPages/ChangePasswordRequired";
import ResetPassword from "../../components/AuthenticationPages/ResetPassword";
import Help from "../../components/AuthenticationPages/Help";
import { BorderIconTextButton, NoBorderIconButton } from "lynxai-ui-kit";
import { MdOutlineLightMode } from "react-icons/md";
import { BsChatLeftDots, BsMoon } from "react-icons/bs";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

export default function Authentication({ previousLocation }) {
  const { theme, setTheme } = useContext(ThemeContext);
  const { isAuthenticated } = useContext(GeneralContext)
  const [resetPassword, setResetPassword] = useState(false)
  const [newPasswordRequired, setNewPasswordRequired] = useState(false)
  const [helpRequired, setHelpRequired] = useState(false)
  const [newPasswordRequiredUser, setNewPasswordRequiredUser] = useState(null)
  const from = previousLocation?.pathname || "/";

  const navigate = useNavigate();

  useEffect(() => {
    function enter() {
      if (isAuthenticated) {
        navigate(from);
      }
    }
    enter()
  });

  return (
    <div className="authentication-wrapper">
      {!helpRequired ?
        !newPasswordRequired ?
          resetPassword ? <ResetPassword setResetPassword={setResetPassword} ></ResetPassword> : <LogIn setNewPasswordRequired={setNewPasswordRequired} setNewPasswordRequiredUser={setNewPasswordRequiredUser} setResetPassword={setResetPassword} previousLocation={previousLocation} /> :
          <ChangePasswordRequired setNewPasswordRequired={setNewPasswordRequired} setNewPasswordRequiredUser={setNewPasswordRequiredUser} newPasswordRequiredUser={newPasswordRequiredUser} />
        : <Help setHelpRequired={setHelpRequired} />
      }
      <div className="bottom-right-div">
        {!helpRequired && <BorderIconTextButton theme={theme} text="Help" className="help-button" icon={<BsChatLeftDots className="icon small-text" />} onClick={() => setHelpRequired(true)} />}
        <NoBorderIconButton theme={theme} icon={theme === 'dark' ? <MdOutlineLightMode className="icon extra-small-text color-white" /> : <BsMoon className="icon extra-small-text color-white" />} onClick={() => handleThemeChange(theme, setTheme)} />
      </div>
    </div>
  );
}
