import React, { useContext } from "react";

import "./AssetsHistoryManagement.scss"
import { GeneralContext, ManageFundContext } from "../../../../utils/functions_variables/variables";

import { BsCalendarEvent } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";

const AssetsHistoryManagementDateButton = ({ date }) => {
    const { setWarningMessage } = useContext(GeneralContext)
    const { purpose, dates, setDates, selectedDate, setSelectedDate, fundAssets, setFundAssets, createdAssets, setCreatedAssets } = useContext(ManageFundContext)

    function removeDate(date) {
        let newDates = dates?.filter(element => element !== date)
        if (newDates?.length === 0) {
            setWarningMessage(["warning", "At least one date must be selected. Today's date has been added."])
            newDates = [new Date().toLocaleDateString('en-GB')]
        }
        if (date in fundAssets) {
            const updatedDict = { ...fundAssets };
            delete updatedDict[date];
            setFundAssets(updatedDict);
        }
        if (date in createdAssets) {
            const updatedDict = { ...createdAssets };
            delete updatedDict[date];
            setCreatedAssets(updatedDict);
        }
        setDates(newDates)
        if (selectedDate === date) {
            setSelectedDate(newDates[newDates?.length - 1])
        }
    }

    return (
        <div className={`asset-history-management-date-div  ${selectedDate !== date && "opacity"}`}>
            <button className="asset-history-management-date-button" onClick={() => setSelectedDate(date)}>
                <BsCalendarEvent className="calendar-icon extra-small-text" />
                <p className="font-style-montserrat-family-regular extra-small-text color-white">{date}</p>
            </button>
            {
                purpose !== "details" &&
                <button className="remove-button" onClick={() => removeDate(date)}>
                    <RxCross2 className="trash-icon small-text" />
                </button>
            }
        </div>
    );
}

export default AssetsHistoryManagementDateButton;