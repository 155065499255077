import React, { useState, useContext, useEffect } from "react";

import "./CreateAsset.scss"
import countries from "./countries_list.json"
import assets_and_investments_types from "./assets_and_investments_types.json"
import { ManageFundContext } from "../../../utils/functions_variables/variables";
import FormField from "./FormField";
import CheckboxField from "./CheckboxField";
import { InputOptionSingleValue, BorderTextFullWidthButton, NormalInput, RadioInput, SearchBarControlReactSelect, NormalDropdown } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

// IT IS NOT ALLOWED TO STORE THE ISIN's FROM THIS COUNTRY CODES 
// const mandatoryTickerCountries = ['US', 'CA', 'KY', 'BM', 'VI', 'VG', 'UM', 'TT', 'SR', 'GS', 'SX', 'VC', 'MF', 'LC', 'KN', 'BL', 'PR', 'PH', 'PW', 'MP', 'FM', 'YT', 'MH', 'HT', 'GY', 'GU', 'GD', 'DM', 'CW', 'BQ', 'BZ', 'BS', 'AW', 'AG', 'AI', 'AS'];

const CreateAsset = () => {
    const { theme } = useContext(ThemeContext)
    const { assetsList, setAssetsList, setSelectedAsset, selectedDate, createdAssets, setCreatedAssets } = useContext(ManageFundContext)

    const asset_types = assets_and_investments_types.map(item => ({ "label": item.label, "value": item.value }))
    const [sameNameWarning, setSameNameWarning] = useState(null);
    const [formReady, setFormReady] = useState(false);
    const [assetName, setAssetName] = useState("");
    const [providerName, setProviderName] = useState("");
    const [providerNameFilled, setProviderNameFilled] = useState(false);
    const [typeCommodity, setTypeCommodity] = useState("");
    const [typeCommodityFilled, setTypeCommodityFilled] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [quantityFilled, setQuantityFilled] = useState(false);
    const [assetNameFilled, setAssetNameFilled] = useState(false)
    const [publicAsset, setPublicAsset] = useState(true);
    const [selectedAssetTypeOption, setselectedAssetTypeOption] = useState('');
    const [selectedAssetPublicPrivate, setSelectedAssetPublicPrivate] = useState('');
    const [isin, setIsin] = useState("")
    const [isinFilledCorrectly, setIsinFilledCorrectly] = useState(false)
    const [ticker, setTicker] = useState("")
    const [tickerFilled, setTickerFilled] = useState(false)
    const [selectedCountries, setSelectedCountries] = useState([])


    useEffect(() => {
        if (assetNameFilled) {
            if (selectedAssetTypeOption === 'equity') {
                if (tickerFilled && checkIsin()) {
                    setFormReady(true)
                } else {
                    setFormReady(false)
                }
            } else if (selectedAssetTypeOption === 'investment_trusts' || selectedAssetTypeOption === 'bonds_fixed_income' || selectedAssetTypeOption === 'funds' || selectedAssetTypeOption === 'cash' || selectedAssetTypeOption === 'options_and_derivatives') {
                if (selectedAssetPublicPrivate === 'public') {
                    if (tickerFilled && checkIsin()) {
                        setFormReady(true)
                    } else {
                        setFormReady(false)
                    }
                } else if (selectedAssetPublicPrivate === 'private') {
                    if (selectedCountries?.length > 0 && providerNameFilled) {
                        setFormReady(true)
                    } else {
                        setFormReady(false)
                    }
                }
            } else if (selectedAssetTypeOption === 'commodities') {
                if (selectedAssetPublicPrivate === 'public') {
                    if (tickerFilled && checkIsin()) {
                        setFormReady(true)
                    } else {
                        setFormReady(false)
                    }
                } else if (selectedAssetPublicPrivate === 'private') {
                    if (quantityFilled && typeCommodityFilled) {
                        setFormReady(true)
                    } else {
                        setFormReady(false)
                    }
                }
            } else if (selectedAssetTypeOption === 'alternative_investments') {
                if (selectedAssetPublicPrivate === 'public') {
                    if (tickerFilled && checkIsin()) {
                        setFormReady(true)
                    } else {
                        setFormReady(false)
                    }
                } else if (selectedAssetPublicPrivate === 'private') {
                    setFormReady(true)
                }
            }
        }
    }, [selectedAssetTypeOption, assetName, selectedAssetPublicPrivate, isin, ticker, selectedCountries]);


    function handleCreateAsset() {
        if (formReady) {
            const random_id = Math.floor(Math.random() * 1000000000)
            let newAsset = { value: random_id, label: assetName, created: true, assetName: assetName, assetType: selectedAssetTypeOption, isin: isin, ticker: ticker, countries: selectedCountries, currency: "", amount: "", share: "", public: publicAsset }
            let newAssetsList = [...assetsList, newAsset]
            newAssetsList = newAssetsList?.sort((a, b) => a?.label?.localeCompare(b?.label))
            setAssetsList(newAssetsList)
            setSelectedAsset(newAsset)
            const newArray = {
                ...createdAssets,
                [selectedDate]: [
                    {
                        value: random_id,
                        keySecondary: random_id,
                        label: assetName,
                        currency: "",
                        amount: "",
                        share: "",
                        investment_type: "",
                        isin: isin,
                        ticker: ticker,
                        public_asset: publicAsset,
                        country: selectedCountries,
                        asset_type: selectedAssetTypeOption
                    },
                    ...(createdAssets[selectedDate] || [])
                ]
            };
            setCreatedAssets(newArray);
        }
    }

    function handleSelectChange(selectedOption) {
        cleanAll()
        setselectedAssetTypeOption(selectedOption?.value);
    };

    function checkIsin() {
        if (isin !== "") {
            if (isinFilledCorrectly) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    function cleanAll() {
        setFormReady(false)
        setSameNameWarning(null)
        setAssetName("")
        setAssetNameFilled(false)
        setProviderName("")
        setProviderNameFilled(false)
        setTypeCommodity("")
        setTypeCommodityFilled(false)
        setQuantity(0)
        setQuantityFilled(false)
        setselectedAssetTypeOption('')
        setSelectedAssetPublicPrivate('')
        setPublicAsset(true)
        setIsin("")
        setTicker("")
        setTickerFilled(false)
        setIsinFilledCorrectly(false)
        setSelectedCountries([])
    }

    function handleAssetName(event) {
        setAssetName(event);
        if (assetsList?.some((dict) => dict?.label?.toLowerCase() === event?.toLowerCase())) {
            setSameNameWarning("Asset name already exists.")
            setAssetNameFilled(false)
        } else {
            setSameNameWarning(null)
            setAssetNameFilled(event !== "" && event?.length >= 3)
        }
    }

    function handleProviderName(event) {
        setProviderName(event);
        setProviderNameFilled(event !== "")
    }

    function handleQuantity(event) {
        setQuantity(event)
        setQuantityFilled(event !== 0)
    }

    function handleCommodityType(event) {
        setTypeCommodity(event);
        setTypeCommodityFilled(event !== "")
    }

    function handleCountry(value) {
        setSelectedCountries([value?.label])
    }

    function handlePublicPrivate(e) {
        setFormReady(false)
        setAssetName("")
        setSameNameWarning(null)
        setAssetNameFilled(false)
        setProviderName("")
        setProviderNameFilled(false)
        setTypeCommodity("")
        setTypeCommodityFilled(false)
        setQuantity(0)
        setQuantityFilled(false)
        setIsin("")
        setTicker("")
        setTickerFilled(false)
        setIsinFilledCorrectly(false)
        setSelectedCountries([])
        setSelectedAssetPublicPrivate(e?.target?.value)
        setPublicAsset(e?.target?.value === 'public')
    }

    function handleISIN(e) {
        const input = e;
        const maxLength = 12;
        const isValid = /^([a-zA-Z]{0,2}|[a-zA-Z]{2}[a-zA-Z0-9]{0,9}|[a-zA-Z]{2}[a-zA-Z0-9]{9}[0-9]{0,1}|[a-zA-Z]{2}[a-zA-Z0-9]{9}[0-9]{1}\d)$/.test(input);
        if (isValid && input?.length <= maxLength) {
            setIsin(input?.toUpperCase());
            if (input?.length === maxLength) { setIsinFilledCorrectly(true) } else { setIsinFilledCorrectly(false) }
        }
    }

    function handleTicker(e) {
        const newValue = e
        setTicker(newValue?.toUpperCase());
        if (newValue?.length > 0) {
            setTickerFilled(true)
        } else {
            setTickerFilled(false)
        }
    }

    return (
        <div className="create-asset-fields">
            <FormField fieldName="Asset type" field={<NormalDropdown theme={theme} noOptionsMessage={() => <p className="extra-small-text font-style-montserrat-family-regular">No results match your search criteria.</p>} options={asset_types} placeholder="Select an option" onChange={handleSelectChange}
                components={{ Option: InputOptionSingleValue, Control: SearchBarControlReactSelect }} borderStatus={`${selectedAssetTypeOption !== '' ? "var(--main-green)" : "red"}`} />} />
            <div className="optional-fields-div">
                {(selectedAssetTypeOption === 'equity') && (
                    <>
                        {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                        <FormField fieldName="Asset name" field={<NormalInput theme={theme} className={`${assetNameFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Asset name" value={assetName} onChange={(e) => handleAssetName(e?.target?.value)} autoFocus={true} required={true} type="text" />} />
                        <FormField fieldName="ISIN" field={<NormalInput theme={theme} className={`${isin !== "" ? (isinFilledCorrectly ? "border-green" : "border-red") : "border-lightgray"} margin-top-1`} placeholder="XX-00000000-0" value={isin} onChange={(e) => handleISIN(e?.target?.value)} type="text" />} />
                        <FormField fieldName="Ticker" field={<NormalInput theme={theme} className={`${tickerFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="XXXX" value={ticker} onChange={(e) => handleTicker(e?.target?.value)} type="text" />} />
                    </>
                )}
                {(selectedAssetTypeOption === 'investment_trusts' || selectedAssetTypeOption === 'bonds_fixed_income' || selectedAssetTypeOption === 'funds' || selectedAssetTypeOption === 'cash' || selectedAssetTypeOption === 'options_and_derivatives') && (<>
                    <FormField fieldName="" field={<div className="checkboxes-div margin-top-2 margin-bottom-1">
                        <CheckboxField text="Public" infoBoolean={false} ><RadioInput name="publicOrPrivate" value="public" onChange={handlePublicPrivate} /></CheckboxField>
                        <CheckboxField text="Private" infoBoolean={false} ><RadioInput name="publicOrPrivate" value="private" onChange={handlePublicPrivate} /></CheckboxField>
                    </div>} />
                    {selectedAssetPublicPrivate === 'public' &&
                        (<>
                            {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                            <FormField fieldName="Asset name" field={<NormalInput theme={theme} className={`${assetNameFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Asset name" value={assetName} onChange={(e) => handleAssetName(e?.target?.value)} autoFocus={true} required={true} type="text" />} />
                            <FormField fieldName="ISIN" field={<NormalInput theme={theme} className={`${isin !== "" ? (isinFilledCorrectly ? "border-green" : "border-red") : "border-lightgray"} margin-top-1`} placeholder="XX-00000000-0" value={isin} onChange={(e) => handleISIN(e?.target?.value)} type="text" />} />
                            <FormField fieldName="Ticker" field={<NormalInput theme={theme} className={`${tickerFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="XXXX" value={ticker} onChange={(e) => handleTicker(e?.target?.value)} type="text" />} />
                        </>)}
                    {selectedAssetPublicPrivate === 'private' &&
                        (<>
                            {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                            <FormField fieldName="Provider name" field={<NormalInput theme={theme} className={`${providerNameFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Provider name" value={providerName} onChange={(e) => handleProviderName(e?.target?.value)} autoFocus={true} required={true} type="text" />} />
                            <FormField fieldName="Asset name" field={<NormalInput theme={theme} className={`${assetNameFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Asset name" value={assetName} onChange={(e) => handleAssetName(e?.target?.value)} autoFocus={true} required={true} type="text" />} />
                            <FormField fieldName="Country" field={<NormalDropdown theme={theme} noOptionsMessage={() => <p className="extra-small-text font-style-montserrat-family-regular">No results match your search criteria.</p>} className="margin-top-1" options={countries} placeholder="Select a country" onChange={handleCountry}
                                components={{ Option: InputOptionSingleValue, Control: SearchBarControlReactSelect }} borderStatus={`${selectedCountries.length === 1 ? "var(--main-green)" : "red"}`} />} />
                        </>)}
                </>
                )}
                {(selectedAssetTypeOption === 'commodities') && (<>
                    <FormField fieldName="" field={<div className="checkboxes-div margin-top-2 margin-bottom-1">
                        <CheckboxField text="Public" infoBoolean={false} ><RadioInput name="publicOrPrivate" value="public" onChange={handlePublicPrivate} /></CheckboxField>
                        <CheckboxField text="Private" infoBoolean={false} ><RadioInput name="publicOrPrivate" value="private" onChange={handlePublicPrivate} /></CheckboxField>
                    </div>} />
                    {selectedAssetPublicPrivate === 'public' &&
                        (<>
                            {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                            <FormField fieldName="Asset name" field={<NormalInput theme={theme} className={`${assetNameFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Asset name" value={assetName} onChange={(e) => handleAssetName(e?.target?.value)} autoFocus={true} required={true} type="text" />} />
                            <FormField fieldName="ISIN" field={<NormalInput theme={theme} className={`${isin !== "" ? (isinFilledCorrectly ? "border-green" : "border-red") : "border-lightgray"} margin-top-1`} placeholder="XX-00000000-0" value={isin} onChange={(e) => handleISIN(e?.target?.value)} type="text" />} />
                            <FormField fieldName="Ticker" field={<NormalInput theme={theme} className={`${tickerFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="XXXX" value={ticker} onChange={(e) => handleTicker(e?.target?.value)} type="text" />} />
                        </>)}
                    {selectedAssetPublicPrivate === 'private' &&
                        (<>
                          {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                            <FormField fieldName="Asset name" field={<NormalInput theme={theme} className={`${assetNameFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Asset name" value={assetName} onChange={(e) => handleAssetName(e?.target?.value)} autoFocus={true} required={true} type="text" />} />
                            <FormField fieldName="Commodity type" field={<NormalInput theme={theme} className={`${typeCommodityFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Type of commodity" value={typeCommodity} onChange={(e) => handleCommodityType(e?.target?.value)} autoFocus={true} required={true} type="text" />} />
                            <FormField fieldName="Quantity (kg)" field={<NormalInput theme={theme} className={`${quantityFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Quantity (kg)" value={quantity} onChange={(e) => handleQuantity(e?.target?.value)} autoFocus={true} required={true} type="number" />} />
                        </>)}
                </>
                )}
                {(selectedAssetTypeOption === 'alternative_investments') && (<>
                    <FormField fieldName="" field={<div className="checkboxes-div margin-top-2 margin-bottom-1">
                        <CheckboxField text="Public" infoBoolean={false} ><RadioInput name="publicOrPrivate" value="public" onChange={handlePublicPrivate} /></CheckboxField>
                        <CheckboxField text="Private" infoBoolean={false} ><RadioInput name="publicOrPrivate" value="private" onChange={handlePublicPrivate} /></CheckboxField>
                    </div>} />
                    {selectedAssetPublicPrivate === 'public' &&
                        (<>
                            {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                            <FormField fieldName="Asset name" field={<NormalInput theme={theme} className={`${assetNameFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Asset name" value={assetName} onChange={(e) => handleAssetName(e?.target?.value)} autoFocus={true} required={true} type="text" />} />
                            <FormField fieldName="ISIN" field={<NormalInput theme={theme} className={`${isin !== "" ? (isinFilledCorrectly ? "border-green" : "border-red") : "border-lightgray"} margin-top-1`} placeholder="XX-00000000-0" value={isin} onChange={(e) => handleISIN(e?.target?.value)} type="text" />} />
                            <FormField fieldName="Ticker" field={<NormalInput theme={theme} className={`${tickerFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="XXXX" value={ticker} onChange={(e) => handleTicker(e?.target?.value)} type="text" />} />
                        </>)}
                    {selectedAssetPublicPrivate === 'private' &&
                        (<>
                            {sameNameWarning && <FormField fieldName="" field={<p className="font-style-montserrat-family-regular extra-small-text color-red">{sameNameWarning}</p>} />}
                            <FormField fieldName="Asset name" field={<NormalInput theme={theme} className={`${assetNameFilled ? "border-green" : "border-red"} margin-top-1`} placeholder="Asset name" value={assetName} onChange={(e) => handleAssetName(e?.target?.value)} autoFocus={true} required={true} type="text" />} />
                        </>)}
                </>
                )}
            </div>
            <BorderTextFullWidthButton theme={theme} text="Create" className={`${formReady ? "button-hover" : "button-disabled"} margin-top-3`} onClick={handleCreateAsset} disabled={!formReady} />
        </div>
    );
}

export default CreateAsset;