
import React, { useState } from "react";

import "../../MultiStep.scss"

import InvesteeCompaniesTable from "../InvesteeCompaniesTable/InvesteeCompaniesTable";
import SovereignsSupranationalsTable from "../SovereignsSupranationalsTable/SovereignsSupranationalsTable";

const AdditionalSocialEmployeeHumanRightsIndicators = ({ selectedFund, dataDynamic, dataStatic }) => {

    const indicator = "additionalSocial";

    return (
        <div className="tables-main-div">
            <InvesteeCompaniesTable selectedFund={selectedFund} indicator={indicator} dataDynamic={dataDynamic?.investeeCompanies} dataStatic={dataStatic?.investeeCompanies} isAdditionalClimate={false} isAdditionalSocial={true}></InvesteeCompaniesTable>
            <SovereignsSupranationalsTable selectedFund={selectedFund} indicator={indicator} dataDynamic={dataDynamic?.sovereignsAndSupranationals} dataStatic={dataStatic?.sovereignsAndSupranationals}></SovereignsSupranationalsTable>
        </div>
    );
}

export default AdditionalSocialEmployeeHumanRightsIndicators;