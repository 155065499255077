import React, { useContext } from "react";

import "./FAQ.scss"

import { NoBorderTextIconFullWidthButton, CardProperties } from "lynxai-ui-kit";
import { FiChevronRight } from "react-icons/fi";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const FAQContentCard = ({ title, topics, setOpen, setModalTitle, setModalContent }) => {
  const { theme } = useContext(ThemeContext);

  const handleOpenModal = (item) => {
    setModalTitle(item?.title)
    setModalContent(item?.description)
    setOpen(true)
  }

  return (
    <CardProperties theme={theme} className="faq-content-card-div">
        <p className="margin-bottom-2 font-style-montserrat-family-semibold extra-small-text color-white">{title}</p>
        {topics?.map((item, index) =>
        <NoBorderTextIconFullWidthButton theme={theme} className="margin-bottom-1" text={item?.title} icon={<FiChevronRight className="icon small-text" />} onClick={() =>handleOpenModal(item)} />
        )}
    </CardProperties>
  );
}

export default FAQContentCard;