import React, { useState, useContext } from "react";

import ChangeProfileDetails from "../../components/ChangeProfileDetails/ChangeProfileDetails";
import ChangePassword from "../../components/ChangePassword/ChangePassword";

import "./Configurations.scss"
import { MainTitleTransparentCard } from "lynxai-ui-kit";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

const Profile = () => {
    const { theme } = useContext(ThemeContext)

    return (
        <div className="configurations-main-div">
            <MainTitleTransparentCard theme={theme} text="Profile preferences" />
            <ChangeProfileDetails />
            <ChangePassword />
        </div>
    );
}

export default Profile;