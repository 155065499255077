import React, { useContext } from "react";
import { convertToInternationalCurrencySystem } from "../../../utils/Maths/MathsFunctions";

import "./BigOneValueCard.scss"

import { CardProperties } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const BigOneValueCard = ({ title, data, cardType }) => {
    const { theme } = useContext(ThemeContext);

    return (
        <CardProperties theme={theme} className={`${cardType} color-white`}>
            <p className="font-style-montserrat-family-regular extra-small-text">{title}</p>
            <div className="big-one-value-card-value-div font-style-montserrat-family-semibold large-text">
                <div className="ring">
                    €{convertToInternationalCurrencySystem(data, 3)}
                </div>
            </div>
        </CardProperties>
    );
}

export default BigOneValueCard;

