import React, { useState, useContext } from "react";

import "./MetricQuestionBox.scss"
import { ImArrowDown2, ImArrowUp2 } from "react-icons/im";
import { CardProperties } from "lynxai-ui-kit";
import { FlexBlockDiv } from "../../assets/styles/global";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

const MetricQuestionBox = ({ text, value, value1, value2 }) => {
    const { theme } = useContext(ThemeContext);

    return (
        <CardProperties theme={theme} className="metric-question-box-div">
            <FlexBlockDiv>
                <p className="font-style-montserrat-family-semibold extra-small-text color-white">{text}</p>
                {value1 && value2 ?
                    <div className="box-two-values">
                        <div className="value">
                            <ImArrowDown2 className="icon small-text color-green" />
                            <label className="font-style-montserrat-family-regular small-text color-white">{value2}</label>
                        </div>
                        <div className="value">
                            <ImArrowUp2 className="icon small-text color-red" />
                            <label className="font-style-montserrat-family-regular small-text color-white">{value1}</label>
                        </div>
                    </div>
                    :
                    <label className={`one-value font-style-montserrat-family-regular small-text ${value > 0 ? "color-red" : "color-green"}`}>{value > 0 && "+"}{value}%</label>
                }
            </FlexBlockDiv>
        </CardProperties>
    );
}

export default MetricQuestionBox;