import React, { useContext } from "react";

import "./ManageFund.scss"

const FormField = ({ fieldName, field }) => {

    return (
        <div className="form-field">
            <label className="font-style-montserrat-family-semibold extra-small-text color-white">{fieldName}</label>
            {field}
        </div>
    );
}

export default FormField;