
import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { darkenBackground } from "../../components/LeftSlideMenu/LeftSlideMenuStyles"
import { RegulationsMenuButton } from "../../components/LeftSlideMenu/LeftSlideMenuStyles"
import LeftSlideMenu from "../../components/LeftSlideMenu/LeftSlideMenu"
import InvestmentFundEligibility from "./EUTaxonomy/InvestmentFundEligibility";
import InvestmentFundAlignment from "./EUTaxonomy/InvestmentFundAlignment";
import NonFinancialCompanyProfile from "./EUTaxonomy/NonFinancialCompanyProfile";
import FinancialCompanyProfile from "./EUTaxonomy/FinancialCompanyProfile";
import InsuranceCompanyProfile from "./EUTaxonomy/InsuranceCompanyProfile";
import PAIIndicators from "./SFDR/PAIIndicators";

export default function Regulations() {
  const [leftMenuOpen, setleftMenuOpen] = useState(false)

  const openMenu = () => {
    setleftMenuOpen(!leftMenuOpen);
    darkenBackground(leftMenuOpen);
  }

  return (
    <>
      <title>Lynxai | Regulations</title>
        <LeftSlideMenu leftMenuOpen={leftMenuOpen} setleftMenuOpen={setleftMenuOpen}></LeftSlideMenu>
        <RegulationsMenuButton className="RegulationsMenuButton font-style-montserrat-family-semibold small-text color-white" open={leftMenuOpen} onClick={() => openMenu()}>Regulations</RegulationsMenuButton>
        <div className="content-main-div">
          <Routes>
            <Route exact path='/' element={<Navigate replace to="eu-taxonomy/investment-fund-eligibility" />} />
            <Route exact path='/eu-taxonomy/investment-fund-eligibility' element={<InvestmentFundEligibility />} />
            <Route exact path='/eu-taxonomy/investment-fund-alignment' element={<InvestmentFundAlignment />} />
            <Route exact path='/eu-taxonomy/non-financial-company-profile' element={<NonFinancialCompanyProfile />} />
            <Route exact path='/eu-taxonomy/financial-company-profile' element={<FinancialCompanyProfile />} />
            <Route exact path='/eu-taxonomy/insurance-company-profile' element={<InsuranceCompanyProfile />} />
            <Route exact path='/sfdr/principal-adverse-impact-indicators' element={<PAIIndicators />} />
          </Routes>
        </div>
    </>
  );
}
