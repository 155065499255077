
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { AiFillExclamationCircle, AiOutlineInfoCircle, AiOutlineSave, AiOutlineCloseCircle } from "react-icons/ai";
import { TbFileDollar, TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { IoCheckmarkCircle, IoGitNetworkOutline, IoBuildOutline } from "react-icons/io5";
import { HiOutlineCubeTransparent } from "react-icons/hi";

import { ManageSimulationContext } from "../../../../utils/functions_variables/variables";

import "../ManageSimulation.scss"
import { BackgroundStraightAnglesFullWidthIconButton, BackgroundStraightAnglesFullWidthIconTextButton, ExpandableLeftMenuTabButton, ExpandableLeftMenuTopButton, NoBorderIconButton, NoBorderTextButton } from "lynxai-ui-kit";
import { FlexBlockDiv } from "../../../../assets/styles/global";
import { ThemeContext } from "../../../../assets/styles/darkLightMode/themeContext";

export default function ManageSimulationSteps() {
  const { theme } = useContext(ThemeContext);
  const { tab, setTab, stepOneDone, stepTwoDone, stepThreeDone } = useContext(ManageSimulationContext)
  const [menuOpen, setMenuOpen] = useState(true);

  const navigate = useNavigate();

  function handleSimulate() {
    setTab(5)
  }

  function handleSaveSimulation() {

  }

  return (
    <div className="create-simulation-menu-div">
      <ExpandableLeftMenuTopButton theme={theme} text="Create simulation" className="margin-bottom-1" icon={menuOpen ? <TbLayoutSidebarLeftCollapse className="icon small-text color-white" /> : <TbLayoutSidebarLeftExpand className="margin-center icon small-text color-white" />} open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />

      <ExpandableLeftMenuTabButton theme={theme} text="Type" className={tab === 1 ? "active" : "inactive"} statusIcon={stepOneDone ? <IoCheckmarkCircle className="status-icon color-green small-text"></IoCheckmarkCircle> : <AiFillExclamationCircle className="status-icon color-orange small-text"></AiFillExclamationCircle>} icon={<IoGitNetworkOutline className="icon small-text color-white" />} open={menuOpen} onClick={() => setTab(1)} />
      <ExpandableLeftMenuTabButton theme={theme} text="Assets" className={tab === 2 ? "active" : "inactive"} statusIcon={stepTwoDone ? <IoCheckmarkCircle className="status-icon color-green small-text"></IoCheckmarkCircle> : <AiFillExclamationCircle className="status-icon color-orange small-text"></AiFillExclamationCircle>} icon={<TbFileDollar className="icon small-text color-white" />} open={menuOpen} onClick={() => setTab(2)} />
      <ExpandableLeftMenuTabButton theme={theme} text="Scenarios" className={tab === 3 ? "active" : "inactive"} statusIcon={<IoCheckmarkCircle className="status-icon color-transparent small-text" />} icon={<IoBuildOutline className="icon small-text color-white" />} open={menuOpen} onClick={() => setTab(3)} />
      <ExpandableLeftMenuTabButton theme={theme} text="Details" className={tab === 4 ? "active" : "inactive"} statusIcon={<IoCheckmarkCircle className="status-icon color-transparent small-text" />} icon={<AiOutlineInfoCircle className="icon small-text color-white" />} open={menuOpen} onClick={() => setTab(4)} />
      {menuOpen ?
        <BackgroundStraightAnglesFullWidthIconTextButton theme={theme} className={`margin-top-2`} text="Simulate" icon={<HiOutlineCubeTransparent className="icon medium-text color-white" />} onClick={handleSimulate} />
        :
        <BackgroundStraightAnglesFullWidthIconButton theme={theme} className={`margin-top-2`} icon={<HiOutlineCubeTransparent className="medium-text" />} onClick={handleSimulate} />
      }
      <FlexBlockDiv className="margin-top-1 margin-left-1 margin-right-1">
        <NavLink className="cancel-link nav-link-comp" to="/simulator">
          {menuOpen ?
            <NoBorderTextButton theme={theme} text="Cancel" />
            :
            <NoBorderIconButton theme={theme} icon={<AiOutlineCloseCircle className="icon small-text color-white" />} />
          }
        </NavLink>
        {menuOpen ?
          <NoBorderTextButton theme={theme} onClick={handleSaveSimulation} text="Save" />
          :
          <NoBorderIconButton theme={theme} onClick={handleSaveSimulation} icon={<AiOutlineSave className="icon small-text color-white" />} />
        }
      </FlexBlockDiv>
    </div>
  );
}
