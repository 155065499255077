import React, { useState, forwardRef, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";

import { closeModal } from "../../../ModalWindow/ModalUtils";
import "./AssetsHistoryManagement.scss"
import { BsCalendarEvent } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";

import FormField from "../FormField";
import { BorderTextButton, NoBorderTextButton } from "lynxai-ui-kit";
import { FlexBlockDiv } from "../../../../assets/styles/global";
import { ThemeContext } from "../../../../assets/styles/darkLightMode/themeContext";

const AddDateForm = (props) => {
    const { theme } = useContext(ThemeContext)

    const [pickedDate, setPickedDate] = useState(new Date())
    const [dateRepeated, setDateRepeated] = useState(false)
    const [datefieldFilled, setDatefieldFilled] = useState(false)

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="date-input" onClick={onClick} ref={ref}>
            <BsCalendarEvent className="font-style-montserrat-family-regular small-text color-white" /><p className="font-style-montserrat-family-regular extra-small-text color-white">{value}</p><FaChevronDown className="color-lightgray small-text" />
        </button>
    ));

    const handleAddAction = () => {
        closeModal(props?.open, props?.setOpen)
        setTimeout(() => {
            props?.addDate(pickedDate?.toLocaleDateString('en-GB'))
        }, 350)
    }

    useEffect(() => {
        setDatefieldFilled(true)
        setDateRepeated(props?.dates?.includes(pickedDate?.toLocaleDateString('en-GB')))
    }, [pickedDate]);

    return (
        <div className="add-date-form-div">
            <FormField fieldName="Select date" field={props?.purpose === "details" ? <p className="font-style-montserrat-family-regular extra-small-text color-white">{pickedDate?.toISOString().substring(0, 10)}</p> :
                <div style={{ position: 'relative', zIndex: 2 }}>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={pickedDate}
                        calendarClassName="row-stripes"
                        onChange={(date) => { setPickedDate(date) }}
                        customInput={<CustomInput />}
                        filterDate={date => { return new Date() > date }}
                    />
                </div>}
            />
            {dateRepeated && <FormField fieldName="" field={<p className="margin-top-1 font-style-montserrat-family-regular extra-small-text color-red">The selected date was already chosen.</p>} />}
            <FlexBlockDiv className="right-alignment margin-top-2">
                <NoBorderTextButton theme={theme} text="Cancel" onClick={() => closeModal(props?.open, props?.setOpen)} />
                <BorderTextButton theme={theme} className={!datefieldFilled || dateRepeated && "button-disabled"} text="Add" onClick={handleAddAction} disabled={!datefieldFilled || dateRepeated} />
            </FlexBlockDiv>
        </div>
    );
}

export default AddDateForm;