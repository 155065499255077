
import React, { useState } from "react";

import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";
import StepFour from "./StepFour/StepFour";
import StepFive from "./StepFive/StepFive";

import "./MultiStep.scss"

const MultiStep = ({ selectedFund, stepsInfo, setStepsInfo }) => {
    const [step, setStep] = useState(1)

    const selectStepNumber = (stepNumber) => {
        setStep(stepNumber)
    }

    return (
        <>
            <div className="multi-step-main-div margin-top-2 margin-bottom-1">
                <ul className="ul-five-columns form-stepper">
                    <li className={`form-stepper-${step === 1 ? "active" : "unfinished"} form-stepper-list`} step="1" onClick={() => selectStepNumber(1)}>
                        <div className="label font-style-montserrat-family-regular extra-small-text">1. Summary</div>
                    </li>
                    <li className={`form-stepper-${step === 2 ? "active" : "unfinished"} form-stepper-list`} onClick={() => selectStepNumber(2)} step="2">
                        <div className="label font-style-montserrat-family-regular extra-small-text">2. Description of the principal adverse impacts on sustainability factors</div>
                    </li>
                    <li className={`form-stepper-${step === 3 ? "active" : "unfinished"} form-stepper-list`} onClick={() => selectStepNumber(3)} step="3">
                        <div className="label font-style-montserrat-family-regular extra-small-text">3. Description of policies to identify and prioritise PAI on sustainability factors</div>
                    </li>
                    <li className={`form-stepper-${step === 4 ? "active" : "unfinished"} form-stepper-list`} onClick={() => selectStepNumber(4)} step="4">
                        <div className="label font-style-montserrat-family-regular extra-small-text">4. Engagement Policies</div>
                    </li>
                    <li className={`form-stepper-${step === 5 ? "active" : "unfinished"} form-stepper-list`} onClick={() => selectStepNumber(5)} step="5">
                        <div className="label font-style-montserrat-family-regular extra-small-text">5. References to international standards</div>
                    </li>
                </ul>
            </div>
            <div className="steps-content-div">
                <StepOne step={step} selectedFund={selectedFund} stepsInfo={stepsInfo} setStepsInfo={setStepsInfo}></StepOne>
                <StepTwo step={step} selectedFund={selectedFund} stepsInfo={stepsInfo} setStepsInfo={setStepsInfo}></StepTwo>
                <StepThree step={step} selectedFund={selectedFund} stepsInfo={stepsInfo} setStepsInfo={setStepsInfo}></StepThree>
                <StepFour step={step} selectedFund={selectedFund} stepsInfo={stepsInfo} setStepsInfo={setStepsInfo}></StepFour>
                <StepFive step={step} selectedFund={selectedFund} stepsInfo={stepsInfo} setStepsInfo={setStepsInfo}></StepFive>
            </div>
        </>
    );
}

export default MultiStep;
