function convertToInternationalCurrencySystem(labelValue, n) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9
        ? (Math.abs(Number(labelValue)) / 1.0e+9)?.toFixed(n).replace(/[.,]0/, "") + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6
            ? (Math.abs(Number(labelValue)) / 1.0e+6)?.toFixed(n).replace(/[.,]0/, "") + "M"
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3
                ? (Math.abs(Number(labelValue)) / 1.0e+3)?.toFixed(n).replace(/[.,]0/, "") + "K"
                : Math.abs(Number(labelValue));
}

function convertToCurrency(value) {
    return value?.toLocaleString("en-US", {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
        groupingSeparator: ' '
    }).replace(/,/g, ' ').replace(/€/g, '');
}

function convertToBigNumbers(value) {
    return value?.toLocaleString("pt-PT", {
        useGrouping: true,
        groupingSeparator: ' '
    });
}

function roundStringToTwoDecimalPlaces(str) {
    let num = parseFloat(str)
    let num_zeros = countZerosAfterDecimal(num)
    return parseFloat(parseFloat(num)?.toFixed(Math.log10(num) < -2 ? num_zeros + 3 : 3));
}

function roundStringToTwoDecimalPlacesOrMinorLabel(value) {
    let roundedValue = value * 100;
    const parsedValue = parseFloat(roundedValue);
    if (!isNaN(parsedValue)) {
      roundedValue = parsedValue?.toFixed(2);
    }
    if (roundedValue < 0.001) {
      return "<0.001";
    }
    return Number(parseFloat(roundedValue));
  }

function roundPercentage(str) {
    const percentage = parseFloat(str);
    const roundedPercentage = percentage?.toFixed(3);
    const roundedPercentageString = roundedPercentage === "0.000" ? "0%" : roundedPercentage + "%";
    return roundedPercentageString
}

function countZerosAfterDecimal(number) {
    const str = number.toString();
    const decimalIndex = str.indexOf('.');

    if (decimalIndex === -1) {
        return 0; // No decimal point found
    }

    let zeroCount = 0;
    let i = decimalIndex + 1;

    while (i < str?.length && str[i] === '0') {
        zeroCount++;
        i++;
    }

    return zeroCount;
}

export { convertToInternationalCurrencySystem, convertToCurrency, convertToBigNumbers, roundStringToTwoDecimalPlaces, roundStringToTwoDecimalPlacesOrMinorLabel, roundPercentage };