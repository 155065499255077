import React, { useState, useEffect, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";

import { GeneralContext } from "../../utils/functions_variables/variables";
import openSupportTicket from "../../utils/requests/supportMessage";

import "./Configurations.scss"

import LocalLoader from "../../components/LocalLoader/LocalLoader";
import { BorderTextFullWidthButton, CardProperties, NormalInput, Textarea, MainTitleTransparentCard } from "lynxai-ui-kit";
import { FlexBlockDiv } from "../../assets/styles/global";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";
import { handleKeyDownEvent } from "../../utils/functions_variables/functions";

const Support = () => {
    const { theme } = useContext(ThemeContext)
    const [isLoading, setIsLoading] = useState(false)
    const { userName, userEmail, setWarningMessage } = useContext(GeneralContext);
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [buttonEnable, setButtonEnable] = useState(false)
    const form = useRef();

    useEffect(() => {
        if (subject !== "" && subject?.length > 2 && description !== "" && description?.length > 15) {
            setButtonEnable(true)
        } else {
            setButtonEnable(false)
        }
    }, [description, subject]);

    if (isLoading) {
        return <LocalLoader></LocalLoader>;
    }

    const onSubmit = async (event) => {
        if (buttonEnable) {
            event.preventDefault();
            setIsLoading(true)
            await openSupportTicket(form).then((data) => {
                setIsLoading(false)
                setWarningMessage(data)
            }).catch((error) => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    }

    return (
        <div className="configurations-main-div">
            <MainTitleTransparentCard theme={theme} text="Support - How can we help you?" />
            <CardProperties theme={theme} className="configurations-card support-card">
                <FlexBlockDiv>
                    <p className="faq-reminder extra-small-text font-style-montserrat-family-regular color-white">Before submitting your support request, please take a moment to review our <NavLink to="/configurations/faq" state={{ from: 2 }}>FAQ</NavLink> page.
                        <br />It's possible that your question has already been answered there, saving you time and effort. If you can't find the information you need, feel free to proceed with filling out the form below.
                        <br />We reply within 48 hours.</p>
                </FlexBlockDiv>
                <form ref={form} onSubmit={onSubmit}>
                    <div className="form-div">
                        <NormalInput theme={theme} className="input-small margin-bottom-1" name="name" value={userName} disabled={true} type="text" />
                        <NormalInput theme={theme} className="input-small margin-bottom-1" name="email" value={userEmail} disabled={true} type="text" />
                        <input type="hidden" name="name" value={userName} />
                        <input type="hidden" name="email" value={userEmail} />
                        <NormalInput theme={theme} className="margin-bottom-1" placeholder="Subject" value={subject} onChange={(e) => setSubject(e?.target?.value)} autoFocus={true} required={true} type="text" onKeyDown={(e) => handleKeyDownEvent(onSubmit, e)} />
                        <Textarea theme={theme} placeholder="Description" name="message" maxLength={1200} type="text" rows={7} value={description} onChange={(e) => setDescription(e?.target?.value)} required={true} />
                    </div>
                </form>
                <BorderTextFullWidthButton theme={theme} text="Send" className={buttonEnable ? "button-hover" : "button-disabled"} onClick={onSubmit} type="submit" disabled={!buttonEnable} />
            </CardProperties>
        </div>
    );
}

export default Support;