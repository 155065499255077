import React, { useEffect, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, Rectangle, CartesianGrid, Tooltip, BarChart, Bar, ResponsiveContainer, Label } from 'recharts';

import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";
import { ValueTooltip, TickFormatterYAxisBigText } from "../../DataVisualization/Tooltips";
import { convertToBigNumbers } from "../../../utils/Maths/MathsFunctions";
import "./MetricsBoardAnalysisCard.scss"

import { RxCross2 } from "react-icons/rx";

import MetricQuestionBox from "../../MetricQuestionBox/MetricQuestionBox";
import MetricQuestionGraph from "../../MetricQuestionBox/MetricQuestionGraph";
import RankingDotsChart from "../../DataVisualization/RankingDotsChart/RankingDotsChart";
import LineChartGraph from "../../DataVisualization/LineChartGraph/LineChartGraph";

import { NoBorderIconButton, CardProperties } from "lynxai-ui-kit";
import { FlexBlockDiv } from "../../../assets/styles/global";

const MetricsBoardAnalysisCard = ({ name, metric_id, data, handleRemoveMetricToFundCharacteristicsData }) => {
    const { theme } = useContext(ThemeContext);

    function removeBoard() {
        handleRemoveMetricToFundCharacteristicsData(name, metric_id)
    }

    return (
        <CardProperties theme={theme} className="metrics-board-analysis-div big-chart-card-size card-height-2">
            <FlexBlockDiv>
                <p className="font-style-montserrat-family-regular small-text color-white">{name}</p>
                <NoBorderIconButton theme={theme} icon={<RxCross2 className="icon medium-text color-white"/>} onClick={removeBoard}/>
            </FlexBlockDiv>
            <div className="multi-analysis-div">
                <div className="top-div">
                    <div className="main-graph">
                        <LineChartGraph title="CO2 emissions per year" data={data?.emissionData?.sort((a, b) => a.year - b.year)} />
                    </div>
                    <div className="right-div">
                        <div className="questions-div">
                            <MetricQuestionGraph text="Which assets are in the top 5 as best and worst performers? (CO2 emissions per EUR invested)" sizeClass="two-charts-visualization">
                                <div className="double-graph-modal-div">
                                    <p className="top-title font-style-montserrat-family-semibold extra-small-text color-white">Best 5</p>
                                    <RankingDotsChart data={data?.bestAssetsPerEurInvested} domainInterval={data?.domainAssetsPerEuroInvested[0]} calculatedInterval={data?.domainAssetsPerEuroInvested[1]} labelText={"Emissions (tons)"} dotsColor="var(--main-green)" />
                                    <p className="top-title font-style-montserrat-family-semibold extra-small-text color-white">Worst 5</p>
                                    <RankingDotsChart data={data?.worstAssetsPerEurInvested} domainInterval={data?.domainAssetsPerEuroInvested[0]} calculatedInterval={data?.domainAssetsPerEuroInvested[1]} labelText={"Emissions (tons)"} dotsColor="var(--main-red)" />
                                </div>
                            </MetricQuestionGraph>
                            <MetricQuestionGraph text="Which industries are in the top 3 as best and worst performers? (CO2 emissions per EUR invested)" sizeClass="two-charts-visualization">
                                <div className="double-graph-modal-div">
                                    <p className="top-title font-style-montserrat-family-semibold extra-small-text color-white">Best 3</p>
                                    <RankingDotsChart data={data?.bestIndustriesPerEurInvested} domainInterval={data?.domainIndustriesPerEuroInvested[0]} calculatedInterval={data?.domainIndustriesPerEuroInvested[1]} labelText={"Emissions (tons)"} dotsColor="var(--main-green)" />
                                    <p className="top-title font-style-montserrat-family-semibold extra-small-text color-white">Worst 3</p>
                                    <RankingDotsChart data={data?.worstIndustriesPerEurInvested} domainInterval={data?.domainIndustriesPerEuroInvested[0]} calculatedInterval={data?.domainIndustriesPerEuroInvested[1]} labelText={"Emissions (tons)"} dotsColor="var(--main-red)" />
                                </div>
                            </MetricQuestionGraph>
                        </div>
                        <div className="questions-div">
                            <MetricQuestionGraph text="Which assets are in the top 5 as best and worst performers? (CO2 emissions per revenue)" sizeClass="two-charts-visualization">
                                <div className="double-graph-modal-div">
                                    <p className="top-title font-style-montserrat-family-semibold extra-small-text color-white">Best 5</p>
                                    <RankingDotsChart data={data?.bestAssetsPerRevenue} domainInterval={data?.domainAssetsPerRevenue[0]} calculatedInterval={data?.domainAssetsPerRevenue[1]} labelText={"Emissions (tons)"} dotsColor="var(--main-green)" />
                                    <p className="top-title font-style-montserrat-family-semibold extra-small-text color-white">Worst 5</p>
                                    <RankingDotsChart data={data?.worstAssetsPerRevenue} domainInterval={data?.domainAssetsPerRevenue[0]} calculatedInterval={data?.domainAssetsPerRevenue[1]} labelText={"Emissions (tons)"} dotsColor="var(--main-red)" />
                                </div>
                            </MetricQuestionGraph>
                            <MetricQuestionGraph text="Which industries are in the top 3 as best and worst performers? (CO2 emissions per revenue)" sizeClass="two-charts-visualization">
                                <div className="double-graph-modal-div">
                                    <p className="top-title font-style-montserrat-family-semibold extra-small-text color-white">Best 3</p>
                                    <RankingDotsChart data={data?.bestIndustriesPerRevenue} domainInterval={data?.domainIndustriesPerRevenue[0]} calculatedInterval={data?.domainIndustriesPerRevenue[1]} labelText={"Emissions (tons)"} dotsColor="var(--main-green)" />
                                    <p className="top-title font-style-montserrat-family-semibold extra-small-text color-white">Worst 3</p>
                                    <RankingDotsChart data={data?.worstIndustriesPerRevenue} domainInterval={data?.domainIndustriesPerRevenue[0]} calculatedInterval={data?.domainIndustriesPerRevenue[1]} labelText={"Emissions (tons)"} dotsColor="var(--main-red)" />
                                </div>
                            </MetricQuestionGraph>
                        </div>
                    </div>
                </div>
                <div className="bottom-div">
                    <MetricQuestionBox text="Is it currently above or below the threshold?" value="XX" />
                    <MetricQuestionBox text="What were the lowest and highest emission values?" value1={convertToBigNumbers(data?.highestEmissionValue)} value2={convertToBigNumbers(data?.lowestEmissionValue)} />
                </div>
            </div>
        </CardProperties>
    );
}

export default MetricsBoardAnalysisCard;