import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  flex-wrap: no-wrap;
  justify-content: center;
  align-items: center;
  height:100vh;
  margin: 0;
  overflow-x: hidden;
`;

export const InsideContainer = styled.div`
  width:100vw;
  height: 100vh;
  display:none;
  flex-flow:column;
  @media (min-width: 800px) {
    display:flex;
  }
`;

export const MainDiv = styled.div`
  flex-wrap: wrap;
  box-sizing: border-box;
  flex:1;
  width: 100%;
  padding: var(--size-three);
  position: relative;
  display:flex;
`;

export const FlexBlockDiv = styled.div`
  display:flex;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-three);

  p {
    width: 100% !important;
}
`;

export const TooSmallMessage = styled.div`
  display:none;
  height: 100%;
  text-align: center;
  line-height: var(--size-four);
  width:100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    display:flex;
  }
`;

///////////////////////////

export const COLORS_1 = ['#FFBB28', '#9747FF'];
export const COLORS_2 = ['#0071dc', '#36E7FF', '#35D272', '#FFBB28', '#FF8042', '#FE562F', '#9747FF', '#4fe8b0'];