export const findValue = (data, key, value) => {
  return data?.filter(item => item[key] === value);
};

export const stringToDict = (dict) => {
  const newDict = {}
  for (let [key, value] of Object.entries(dict)) {
    let keys = key.split('_');
    let current_dict = newDict;
    for (let i = 0; i < keys?.length; i++) {
      let k = keys[i];
      if (i === keys?.length - 1) {
        current_dict[k] = value;
      } else {
        if (!(k in current_dict)) {
          current_dict[k] = {};
        }
        current_dict = current_dict[k];
      }
    }
  }
  return newDict
}

export function checkIfAbleToSave(addedAssetsList, createdAssetsList) {
  if (Object.keys(addedAssetsList)?.length > 0 || Object.keys(createdAssetsList)?.length > 0) {
    if (Object.keys(addedAssetsList)?.length > 0) {
      for (const innerKey in addedAssetsList) {
        const innerDict = addedAssetsList[innerKey];
        if (innerDict?.every(asset => {
          const shareMandatory = (asset?.asset_type === "equity" || asset?.asset_type === "bonds_fixed_income")
          return (shareMandatory ? (asset?.share !== "" && (asset?.amount !== "" ? asset?.currency !== "" : true)) : ((asset?.amount !== "" && asset?.currency !== "")))
        })) {
          // pass;
        } else {
          return false
        }
      }
    }
    if (Object.keys(createdAssetsList)?.length > 0) {
      for (const innerKey in createdAssetsList) {
        const innerDict = createdAssetsList[innerKey];
        if (innerDict?.every(asset => {
          const shareMandatory = (asset?.asset_type === "equity" || asset?.asset_type === "bonds_fixed_income")
          return (shareMandatory ? (asset?.share !== "" && (asset?.amount !== "" ? asset?.currency !== "" : true)) : ((asset?.amount !== "" && asset?.currency !== "")))
        })) {
          // pass;
        } else {
          return false
        }
      }
    }
    return true
  } else {
    return false
  }
}

export function checkKeyAndGetValue(key, myDict) {
  let value = ""
  if (myDict !== undefined && key in myDict) {
    value = myDict[key]
  } else {
    value = "-"
  }
  return value
};

export function handleKeyDownEvent(action, event) {
  if (event.keyCode === 13) {
    action(event)
  }
};

export function handleThemeChange(theme, setTheme) {
  const isCurrentDark = theme === 'dark';
  setTheme(isCurrentDark ? 'light' : 'dark');
  localStorage.setItem('theme', isCurrentDark ? 'light' : 'dark');
};

export const toTop = () => {
  const main_container = document.getElementById("Container")
  main_container.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export const HandleNavigationSearchBar = (navigate, selectedOption, setInputText) => {
  if (selectedOption.type === 'fund') {
    setInputText("")
    navigate(`./portfolio/fund-profile/${selectedOption.label}`)
  } else if (selectedOption.type === 'asset') {
    setInputText("")
    navigate(`./portfolio/asset-profile/${selectedOption.value}`)
  }
}

export function HandleInputChangeSearchBar(input, action, setInputText) {
  if (action.action === "input-change") {
    setInputText(input)
  }
}

export function getInvestmentTypesFromAssetType(json, asset_type) {
  let mergedDict = [];
  for (const entry of json) {
    if (entry.value === asset_type) {
      return entry.investment_types;
    }
    mergedDict = mergedDict.concat(entry.investment_types)
  }
  return mergedDict;
}