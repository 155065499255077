import React, { useState, useEffect, useContext } from "react";

import { convertToCurrency, convertToBigNumbers } from "../../../utils/Maths/MathsFunctions";
import { ManageFundContext } from "../../../utils/functions_variables/variables";
import { checkIfAbleToSave, getInvestmentTypesFromAssetType } from "../../../utils/functions_variables/functions";
import { BiTrash } from "react-icons/bi";

import assets_and_investments_types from "./assets_and_investments_types.json"

import "./ManageFund.scss"
import { NoBorderIconButton, NormalInput, NormalControlReactSelect, NormalDropdown, InputOptionSingleValue } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const options_currency = [
    { value: 'EUR', label: 'Euro (EUR)' },
    { value: 'USD', label: 'Dollar (USD)' },
    { value: 'GBP', label: 'Pound (GBP)' },
    { value: 'JPY', label: 'Japanese yen (JPY)' },
    { value: 'AUD', label: 'Australian dollar (AUD)' },
    { value: 'CAD', label: 'Canadian dollar (CAD)' },
    { value: 'CHF', label: 'Swiss franc (CHF)' },
    { value: 'CNH', label: 'Chinese renminbi (CNH)' },
    { value: 'HKD', label: 'Hong Kong dollar (HKD)' },
    { value: 'NZD', label: 'New Zealand dollar (NZD)', }
];

const CreatedAssetCard = ({ keySecondary, text, asset_type, investment_type, currency, amount, share, setButtonEnable }) => {
    const { theme } = useContext(ThemeContext)
    const { fundAssets, selectedDate, createdAssets, setCreatedAssets, setStepThreeDone } = useContext(ManageFundContext)

    const investmentTypesList = getInvestmentTypesFromAssetType(assets_and_investments_types, asset_type)
    const [unitInvestmentType, setUnitInvestmentType] = useState(investment_type !== "" ? investmentTypesList?.find((item) => item?.value === investment_type) : null)
    const [unitCurrency, setUnitCurrency] = useState(currency !== "" ? options_currency?.find((item) => item?.value === currency) : null)
    const [unitAmount, setUnitAmount] = useState(convertToCurrency(Number(amount)))
    const shareMandatory = (asset_type === "equity" || asset_type === "bonds_fixed_income")
    const [unitShare, setUnitShare] = useState(convertToBigNumbers(share))
    const [currencyFilled, setCurrencyFilled] = useState(currency !== "" ? true : false)
    const [amountFilled, setAmountFilled] = useState(amount !== "" && amount !== "0.00" && amount !== 0 ? true : false)
    const [sharesFilled, setShareFilled] = useState(share !== "" ? true : false)

    useEffect(() => {
        if (unitShare !== "" && unitShare !== "0" && unitShare !== 0) {
            setShareFilled(true)
        } else {
            setShareFilled(false)
        }
    }, [unitShare]);

    useEffect(() => {
        if (unitAmount !== "" && unitAmount !== "0.00" && unitAmount !== 0) {
            setAmountFilled(true)
        } else {
            setAmountFilled(false)
        }
    }, [unitAmount]);

    const handleRemove = () => {
        let newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate] = createdAssets[selectedDate]?.filter(element => element?.keySecondary !== keySecondary)
        if (newCreatedAssets[selectedDate]?.length === 0) {
            delete newCreatedAssets[selectedDate];
        }
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
    }

    function handleUpdateCurrenciesCreatedAssets(value) {
        const foundIndex = createdAssets[selectedDate]?.findIndex(item => item?.keySecondary === keySecondary)
        const newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate][foundIndex].currency = value
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
        if (!checkIfAbleToSave(fundAssets, newCreatedAssets)) { setStepThreeDone(false) }
    }

    function handleUpdateAmountsCreatedAssets(value) {
        const foundIndex = createdAssets[selectedDate]?.findIndex(item => item?.keySecondary === keySecondary)
        const newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate][foundIndex].amount = value
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
        if (!checkIfAbleToSave(fundAssets, newCreatedAssets)) { setStepThreeDone(false) }
    }

    function handleUpdateShareCreatedAssets(value) {
        const foundIndex = createdAssets[selectedDate]?.findIndex(item => item?.keySecondary === keySecondary)
        const newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate][foundIndex].share = value
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
        if (!checkIfAbleToSave(fundAssets, newCreatedAssets)) { setStepThreeDone(false) }
    }

    function handleInvestmentType(selectedOption) {
        setUnitInvestmentType(selectedOption)
        const foundIndex = createdAssets[selectedDate]?.findIndex(item => item?.keySecondary === keySecondary)
        const newCreatedAssets = { ...createdAssets }
        newCreatedAssets[selectedDate][foundIndex].investment_type = selectedOption?.value
        setCreatedAssets(newCreatedAssets)
        setButtonEnable(checkIfAbleToSave(fundAssets, newCreatedAssets))
        if (!checkIfAbleToSave(fundAssets, newCreatedAssets)) { setStepThreeDone(false) }
    }

    function handleFocusAmount() {
        if (unitAmount === "") {
            setUnitAmount("")
        } else {
            setUnitAmount(Number(parseFloat(unitAmount.replace(/\s/g, ''))))
        }
    }

    function handleAssetCurrency(selectedOption) {
        setUnitCurrency(selectedOption)
        setCurrencyFilled(true)
        handleUpdateCurrenciesCreatedAssets(selectedOption?.value)
    }

    function handleAssetAmount(e) {
        const regex = /^\d{0,15}(\.\d{0,2})?$/;
        const newValue = e;
        if (newValue !== "" && regex.test(newValue)) {
            setUnitAmount(newValue)
        } else if (newValue === "" && regex.test(newValue)) {
            setUnitAmount("")
        }
    }

    function handleFocusShare() {
        if (unitShare === "") {
            setUnitShare("")
        } else {
            setUnitShare(Number(parseFloat(unitShare.replace(/\s/g, ''))))
        }
    }

    function handleAssetShare(e) {
        const regex = /^[0-9]*$/;
        const newValue = e;
        if (regex.test(newValue) && newValue !== "") {
            setUnitShare(newValue)
        } else if (regex.test(newValue) && newValue === "") {
            setUnitShare("")
        }
    }

    function handleInputAmountBlur() {
        if (unitAmount === "") {
            setUnitAmount("");
            setUnitCurrency(null)
            setCurrencyFilled(false)
            handleUpdateCurrenciesCreatedAssets("")
            handleUpdateAmountsCreatedAssets("")
        } else {
            setUnitAmount(convertToCurrency(Number(parseFloat(unitAmount))));
            handleUpdateAmountsCreatedAssets(Number(parseFloat(unitAmount)))
        }
    }

    function handleInputShareBlur() {
        if (unitShare === "") {
            setUnitShare("");
            handleUpdateShareCreatedAssets("")
        } else {
            setUnitShare(convertToBigNumbers(Number(parseFloat(unitShare))));
            handleUpdateShareCreatedAssets(convertToBigNumbers(Number(parseFloat(unitShare))))
        }
    }

    return (
        <div className="created-asset-card font-style-montserrat-family-semibold extra-small-text color-white">
            <div className="label-div">
                <p className="fund-name">{text}</p>
                <p className="label-new font-style-montserrat-family-semibold extra-small-text color-green">CREATED</p>
            </div>
            <NormalDropdown theme={theme} value={unitInvestmentType} noOptionsMessage={() => <p className="extra-small-text font-style-montserrat-family-regular">No results match your searching criteria.</p>}
                options={investmentTypesList} onChange={handleInvestmentType} components={{ Option: InputOptionSingleValue, Control: NormalControlReactSelect }} />
            <NormalInput theme={theme} className={shareMandatory && (sharesFilled ? "border-green" : "border-red")} placeholder="0" value={unitShare} onChange={(e) => handleAssetShare(e?.target?.value)} required={true} type="text" onFocus={handleFocusShare} onBlur={handleInputShareBlur} />
            <NormalInput theme={theme} className={!shareMandatory && (amountFilled ? "border-green" : "border-red")} placeholder="0.00" value={unitAmount} onChange={(e) => handleAssetAmount(e?.target?.value)} type="text" onFocus={handleFocusAmount} onBlur={handleInputAmountBlur} />
            <NormalDropdown theme={theme} value={unitCurrency} noOptionsMessage={() => <p className="extra-small-text font-style-montserrat-family-regular">No results match your searching criteria.</p>} borderStatus={`${(!shareMandatory || amountFilled) && (currencyFilled ? "var(--main-green)" : "red")}`}
                options={options_currency} onChange={handleAssetCurrency} components={{ Option: InputOptionSingleValue, Control: NormalControlReactSelect }} />
            <NoBorderIconButton theme={theme} onClick={handleRemove} icon={<BiTrash className="icon-red small-text color-white" />} />
        </div>
    );
}

export default CreatedAssetCard;