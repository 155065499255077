import React, { useState, useContext, useEffect } from "react";

import "./FAQ.scss"
import faq_list from "./FAQ_list.json"

import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import FAQContentCard from "./FAQContentCard";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";
import { MainTitleTransparentCard, NormalSearchBar } from "lynxai-ui-kit";

const FAQ = () => {
  const { theme } = useContext(ThemeContext)
  const [displayFAQContent, setDisplayFAQContent] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalContent, setModalContent] = useState("")
  const [search, setSearch] = useState("")
  const [filteredFAQ, setFilteredFAQ] = useState(faq_list)

  useEffect(() => {
    setFilteredFAQ(filterJsonData(search))
  }, [search]);

  const filterJsonData = (filterString) => {
    const filteredData = faq_list
      ?.map((item) => ({
        title: item?.title,
        topics: item?.topics?.filter((topic) =>
          topic?.title?.toLowerCase().includes(filterString?.toLowerCase())
        ),
      }))
      ?.filter((item) => item?.topics?.length > 0);

    return filteredData;
  };

  const handleSearchChange = event => {
    setSearch(event?.target?.value);
  };

  return (
    <>
      <div className="configurations-main-div">
        <MainTitleTransparentCard theme={theme} text="Frequently Asked Questions">
          <NormalSearchBar theme={theme} placeholder="Search topics..." onChange={handleSearchChange} value={search} />
        </MainTitleTransparentCard>
        <div className="faq-content-div">
          {filteredFAQ.map((item, index) =>
            <FAQContentCard key={index} title={item?.title} topics={item?.topics} setOpen={setDisplayFAQContent} setModalTitle={setModalTitle} setModalContent={setModalContent} />
          )}
        </div>
      </div>
      <ModalWindow theme={theme} title={modalTitle} sizeClass="create-asset-create-fund" open={displayFAQContent} setOpen={setDisplayFAQContent}>
        <div className="faq-modal-div">
          <p className="font-style-montserrat-family-regular extra-small-text color-white">
            {modalContent}
          </p>
        </div>
      </ModalWindow>
    </>
  );
}

export default FAQ;