import React, { useState, useEffect, useContext } from "react";
import "./ModalWindow.scss"

import { NoBorderIconButton, CardProperties } from "lynxai-ui-kit";
import { FlexBlockDiv } from "../../assets/styles/global";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

import { closeModal } from "./ModalUtils";
import { RxCross2 } from "react-icons/rx";

const Delayed = ({ children, waitBeforeShow = 225 }) => {
  const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsShown(true);
        }, waitBeforeShow);
    }, [waitBeforeShow]);

    return isShown ? children : null;
};


export default class ModalWindow extends React.Component {

    render() {
        const { sizeClass, setOpen, open, title, topBlockItems, children, theme } = this.props

        if (!open) {
            return null;
        }
        setTimeout(() => {
            document.getElementById("modal-window-div").style.transform = "translate(-50%, -50%) rotateX(0deg)"
        }, 0);
        return <>
            <div className="hidden-background" onClick={() => closeModal(open, setOpen)}></div>
            <CardProperties theme={theme} cardId="modal-window-div" className={sizeClass}>
                {/* <Delayed> */}
                <FlexBlockDiv>
                    <p className="font-style-montserrat-family-semibold small-text color-white">{title}</p>
                    {topBlockItems}
                    <NoBorderIconButton theme={theme} onClick={() => closeModal(open, setOpen)} icon={<RxCross2 className="icon small-text color-lightgray" />} />
                </FlexBlockDiv>
                {children}
                {/* </Delayed> */}
            </CardProperties>
        </>;
    }
}