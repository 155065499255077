import React, { useState, useContext, useEffect } from "react";
import PasswordChecklist from "react-password-checklist"
import { Auth } from "aws-amplify";

import LocalLoader from "../../components/LocalLoader/LocalLoader";
import { handleKeyDownEvent } from "../../utils/functions_variables/functions";
import { GeneralContext } from "../../utils/functions_variables/variables";

import { RxCross2 } from "react-icons/rx";
import { HiCheck } from "react-icons/hi";

import { BorderTextFullWidthButton, CardProperties, PasswordInput } from "lynxai-ui-kit";
import { FlexBlockDiv } from "../../assets/styles/global";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

const icons = { ValidIcon: <HiCheck className="icon-check small-text" ></HiCheck>, InvalidIcon: <RxCross2 className="icon-cross small-text"></RxCross2> }

const ChangePassword = () => {
    const { theme } = useContext(ThemeContext)
    const { setWarningMessage } = useContext(GeneralContext)
    const [buttonConfirmNewPasswordsEnable, setButtonConfirmNewPasswordsEnable] = useState(false)
    const [newPasswordCheck, setNewPasswordCheck] = useState(false)
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (buttonConfirmNewPasswordsEnable) {
            setIsLoading(true)
            await Auth?.currentAuthenticatedUser().then(async user => {
                await Auth?.changePassword(user, oldPassword, newPassword).then(data => {
                    setIsLoading(false)
                    setWarningMessage(["success", "Password changed successfully."])
                }).catch(error => {
                    setIsLoading(false)
                    setWarningMessage(["error", error.message])
                })
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    }

    useEffect(() => {
        function checkOldPassword() {
            setOldPassword(oldPassword)
            if (oldPassword !== '' && newPasswordCheck) {
                setButtonConfirmNewPasswordsEnable(true)
            } else {
                setButtonConfirmNewPasswordsEnable(false)
            }
        }
        checkOldPassword()
    }, [oldPassword]);

    const toggleOldPasswordVisibility = (event) => {
        event.preventDefault();
        setShowOldPassword(!showOldPassword);
    };

    const toggleNewPasswordVisibility = (event) => {
        event.preventDefault();
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmNewPasswordVisibility = (event) => {
        event.preventDefault();
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    const validatePasswords = (isValid) => {
        setNewPasswordCheck(isValid)
        if (isValid && oldPassword !== '') {
            setButtonConfirmNewPasswordsEnable(true)
        } else {
            setButtonConfirmNewPasswordsEnable(false)
        }
    }

    return (
        <CardProperties theme={theme} className="configurations-card profile-card">
            <FlexBlockDiv>
                <p className="font-style-montserrat-family-semibold small-text color-white">Change password</p>
            </FlexBlockDiv>
            {isLoading ? <LocalLoader size="textarea-big-input"></LocalLoader>
                :
                <>
                    <form onSubmit={handleChangePassword}>
                        <div className="profile-card-inside-div authentication-wrapper">
                            <PasswordInput theme={theme} className="margin-bottom-1" placeholder="Old password" required={true} autoComplete="new-password" value={oldPassword} showPassword={showOldPassword} onChange={(e) => setOldPassword(e?.target?.value)} onKeyDown={(e) => handleKeyDownEvent(handleChangePassword, e)} toggleShowPassword={toggleOldPasswordVisibility} />
                            <PasswordInput theme={theme} className="margin-bottom-1" placeholder="New password" required={true} autoComplete="new-password" value={newPassword} showPassword={showNewPassword} onChange={(e) => setNewPassword(e?.target?.value)} onKeyDown={(e) => handleKeyDownEvent(handleChangePassword, e)} toggleShowPassword={toggleNewPasswordVisibility} />
                            <PasswordInput theme={theme} className="margin-bottom-1" placeholder="Confirm new password" required={true} autoComplete="new-password" value={confirmNewPassword} showPassword={showConfirmNewPassword} onChange={(e) => setConfirmNewPassword(e?.target?.value)} onKeyDown={(e) => handleKeyDownEvent(handleChangePassword, e)} toggleShowPassword={toggleConfirmNewPasswordVisibility} />
                            <PasswordChecklist className="font-style-montserrat-family-regular extra-small-text color-white"
                                rules={["capital", "lowercase", "minLength", "number", "specialChar", "match"]}
                                minLength={8}
                                fontSize={10}
                                value={newPassword}
                                valueAgain={confirmNewPassword}
                                iconComponents={icons} onChange={(isValid) => validatePasswords(isValid)} />
                        </div>
                    </form>
                    <BorderTextFullWidthButton theme={theme} text="Change password" className={buttonConfirmNewPasswordsEnable ? "button-hover" : "button-disabled"} onClick={handleChangePassword} type="submit" disabled={!buttonConfirmNewPasswordsEnable} />
                </>
            }
        </CardProperties>
    );
}

export default ChangePassword;