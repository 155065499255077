
import React, { useState } from "react";

import "../../MultiStep.scss"

import InvesteeCompaniesTable from "../InvesteeCompaniesTable/InvesteeCompaniesTable";
import RealEstateAssetsTable from "../RealEstateAssetsTable/RealEstateAssetsTable";
import SovereignsSupranationalsTable from "../SovereignsSupranationalsTable/SovereignsSupranationalsTable";

const AdditionalClimateIndicators = ({ selectedFund, dataStatic, dataDynamic }) => {

    const indicator = "additionalClimate";

    return (
        <div className="tables-main-div">
            <InvesteeCompaniesTable selectedFund={selectedFund} indicator={indicator} dataDynamic={dataDynamic?.investeeCompanies} dataStatic={dataStatic?.investeeCompanies} isAdditionalClimate={true} isAdditionalSocial={false}></InvesteeCompaniesTable>
            <SovereignsSupranationalsTable selectedFund={selectedFund} indicator={indicator} dataDynamic={dataDynamic?.sovereignsAndSupranationals} dataStatic={dataStatic?.sovereignsAndSupranationals}></SovereignsSupranationalsTable>
            <RealEstateAssetsTable selectedFund={selectedFund} indicator={indicator} dataDynamic={dataDynamic?.realEstateAssets} dataStatic={dataStatic?.realEstateAssets}></RealEstateAssetsTable>
        </div>
    );
}

export default AdditionalClimateIndicators;