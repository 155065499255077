import React, { useContext } from "react";

import "./TextCard.scss"

import { CardProperties } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const TextCard = ({ title, data, cardType }) => {
    const { theme } = useContext(ThemeContext);

    return (
        <CardProperties theme={theme} className={`text-card-div ${cardType} extra-small-text font-style-montserrat-family-regular color-white`}>
            <p>{title}</p>
            <ul>
                {data?.map((child, index) => <li key={index}>{child?.name} with {`${(child?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</li>)}
            </ul>
        </CardProperties>
    );
}

export default TextCard;