import React, { useState, useContext } from "react";
import { BarChart, Bar, XAxis, Text, Label, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip, Rectangle } from 'recharts';

import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";
import { ValueTooltip, CurrencyTooltip, TooBigXAxis, TickFormatterAxisBigNumber } from "../Tooltips";
import { AiOutlineExpand } from "react-icons/ai";
import { FlexBlockDiv } from "../../../assets/styles/global";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { convertToInternationalCurrencySystem } from "../../../utils/Maths/MathsFunctions";

import { NoBorderIconButton, CardProperties, ThemeVariables } from "lynxai-ui-kit";

const DoubleYAxisBarChartCard = (props) => {
    const { title, data, cardType, yKey1, yKey2, modal } = props;
    const { theme, setTheme } = useContext(ThemeContext);
    const [isModalOpen, setisModalOpen] = useState(false);
    const [activeTooltip, setActiveTooltip] = useState("");

    const labelFormatter = (x) => {
        return convertToInternationalCurrencySystem(x, 1)
    };

    return (
        <>
            {
                data?.length > 0 &&
                <>
                    <CardProperties theme={theme} className={`${modal && 'background-transparent'} ${cardType}`}>
                    {!modal &&
                    <FlexBlockDiv>
                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                        <NoBorderIconButton theme={theme} onClick={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                    </FlexBlockDiv>}
                        <ResponsiveContainer width="100%" height="100%" className="font-style-montserrat-family-regular extra-extra-small-text color-white">
                            <BarChart width="100%" height="100%" data={data} margin={{ top: 35 }}>
                                <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.medium_color_50}` }} vertical={false} />
                                <XAxis dataKey="label" interval={0} tick={<TooBigXAxis data={data} />} tickLine={false} />
                                <YAxis dataKey={yKey1} yAxisId={0} orientation="left" tickFormatter={TickFormatterAxisBigNumber} tick={{ fill: `${ThemeVariables[theme]?.extreme_contrast}` }} tickLine={false}><Label className="font-style-montserrat-family-semibold extra-extra-small-text" style={{ fill: '#0071dc' }} dx={4} position="top" offset={15} value={"No. of assets"} /></YAxis>
                                <YAxis dataKey={yKey2} yAxisId={1} orientation="right" tickFormatter={TickFormatterAxisBigNumber} tick={{ fill: `${ThemeVariables[theme]?.extreme_contrast}` }} tickLine={false}><Label className="font-style-montserrat-family-semibold extra-extra-small-text" style={{ fill: "#35D272" }} position="top" dx={-14} offset={15} value={"Total amount (€)"} /></YAxis>
                                <Bar onMouseOver={() => setActiveTooltip("count")} yAxisId={0} shape={<Rectangle radius={[3, 3, 0, 0]} />} animationDuration={800} dataKey={yKey1} name="Number of assets" fill="#0071dc" minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.light_contrast}`, position: "top" }}></Bar>
                                <Bar onMouseOver={() => setActiveTooltip("currency")} yAxisId={1} shape={<Rectangle radius={[3, 3, 0, 0]} />} animationDuration={800} dataKey={yKey2} name="Total amount (€)" fill="#35D272" minPointSize={1} label={{ fill: `${ThemeVariables[theme]?.light_contrast}`, position: "top", formatter: labelFormatter }}></Bar>
                                <Tooltip wrapperStyle={{ outline: 'none' }} content={activeTooltip === "count" ? <ValueTooltip /> : <CurrencyTooltip />} shared={false} />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardProperties>
                    <ModalWindow theme={theme} title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                        <DoubleYAxisBarChartCard title={title} data={data} yKey1={yKey1} yKey2={yKey2} cardType="modal-card" modal={true}></DoubleYAxisBarChartCard>
                    </ModalWindow>
                </>
            }
        </>
    );
}

export default DoubleYAxisBarChartCard;