import React, { useState, useContext } from "react";

import "../ManageFund.scss"
import { ManageFundContext } from "../../../../utils/functions_variables/variables";
import { checkIfAbleToSave } from "../../../../utils/functions_variables/functions";

import AddAsset from "../AddAsset";
import ModalWindow from "../../../ModalWindow/ModalWindow";
import Assets from "../Assets";
import CreateAsset from "../CreateAsset";
import AssetsHistoryManagement from "../AssetsHistoryManagement/AssetsHistoryManagement";

import { BorderTextButton, CardProperties, MainTitleTransparentCard } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../assets/styles/darkLightMode/themeContext";

const FundAssets = () => {
  const { theme } = useContext(ThemeContext)
  const { purpose, fundAssets, createdAssets, setStepThreeDone } = useContext(ManageFundContext)

  const [displayCreateAsset, setDisplayCreateAsset] = useState(false)
  const [buttonEnable, setButtonEnable] = useState(checkIfAbleToSave(fundAssets, createdAssets))

  function handleNotFound() {
    setDisplayCreateAsset(!displayCreateAsset)
  }

  function handleSave() {
    setStepThreeDone(true)
  }

  return (
    <>
      <MainTitleTransparentCard theme={theme} text="Assets" />
      <div className="fund-details-div">
        <AssetsHistoryManagement />
        <CardProperties theme={theme}>
          {purpose !== "details" && <AddAsset handleNotFound={handleNotFound} />}
          <Assets setButtonEnable={setButtonEnable} />
        </CardProperties>
        {
          purpose !== "details" &&
          <BorderTextButton theme={theme} text="Save" className={`${buttonEnable ? "button-hover" : "button-disabled"} margin-top-2`} onClick={handleSave} disabled={!buttonEnable} />
        }
      </div>
      <ModalWindow theme={theme} title="Create asset" sizeClass="create-asset-create-fund" open={displayCreateAsset} setOpen={setDisplayCreateAsset}>
        <CreateAsset />
      </ModalWindow>
    </>
  );
}

export default FundAssets;