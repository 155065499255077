import React, { useEffect, useState, useContext } from "react";

import "../Regulations.scss"

import MultiStep from "../../../components/PAI/MultiStep/MultiStep";
import MainLoader from "../../../components/MainLoader/MainLoader";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import { closeModal } from "../../../components/ModalWindow/ModalUtils";
import AlertBoxPAIStepRow from "../../../components/ModalWindow/AlertBoxPAIStepRow";
import OptionalMetricsSelectionExport from "../../../components/PAI/MultiStep/StepTwo/OptionalMetricsSelectionExport/OptionalMetricsSelectionExport";

import { AiOutlineDownload } from "react-icons/ai";
import { MdOutlineChecklistRtl } from "react-icons/md";

import { BorderIconTextButton, BorderTextButton, CardProperties, NormalControlReactSelect, NormalDropdown, InputOptionSingleValue } from "lynxai-ui-kit";
import { objectToQueryString } from "../../../utils/requests/helpFunctions";
import { getFileFromBucket } from "../../../utils/requests/downloadFileFromBucket";
import { getFunds, getPAIReportDownload } from "../../../utils/requests/getRequests";
import "./SFDR.scss"
import { GeneralContext } from "../../../utils/functions_variables/variables";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

const PAIIndicators = () => {
  const { theme } = useContext(ThemeContext)
  const { setWarningMessage, databaseAddress } = useContext(GeneralContext)
  const [isLoading, setIsLoading] = useState(true)
  const [loadingText, setLoadingText] = useState(null)
  const [checkedItemsAdditionalClimate, setCheckedItemsAdditionalClimate] = useState(new Map());
  const [checkedItemsAdditionalSocial, setCheckedItemsAdditionalSocial] = useState(new Map());
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false);
  const [fundsList, setFundsList] = useState("")
  const [selectedFund, setSelectedFund] = useState("")
  const [tableOneDone, setTableOneDone] = useState(false)
  const [tableTwoDone, setTableTwoDone] = useState(false)
  const [stepsInfo, setStepsInfo] = useState({
    stepOne: { filled: false },
    stepThree: { filled: false },
    stepFour: { filled: false },
    stepFive: { filled: false },
  })

  useEffect(() => {
    const getFundsList = async () => {
      setIsLoading(true)
      await getFunds(databaseAddress).then(data => {
        const simplifiedData = data?.map(obj => { return { value: obj?.id, label: obj?.fund_name } })?.sort((a, b) => a?.label?.localeCompare(b?.label))
        setFundsList(simplifiedData)
        if (selectedFund === "") { setSelectedFund(simplifiedData[0]) }
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        setWarningMessage(["error", error.message])
      })
    }

    getFundsList()
  }, [])

  useEffect(() => {
    setStepsInfo({
      stepOne: { filled: false },
      stepThree: { filled: false },
      stepFour: { filled: false },
      stepFive: { filled: false }
    })
  }, [selectedFund])

  function checkExportReportReady() {
    if (Object.values(stepsInfo)?.some(value => value?.filled === false)) {
      setIsAlertBoxOpen(true)
    } else {
      setisModalOpen(!isModalOpen)
    }
  }

  function addToDictionary(dictionary, key, value) {
    const parts = key.split("_");
    const lastPart = parts?.pop();

    let currentObj = dictionary;
    for (const part of parts) {
      if (!currentObj[part]) {
        currentObj[part] = {};
      }
      currentObj = currentObj[part];
    }
    currentObj[lastPart] = value;
  }

  async function handleExportReport() {
    setLoadingText("Generating report")
    setIsLoading(true)
    const mergedDict = {};

    for (const [key, value] of checkedItemsAdditionalClimate) {
      addToDictionary(mergedDict, key, value);
    }

    for (const [key, value] of checkedItemsAdditionalSocial) {
      addToDictionary(mergedDict, key, value);
    }

    const filename = "PAI report - " + selectedFund?.label
    await getPAIReportDownload(databaseAddress, selectedFund?.label, objectToQueryString(mergedDict)).then(data => {
      getFileFromBucket(data?.bucket_name, data?.document_name, filename, 'application/pdf')
      setLoadingText(null)
      setIsLoading(false)
      setWarningMessage(["success", "The download will start soon."])
    }).catch(error => {
      setLoadingText(null)
      setIsLoading(false)
      setWarningMessage(["error", error.message])
    })
  }

  function handleFundSelection(selectedOption) {
    setSelectedFund(selectedOption)
  }

  if (isLoading) {
    return <MainLoader></MainLoader>;
  }

  return (
    <div className="regulations-content">
      <CardProperties theme={theme} className="full-width">
        <FlexBlockDiv>
          <p className="font-style-montserrat-family-semibold small-text color-white">Principal Adverse Impact Indicators</p>
          <BorderIconTextButton theme={theme} text="Private companies" className="button-disabled" icon={<MdOutlineChecklistRtl className="icon small-text" />} disabled={true} />
          <BorderIconTextButton theme={theme}  onClick={checkExportReportReady} text="Export report" className={fundsList?.length > 0 ? "button-hover" : "button-disabled"} icon={<AiOutlineDownload className="icon small-text" />} disabled={fundsList?.length > 0 ? false : true} />
          <NormalDropdown theme={theme} value={selectedFund} defaultValue={fundsList[0]} noOptionsMessage={ () => <p className="extra-small-text font-style-montserrat-family-regular">No funds created for this regulation.</p>} className={`${fundsList?.length === 0 && "button-disabled"} funds-dropdown`} isDisabled={fundsList?.length > 0 ? false : true}
            options={fundsList} onChange={handleFundSelection} components={{ Option: InputOptionSingleValue, Control: NormalControlReactSelect }} />
        </FlexBlockDiv>
      </CardProperties>
      {fundsList?.length > 0 ?
        <>
          <MultiStep selectedFund={selectedFund} stepsInfo={stepsInfo} setStepsInfo={setStepsInfo}></MultiStep>
          <ModalWindow theme={theme} title="The report can't be exported" sizeClass="create-asset-create-fund" open={isAlertBoxOpen} setOpen={setIsAlertBoxOpen}>
            <div className="alert-box-info-div color-white">
              <p className="font-style-montserrat-family-regular small-text margin-top-2 margin-bottom-1">The following steps are not complete:</p>
              {!stepsInfo?.stepOne?.filled && <AlertBoxPAIStepRow>1. Summary</AlertBoxPAIStepRow>}
              {!stepsInfo?.stepThree?.filled && <AlertBoxPAIStepRow>3. Description of policies to identify and prioritise PAI on sustainability factors</AlertBoxPAIStepRow>}
              {!stepsInfo?.stepFour?.filled && <AlertBoxPAIStepRow>4. Engagement Policies</AlertBoxPAIStepRow>}
              {!stepsInfo?.stepFive?.filled && <AlertBoxPAIStepRow>5. References to international standards</AlertBoxPAIStepRow>}
            </div>
            <FlexBlockDiv className="right-alignment margin-top-2">
              <BorderTextButton theme={theme} text="Close" onClick={() => closeModal(isAlertBoxOpen, setIsAlertBoxOpen)} />
            </FlexBlockDiv>
          </ModalWindow>
          <ModalWindow theme={theme} title="Select the optional metrics you would like to export" topBlockItems={<BorderIconTextButton theme={theme} onClick={handleExportReport} className={(tableOneDone && tableTwoDone) ? "button-hover" : "button-disabled"} disabled={!tableOneDone && !tableTwoDone} icon={<AiOutlineDownload className="icon medium-text" />} text="Export report" />} sizeClass="export-pai-metrics-visualization" open={isModalOpen} setOpen={setisModalOpen}>
            <OptionalMetricsSelectionExport selectedFund={selectedFund} loadingText={loadingText} setLoadingText={setLoadingText} checkedItemsAdditionalClimate={checkedItemsAdditionalClimate} setCheckedItemsAdditionalClimate={setCheckedItemsAdditionalClimate} checkedItemsAdditionalSocial={checkedItemsAdditionalSocial} setCheckedItemsAdditionalSocial={setCheckedItemsAdditionalSocial} tableOneDone={tableOneDone} setTableOneDone={setTableOneDone} tableTwoDone={tableTwoDone} setTableTwoDone={setTableTwoDone} />
          </ModalWindow>
        </>
        :
        <div className="pai-no-funds-div font-style-montserrat-family-regular small-text color-white ">There are no funds created with this regulation.</div>
      }
    </div>
  );
}

export default PAIIndicators;