import React, { useState, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, Rectangle, CartesianGrid, Tooltip, BarChart, Bar, ResponsiveContainer, Label } from 'recharts';

import { AiOutlineExpand } from "react-icons/ai";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

import { ValueTooltip, TickFormatterAxisBigNumber } from "../Tooltips";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { FlexBlockDiv } from "../../../assets/styles/global";
import { NoBorderIconButton, CardProperties, ThemeVariables } from "lynxai-ui-kit";

const LineChartGraphCard = ({ title, cardType, data, modal }) => {
    const { theme } = useContext(ThemeContext);
    const [isModalOpen, setisModalOpen] = useState(false);

    return (
        <>
            {data?.length > 0 &&
                <CardProperties theme={theme} className={`${modal && 'background-transparent'} ${cardType}`}>
                    {!modal &&
                        <FlexBlockDiv>
                            <p className="font-style-montserrat-family-regular extra-small-text color-white">{title}</p>
                            <NoBorderIconButton theme={theme} onClick={() => setisModalOpen(!isModalOpen)} icon={<AiOutlineExpand className="icon small-text color-lightgray" />} />
                        </FlexBlockDiv>}
                    <ResponsiveContainer width="100%" height="100%" className={`font-style-montserrat-family-regular extra-extra-small-text color-white`}>
                        <LineChart data={data} margin={{ top: 15, right: 7, left: 25 }}>
                            <CartesianGrid style={{ stroke: `${ThemeVariables[theme]?.medium_color_50}` }} vertical={false} />
                            <XAxis dataKey="year" dx={-2} tick={{ width: 130, fill: `${ThemeVariables[theme]?.extreme_contrast}` }} tickLine={false} />
                            <YAxis tickFormatter={TickFormatterAxisBigNumber} tick={{ fill: `${ThemeVariables[theme]?.extreme_contrast}` }} tickLine={false} />
                            <Line strokeWidth={2} type="monotone" dataKey="value" stroke="var(--main-blue)" activeDot={{ r: 6 }} dot={{ stroke: `${ThemeVariables[theme]?.extreme_contrast}`, strokeWidth: 0, r: 4, strokeDasharray: '' }} />
                            <Tooltip wrapperStyle={{ outline: 'none' }} content={<ValueTooltip />} shared={false} />
                        </LineChart>
                    </ResponsiveContainer>
                    <ModalWindow theme={theme} title={title} sizeClass="charts-visualization" open={isModalOpen} setOpen={setisModalOpen}>
                        <LineChartGraphCard title={title} data={data} cardType="modal-card" modal={true}></LineChartGraphCard>
                    </ModalWindow>
                </CardProperties>
            }
        </>
    );
}

export default LineChartGraphCard;