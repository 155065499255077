import React from "react";
import "./MainLoader.scss";

const main_loader = require('../../assets/images/main_loader.gif')

function MainLoader({ text }) {
  return (
   <div className="main-loader-container">
    <img
        src={main_loader}
        style={{ width: '100px', margin: '35px', display: 'block' }} alt="img"
      />
      {text && <p className="font-style-montserrat-family-regular small-text color-white">{text}</p>}
   </div>
  );
}

export default MainLoader;