import React, { useState, useContext, useEffect } from "react";

import useAutosizeTextArea from "../../utils/useAutosizeTextArea";
import LocalLoader from "../../../../LocalLoader/LocalLoader";

import { putPAIInput } from "../../../../../utils/requests/putRequests";
import { GeneralContext } from "../../../../../utils/functions_variables/variables";
import { Textarea } from "lynxai-ui-kit";
import { ThemeContext } from "../../../../../assets/styles/darkLightMode/themeContext";

const TableInput = ({ selectedFund, text, name, colName }) => {
  const { theme } = useContext(ThemeContext)
  const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const textareaRef = React.useRef(null);
    const [placeholder, setPlaceholder] = useState(text);
    const [value, setValue] = useState(text === "" ? '' : text);
    const [isLoading, setIsLoading] = useState(false)

    useAutosizeTextArea(textareaRef?.current, value);

    useEffect(() => {
        setValue(text)
        { text === "" ? setPlaceholder("Write your comments here") : setPlaceholder(text) }
    }, [text, selectedFund]);

    const handleChange = (evt) => {
        const val = evt?.target?.value;
        setValue(val);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const val = e.target?.value;
        if (val !== placeholder) {
            setIsLoading(true)
            setPlaceholder(val)
            const bodyContent = JSON.stringify({ client: databaseAddress, fundName: selectedFund?.label, insertValue: { step2: { [colName]: val } } })
            await putPAIInput(bodyContent, 2).then(data => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    };

    if (isLoading) {
        return <LocalLoader size="textarea-input"></LocalLoader>;
    }

    return (
        <Textarea theme={theme} className="text-input-textarea extra-small-text" ref={textareaRef} name={name} placeholder={placeholder} maxLength={1200} type="text" rows={1} value={value} onChange={handleChange} onBlur={handleSave} required={true} />
    )
}

export default TableInput;