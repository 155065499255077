import React, { useState, useContext } from "react";
import { Auth } from "aws-amplify";
import PasswordChecklist from "react-password-checklist"

import "../../views/Authentication/Authentication.scss"

import { RxCross2 } from "react-icons/rx";
import { HiCheck } from "react-icons/hi";

import { GeneralContext } from "../../utils/functions_variables/variables"
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";
import { BorderTextFullWidthButton, NoBorderIconButton, CardProperties, NormalInput, PasswordInput } from "lynxai-ui-kit";
import { ImArrowLeft2 } from "react-icons/im";
import { handleKeyDownEvent } from "../../utils/functions_variables/functions";

const icons = { ValidIcon: <HiCheck className="icon-check"></HiCheck>, InvalidIcon: <RxCross2 className="icon-cross"></RxCross2> }

export default function ChangePasswordRequired({ setNewPasswordRequired, setNewPasswordRequiredUser, newPasswordRequiredUser }) {
    const { theme } = useContext(ThemeContext);
    const { setWarningMessage } = useContext(GeneralContext)
    const lynxai_full_logo = (theme === 'light' ? require('../../assets/images/lynxai_full_logo_black_blue.png') : require('../../assets/images/lynxai_full_logo_white_blue.png'));
    const [name, setName] = useState("")
    const [nameEnable, setNameEnable] = useState(false)
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [passwordEnable, setPasswordEnable] = useState(false)
    const [buttonEnable, setButtonEnable] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const validatePasswords = (isValid) => {
        setPasswordEnable(isValid)
        if (isValid && nameEnable) {
            setButtonEnable(true)
        } else { setButtonEnable(false) }
    }

    const validateName = (name_written) => {
        setName(name_written)
        if (name_written !== "") {
            setNameEnable(true)
            if (passwordEnable) {
                setButtonEnable(true)
            } else { setButtonEnable(false) }
        } else { setNameEnable(false); setButtonEnable(false) }
    }

    const handleGoBack = async (e) => {
        setNewPasswordRequired(false)
    }

    async function handleCompleteNewPassword(e) {
        e.preventDefault();
        if (buttonEnable) {
            const attributes = {
                name: name,
            }
            await Auth?.completeNewPassword(newPasswordRequiredUser, newPassword, attributes).then((user) => {
                setNewPasswordRequiredUser(null)
                setNewPasswordRequired(false)
                setWarningMessage(["success", "Password created successfully."])
            }).catch((error) => {
                setWarningMessage(["info", error.message])
                setNewPasswordRequiredUser(null)
                setNewPasswordRequired(false)
            });
        }
    }

    const toggleNewPasswordVisibility = (event) => {
        event.preventDefault();
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmNewPasswordVisibility = (event) => {
        event.preventDefault();
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    return (
        <CardProperties theme={theme} className="authentication-main-div">
            <NoBorderIconButton theme={theme} icon={<ImArrowLeft2 className="icon medium-text color-white" />} className="back-button" onClick={handleGoBack} />
            <img src={lynxai_full_logo} alt="img" />
            <div className="divider margin-top-1 margin-bottom-1"></div>
            <h1 className="margin-top-3 margin-bottom-1 font-style-montserrat-family-semibold small-text color-lightgray">First time logging in?</h1>
            <h2 className="margin-top-1 margin-bottom-1 font-style-montserrat-family-semibold extra-small-text color-lightgray">Please fill in the required details.</h2>
            <form onSubmit={handleCompleteNewPassword}>
                <NormalInput theme={theme} placeholder="Full name" onChange={(e) => validateName(e?.target?.value)} type="text" onKeyDown={(e) => handleKeyDownEvent(handleCompleteNewPassword, e)} autoFocus={true} required={true} className="margin-top-1" />
                <div className="divider margin-top-1 margin-bottom-1"></div>
                <PasswordInput theme={theme} className="margin-bottom-1" placeholder="New password" required={true} autoComplete="new-password" value={newPassword} showPassword={showNewPassword} onChange={(e) => setNewPassword(e?.target?.value)} onKeyDown={(e) => handleKeyDownEvent(handleCompleteNewPassword, e)} toggleShowPassword={toggleNewPasswordVisibility} />
                <PasswordInput theme={theme} className="margin-bottom-1" placeholder="Confirm new password" required={true} autoComplete="new-password" value={confirmNewPassword} showPassword={showConfirmNewPassword} onChange={(e) => setConfirmNewPassword(e?.target?.value)} onKeyDown={(e) => handleKeyDownEvent(handleCompleteNewPassword, e)} toggleShowPassword={toggleConfirmNewPasswordVisibility} />
                <PasswordChecklist className="extra-small-text font-style-montserrat-family-regular color-white"
                    rules={["capital", "lowercase", "minLength", "number", "specialChar", "match"]}
                    minLength={8}
                    fontSize={10}
                    value={newPassword}
                    valueAgain={confirmNewPassword}
                    iconComponents={icons} onChange={(isValid) => validatePasswords(isValid)} />
                <BorderTextFullWidthButton theme={theme} text="Update information" onClick={handleCompleteNewPassword} className={`${buttonEnable ? "button-hover" : "button-disabled"} margin-top-2`} type="submit" disabled={!buttonEnable} />
            </form>
        </CardProperties>
    );
}
