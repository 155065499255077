import React from "react";
import { NavLink } from "react-router-dom";

import "./Footer.scss"

const Footer = () => {

    return (
        <div className="container-footer">
            <div className="footer-content">
                <div className="footer-content-col">
                    <div className="extra-small-text font-style-montserrat-family-semibold color-white">Website Map</div>
                    <div className="footer-content-items font-style-montserrat-family-regular extra-extra-small-text">
                        <NavLink className="nav-link-footer extra-extra-small-text" to="/overview">
                            <div>Overview</div>
                        </NavLink>
                        <NavLink className="nav-link-footer extra-extra-small-text" to="/portfolio">
                            <div>Portfolio</div>
                        </NavLink>
                        <NavLink className="nav-link-footer extra-extra-small-text" to="/simulator">
                            <div>Simulator</div>
                        </NavLink>
                        <NavLink className="nav-link-footer extra-extra-small-text" to="/regulations">
                            <div>Regulations</div>
                        </NavLink>
                    </div>
                </div>
                <div className="footer-content-col">
                    <div className="extra-small-text font-style-montserrat-family-semibold color-white">Talk with Us</div>
                    <div className="footer-content-items font-style-montserrat-family-regular extra-extra-small-text">
                        <NavLink className="nav-link-footer" to="/configurations/support" state={{ from: 3 }}>
                            <div className="footer-content-item extra-extra-small-text">Support</div>
                        </NavLink>
                    </div>
                </div>
                <div className="footer-content-col">
                    <div className="extra-small-text font-style-montserrat-family-semibold color-white">Need any help?</div>
                    <div className="footer-content-items font-style-montserrat-family-regular extra-extra-small-text">
                        <NavLink className="nav-link-footer" to="/configurations/terms" state={{ from: 2 }}>
                            <div className="footer-content-item extra-extra-small-text">FAQ</div>
                        </NavLink>
                        <NavLink className="nav-link-footer" to="/configurations/terms" state={{ from: 4 }}>
                            <div className="footer-content-item extra-extra-small-text">Terms & Conditions</div>
                        </NavLink>
                    </div>
                    <div className="div-join-newsletter">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
