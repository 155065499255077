import React, { useState, useContext } from "react";

import "./FundReportExportationMetricsSelectionModalContent.scss"
import { ThemeContext } from "../../../../../assets/styles/darkLightMode/themeContext";
import { GeneralContext } from "../../../../../utils/functions_variables/variables";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import LocalLoader from "../../../../LocalLoader/LocalLoader";
import { getComparator, stableSort, EnhancedTableHead } from "../../../../../utils/functions_variables/table_functions/TableCompFunctions";
import { CheckboxInput } from "lynxai-ui-kit";

const FundReportExportationMetricsSelectionModalContent = ({ fundName, metrics }) => {
    const { theme } = useContext(ThemeContext);
    const { databaseAddress, setWarningMessage } = useContext(GeneralContext)
    const [isLoading, setIsLoading] = useState(false)
    const [checkedMetrics, setCheckedMetrics] = useState(new Map());
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState({ id: 'main_metric', numeric: false, label: "Main metric" });
    const [selected, setSelected] = useState([]);
    const headCells = [
        {
            id: 'main_metric',
            numeric: false,
            label: 'Main metric',
        }
    ]

    const handleRequestSort = (event, property) => {  // DO NOT REMOVE THE EVENT ARGUMENT. STOPS WORKING.
        const isAsc = orderBy.id === property.id && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const isSelected = (name) => selected?.indexOf(name) !== -1;

    function handleMetricOptionSelection(metric_id, bool_event) {
        const newCheckedMetrics = new Map(checkedMetrics);
        if (bool_event) {
            newCheckedMetrics?.set(metric_id, bool_event);
        } else {
            newCheckedMetrics?.delete(metric_id)
        }
        setCheckedMetrics(newCheckedMetrics)
    }

    return (
        <div className="modal-window-export-fund-report-div">
            {isLoading ?
                <LocalLoader text="Generating report"></LocalLoader>
                :
                <div className="table-div extra-small-text">
                    <Paper sx={{ width: '100%', background: 'transparent', boxShadow: "none !important" }}>
                        <TableContainer sx={{ maxHeight: "70vh" }}>
                            <Table stickyHeader>
                                <EnhancedTableHead headCells={headCells}
                                    numSelected={selected?.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort} />
                                <TableBody>
                                    <>{stableSort(metrics, getComparator(order, orderBy))
                                        ?.map((row, index) => {
                                            const isItemSelected = isSelected(row?.name);
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={index}
                                                    selected={isItemSelected}>
                                                    <TableCell onClick={(event) => handleMetricOptionSelection(row.metric_id, checkedMetrics?.get(row.metric_id) ? !checkedMetrics?.get(row.metric_id) : true)} className="table-row-cell font-style-montserrat-family-regular extra-small-text color-white">
                                                        <CheckboxInput checked={checkedMetrics?.get(row.metric_id)} onChange={(event) => handleMetricOptionSelection(row.metric_id, event?.target?.checked)} />
                                                        <p className="font-style-montserrat-family-regular extra-small-text color-white">{row.label}</p>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            }
        </div>
    );
}

export default FundReportExportationMetricsSelectionModalContent;