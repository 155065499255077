
import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import "./ConfigurationsMenu.scss"
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

import { handleThemeChange } from "../../utils/functions_variables/functions";
import { FiUser } from "react-icons/fi";
import { BsQuestionOctagon, BsChatLeftDots, BsFileEarmarkMedical, BsMoon } from "react-icons/bs";
import { MdOutlineLightMode } from "react-icons/md";

import { ExpandableLeftMenuTabButton, ExpandableLeftMenuTopButton } from "lynxai-ui-kit";
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from "react-icons/tb";

export default function ConfigurationsMenu({ tab, setTab }) {
  const [menuOpen, setMenuOpen] = useState(true);
  const { theme, setTheme } = useContext(ThemeContext);
  const navigate = useNavigate();


  return (
    <div className="configurations-menu-div">
      <ExpandableLeftMenuTopButton theme={theme} text="Configurations" className="margin-bottom-1" icon={menuOpen ? <TbLayoutSidebarLeftCollapse className="icon small-text color-white" /> : <TbLayoutSidebarLeftExpand className="margin-center icon small-text color-white" />} open={menuOpen} onClick={() => setMenuOpen(!menuOpen)}/>
      <ExpandableLeftMenuTabButton theme={theme} text="Profile" icon={<FiUser className="icon extra-small-text color-white" />} open={menuOpen} className={tab === 1 ? "active" : "inactive"} onClick={() => navigate('/configurations/profile', { state: { from: 1 } })} />
      <div className="divider margin-top-1 margin-bottom-1" />
      <ExpandableLeftMenuTabButton theme={theme} text="FAQ" icon={<BsQuestionOctagon className="icon extra-small-text color-white" />} open={menuOpen} className={tab === 2 ? "active" : "inactive"} onClick={() => navigate('/configurations/faq', { state: { from: 2 } })} />
      <ExpandableLeftMenuTabButton theme={theme} text="Support" icon={<BsChatLeftDots className="icon extra-small-text color-white" />} open={menuOpen} className={tab === 3 ? "active" : "inactive"} onClick={() => navigate('/configurations/support', { state: { from: 3 } })} />
      <div className="divider margin-top-1 margin-bottom-1" />
      <ExpandableLeftMenuTabButton theme={theme} text="Terms & Conditions" icon={<BsFileEarmarkMedical className="icon extra-small-text color-white" />} open={menuOpen} className={tab === 4 ? "active" : "inactive"} onClick={() => navigate('/configurations/terms', { state: { from: 4 } })} />
      <div className="divider margin-top-1 margin-bottom-1" />
      <ExpandableLeftMenuTabButton theme={theme} text={theme === 'dark' ? "Light mode" : "Dark mode"} icon={theme === 'dark' ? <MdOutlineLightMode className="icon extra-small-text color-white" /> : <BsMoon className="icon extra-small-text color-white" />} open={menuOpen} onClick={() => handleThemeChange(theme, setTheme)} />
    </div>
  );
}
