import React, { useState, useContext } from "react";

import "./OverviewTopBar.scss"
import { AiOutlineDownload } from "react-icons/ai";
import { OverviewContext } from "../../utils/functions_variables/variables";
import { BorderIconTextButton, CardProperties } from "lynxai-ui-kit";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";

const OverviewTopBar = () => {
  const { theme } = useContext(ThemeContext)
  const { tab, setTab } = useContext(OverviewContext);

    const [isModalOpen, setisModalOpen] = useState(false);

    const selectTabNumber = (tab) => {
        setTab(tab)
    }

    return (
        <>
            <CardProperties theme={theme} className="overview-top-bar-div">
                <p className="font-style-montserrat-family-semibold small-text color-white">Portfolio overview</p>
                <BorderIconTextButton theme={theme} text="Create report " icon={<AiOutlineDownload className="icon small-text"></AiOutlineDownload>} onClick={() => setisModalOpen(!isModalOpen)} />
            </CardProperties>
            <div className="multi-tab-main-div">
                <ul className="form-stepper">
                    <li className={`form-stepper-${tab === 1 ? "active" : "unfinished"} form-stepper-list`} tab="1" onClick={() => selectTabNumber(1)}>
                        <div className="label font-style-montserrat-family-regular small-text">Characteristics</div>
                    </li>
                    <li className={`form-stepper-${tab === 2 ? "active" : "unfinished"} form-stepper-list`} onClick={() => selectTabNumber(2)} tab="2">
                        <div className="label font-style-montserrat-family-regular small-text">CO2 emissions</div>
                    </li>
                    <li className="form-stepper-unfinished form-stepper-list" />
                </ul>
            </div>
        </>
    );
}

export default OverviewTopBar;