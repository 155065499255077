import React, { useState, useEffect, useRef, useContext } from "react";

import "../../views/Authentication/Authentication.scss"

import { ImArrowLeft2 } from "react-icons/im";

import LocalLoader from "../LocalLoader/LocalLoader";
import openSupportTicket from "../../utils/requests/supportMessage";
import { GeneralContext } from "../../utils/functions_variables/variables";
import { ThemeContext } from "../../assets/styles/darkLightMode/themeContext";
import { BorderTextFullWidthButton, NoBorderIconButton, CardProperties, NormalInput, Textarea } from "lynxai-ui-kit";
import { handleKeyDownEvent } from "../../utils/functions_variables/functions";

export default function Help({ setHelpRequired }) {
    const { setWarningMessage } = useContext(GeneralContext)
    const { theme } = useContext(ThemeContext)
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    const [buttonEnable, setButtonEnable] = useState(false)
    const form = useRef();
    const lynxai_full_logo = (theme === 'light' ? require('../../assets/images/lynxai_full_logo_black_blue.png') : require('../../assets/images/lynxai_full_logo_white_blue.png'));

    useEffect(() => {
        function validateFields() {
            const regex = /\S+@\S+\.\S+/;
            if (name !== "" && name?.length > 2 && description?.length > 14 && regex.test(email)) {
                setButtonEnable(true);
            } else {
                setButtonEnable(false);
            }
        }
        validateFields()
    }, [name, email, description]);

    const handleGoBack = () => {
        setHelpRequired(false)
    }

    const onSubmit = async (event) => {
        if (buttonEnable) {
            event.preventDefault();
            setIsLoading(true)
            await openSupportTicket(form).then((data) => {
                setName("")
                setEmail("")
                setDescription("")
                setButtonEnable(false)
                setIsLoading(false)
                setWarningMessage(data)
            }).catch((error) => {
                setIsLoading(false)
                setWarningMessage(["error", error.message])
            })
        }
    }

    return (
        <CardProperties theme={theme} className="support-authentication-main-div authentication-main-div">
            <NoBorderIconButton theme={theme} icon={<ImArrowLeft2 className="icon medium-text color-white" />} className="back-button" onClick={handleGoBack} />
            <img src={lynxai_full_logo} alt="img" />
            <div className="divider margin-top-3 margin-bottom-1"></div>
            {isLoading ? <LocalLoader></LocalLoader> :
                <>
                    <h1 className="margin-top-3 margin-bottom-1 font-style-montserrat-family-semibold small-text color-lightgray">Need help?</h1>
                    <h2 className="margin-top-1 margin-bottom-1 font-style-montserrat-family-semibold extra-small-text color-lightgray">Send us a message. We reply within 48 hours.</h2>
                    <form ref={form} onSubmit={onSubmit}>
                        <NormalInput theme={theme} className="margin-top-1 margin-bottom-1" placeholder="Name" name="name" value={name} onChange={(e) => setName(e?.target?.value)} autoFocus={true} required={true} type="text" onKeyDown={(e) => handleKeyDownEvent(onSubmit, e)} />
                        <NormalInput theme={theme} className="margin-bottom-1" placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e?.target?.value)} required={true} type="text" onKeyDown={(e) => handleKeyDownEvent(onSubmit, e)} />
                        <Textarea theme={theme} placeholder="Message" maxLength={1200} type="text" rows={7} value={description} onChange={(e) => setDescription(e?.target?.value)} required={true} />
                        <BorderTextFullWidthButton theme={theme} text="Send" onClick={onSubmit} className={`${buttonEnable ? "button-hover" : "button-disabled"} margin-top-2`} type="submit" disabled={!buttonEnable} />
                        <p className="bottom-note margin-top-2 font-style-montserrat-family-regular extra-small-text color-lightgray">By clicking send you agree with our <a href="privacy_policy.pdf" download>privacy policy</a>.</p>
                    </form>
                </>}
        </CardProperties>
    );
}