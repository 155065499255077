import React, { useContext } from "react";

import "./FilterCompanyCard.scss";

import { NormalDropdown, NormalControlReactSelect, InputOptionSingleValue, CardProperties } from "lynxai-ui-kit";
import { ThemeContext } from "../../../assets/styles/darkLightMode/themeContext";

function FilterCompanyCard({ cardType, companies, data_1, data_2, data_3 }) {
    const { theme } = useContext(ThemeContext);

    return (
        <CardProperties theme={theme} className={`filter-card-company-div ${cardType}`}>
            <div className="filter-company-card-select-company-div">
                <p className="small-text font-style-montserrat-family-semibold color-white">Choose Company</p>
                <NormalDropdown theme={theme} noOptionsMessage={ () => <p className="extra-small-text font-style-montserrat-family-regular">No results match your searching criteria.</p> } className="navbar-dropdown-search-bar"
                    options={companies} components={{ Option: InputOptionSingleValue, Control: NormalControlReactSelect }}
                    defaultValue={companies && companies[0]} />
            </div>
            <div className="filter-company-card-three-stats">
                <div className="filter-company-card-stat">
                    <p className="font-style-montserrat-family-semibold large-text color-white">{`${(data_1?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</p>
                    <p className="font-style-montserrat-family-regular small-text color-white">{data_1?.name}</p>
                </div>
                <div className="filter-company-card-stat">
                    <p className="font-style-montserrat-family-semibold large-text color-white">{`${(data_2?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</p>
                    <p className="font-style-montserrat-family-regular small-text color-white">{data_2?.name}</p>
                </div>
                <div className="filter-company-card-stat">
                    <p className="font-style-montserrat-family-semibold large-text color-white">{`${(data_3?.percentage * 100)?.toFixed(1).replace(/[.,]0/, "")}%`}</p>
                    <p className="font-style-montserrat-family-regular small-text color-white">{data_3?.name}</p>
                </div>
            </div>
        </CardProperties>
    );
}

export default FilterCompanyCard;